import React, {useContext, useEffect, useState} from "react";
import Prism from "prismjs";
import {Button, Modal, Card, Container, Nav, Image} from "react-bootstrap";
import Footer from "../layouts/Footer";
import HeaderMobile from "../layouts/HeaderMobile";
import failure from "../assets/img/failure-icon.png"
import TaskContext from "../context/TaskContext";
import SingleTask from "./SingleTask";
export default function FailureModal(props) {

    const message = props.message;
    const handleClose = props.action;
    const isEmpty = message === undefined || message === null || message === "";

    const { modalState, updateModalState, } = useContext(TaskContext);


    useEffect(() => {
        Prism.highlightAll();
    }, []);


    const handleClose2 = () => updateModalState(false);

    const resetModals = () => {
        updateModalState(false);
        handleClose();
    }


    return (
        <React.Fragment>
            <HeaderMobile />
            <div className="main main-docs">
                <Modal show={modalState}  onHide={resetModals} centered size="sm">
                    <Modal.Header closeButton>
                        {/*<Modal.Title>Modal heading</Modal.Title>*/}
                    </Modal.Header>
                    <Modal.Body>
                        <Image src={failure} fluid={true} alt="..." />
                        <h3 className="text-center">{message}</h3>
                        {isEmpty && <h3 className="text-center">{"Something went Wrong, Kindly refresh page or try logging in again."}</h3>}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={resetModals}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>

        </React.Fragment>
    );
}