import React, {useContext, useEffect} from "react";
import TaskContext from "../context/TaskContext";
import {TaskService} from "../service/TaskService";
import Sidebar from "../layouts/Sidebar";
import Header from "../layouts/Header";
import {Card, Col, Spinner, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import TaskListing from "../apps/TaskListing";
import SingleTask from "../components/SingleTask";
import img1 from "../assets/img/img1.jpg";
import * as PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2"

function TaskComments(props) {

    const date = props.log.date;
    const activityDescription = props.log.activityDescription;
    const createdBy = props.log.createdBy;
    return (
        <React.Fragment>

            <li className="activity-item comment">
                <p className="d-sm-flex align-items-center mb-2">
                    <Link to="" className="avatar avatar-xs me-2 d-none d-sm-inline"><img src={img1} alt="" /></Link>
                    <span className="fs-sm"><strong>{createdBy}</strong> </span>
                    <span className="text-secondary fs-xs ms-auto">{date}</span>
                </p>
                <Card className="card-comment">
                    <Card.Body>
                        {activityDescription}
                    </Card.Body>
                </Card>
            </li>
        </React.Fragment>
    );
}

TaskComments.propTypes = {task: PropTypes.any};

export default function NewTasks() {
    useEffect(() => {
        document.body.classList.add('app-task')
        return () => {
            document.body.classList.remove('app-task')
        }
    }, []);

    const isNewSection = true;
    const {selectedTask, updateTask} = useContext(TaskContext);
    const {updateModalState} = useContext(TaskContext);
    const [data, setData] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    let taskId = null;
    const [taskLog, setTaskLog] = React.useState([]);
    const navigate = useNavigate();
    function handleTaskClick(task) {

        // pass the task to the context
        updateTask(task);
        //open modal
        updateModalState(true);
        setShowModal(true);
    }

    useEffect(() => {

        const regex = /task=([^&]+)/;
        // Get the current URL from the browser
        const currentUrl = window.location.href;

        // Use the regular expression to extract the token from the URL
        const match = currentUrl.match(regex);
        taskId = match && match[1]; // Extract the captured group
        

        if (taskId) {

            // Fetch task using Axios GET request
            TaskService.getTaskInfo(localStorage.getItem("token"), taskId)
                .then(response => {
                    updateTask(response.data.result); //// pdate state with fetched task data
                    setIsLoaded(true);
                    setData(response.data.result);

        

                })
                .catch(error => {
                    console.error('Error fetching task:', error);
                });

                TaskService.getTaskLog(localStorage.getItem("token"), taskId)
                .then(response => {
                    setIsLoaded(true);
                    setTaskLog(response.data.result);
                 
    
                })
                .catch(error => {
                    console.error('Error fetching logs:', error);
                });
        }


        else{
          navigate("/dashboard/overview", { replace: true });
        }
     




    }, [taskId]);



    return (
        <React.Fragment>
            <Sidebar />
            <Header />
            <div className="main p-4 p-lg-5">

            {
                taskLog && 
                <Row className="g-5">
                    <Col xl="9">
                        <br/>  <br/>  <br/>


                        <h2 className="main-title mb-3">Task Details</h2>
                        {isLoaded && <TaskListing singleTask={data} taskClickFunction={handleTaskClick} isSingleTask={true}/>}
                        <br/>  <br/>  <br/>

                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <h5 className="section-title mb-0">Task Activity</h5>
                           
                        </div>

                        {taskLog.length === 0 && (
                            <div>
                                <p>Fetching activity logs</p>
                                <Spinner animation="border" variant="success" />
                            </div>


                        )}

                        {taskLog.length > 0 && (
                            <div>
                                <ul className="activity-group mb-5">
                                    {taskLog.map((log, index) => (

                                        <TaskComments key={index} log={log} />
                                        ))}
                                </ul>

                            </div>
                        )}




                    </Col>
                </Row>
            }
              
                {showModal && <SingleTask isNewSection={isNewSection}/>}

            </div>
        </React.Fragment>
    );

}