import React, { useEffect, useState} from "react";
import Prism from "prismjs";
import {Alert, Button, Card, Col, Form, Modal, Row} from "react-bootstrap";
import Footer from "../layouts/Footer";
import HeaderMobile from "../layouts/HeaderMobile";
import {useNavigate} from 'react-router-dom';

import Select from "react-select";

import {TeamService} from "../service/TeamService";
import {EmployeeService} from "../service/EmployeeService";
import Sidebar from "../layouts/Sidebar";


export default function InviteEmployee() {
    const [teamsData, setTeamsData] = useState([]);

    useEffect(() => {
        Prism.highlightAll();
        //authorize entry

        // get teams

        TeamService.getAllTeams(localStorage.getItem("token"))
            .then((response) => {
                // Handle successful team retrieval
                if (response.status === 200) {
                    setTeamsData(response.data.result);

                }
            })
            .catch((error) =>{ console.log(error)});

    }, [setTeamsData]);



    const [lgShow, setLgShow] = useState(true);
    const [logSuccess, setLogSuccess] = React.useState(false)
    const navigate = useNavigate();


    const lgHandleClose = () => {


        setTimeout(() => {
            navigate("/teams", { replace: true });
        }, 3000); // Navigate to "/apps/tasks" upon modal closure
        setLgShow(false);
    };

    const selectTeamOptions = teamsData.length > 0
        ? teamsData.map((team) => {
            return { value: team.id, label: team.name };
        })
        : [{ value: null, label: "No teams found" }];
//task varaibles

    const [firstName, seFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [department, setDepartment] = useState("");
    const [jobTitle, setJobTitle] = useState([]);
    const [teamId, setTeamId] = useState(null);
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");
    const departmentOptions = [
        { value: "UNDERWRITING/CLAIMS", label: 'UNDERWRITING/CLAIMS' },
        { value: "MD/CEO", label: 'MD/CEO' },
        { value: "FINANCE", label: 'FINANCE' },
        { value: "HR/ADMIN", label: 'HR/ADMIN' },
        { value: "MEDICAL SERVICE", label: 'MEDICAL SERVICE' },
        { value: "ICT", label: 'ICT' },
        { value: "CLIENT SERVICES", label: 'CLIENT SERVICES' },
        { value: "SALES", label: 'SALES' },
        { value: "OPERATIONS", label: 'OPERATIONS' },
        // Add more departments from the provided list
    ];


    const roleOptions = [
        { value: "EMPLOYEE", label: 'NORMAL' },
        { value: "MANAGER", label: 'LINE MANAGER' }

    ];

    const employeeDto = {
        firstName,
        lastName,
        email,
        role,
        department,
        jobTitle,
        teamId,
    };


    const handleSubmit = (e) => {
        e.preventDefault();


        // Perform the task creation logic here



        // post dto

        EmployeeService.sendInvite(employeeDto, localStorage.getItem("token"))
            .then((response) => {
                // Handle successful task creation
                if (response.status === 201) {
                    setLogSuccess(true);
                    lgHandleClose();
                }


            })
            .catch((error) => console.log(error)

            );

    };





    return (
        <React.Fragment>
            <Sidebar />
            <HeaderMobile/>
            <div className="main main-docs">
                <Modal show={lgShow} onHide={lgHandleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Invite an Employee</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Card >
                            <Card.Body>

                                    <Form.Label htmlFor="formGroupExampleInput">Employee Full Name</Form.Label>
                                    <div className="mb-3">
                                        <Row>
                                        <Col>
                                            <Form.Control onChange={(event) => seFirstName(event.target.value)} type="text" placeholder="First name" aria-label="First name" />
                                        </Col>
                                        <Col>
                                            <Form.Control onChange={(event) => setLastName(event.target.value)}type="text" placeholder="Last name" aria-label="Last name" />
                                        </Col>
                                        </Row>
                                    </div>


                                <div className="mb-3">
                                    <Form.Label htmlFor="formGroupExampleInput">Employee Email</Form.Label>
                                    <Form.Control onChange={(event) => setEmail(event.target.value)} type="text" id="formGroupExampleInput"
                                                  placeholder="Only email addresses ending with '@metrohealthhmo.com' are allowed."/>
                                </div>
                                <div className="mb-3">
                                    <Form.Label htmlFor="formGroupExampleInput2">Department</Form.Label>
                                    <Select
                                        onChange={(departmentOption) => {
                                            if (departmentOption && departmentOption.value) {
                                                setDepartment(departmentOption.value);
                                            }
                                        }}
                                        options={departmentOptions}
                                        isSearchable={true}
                                    />
                                </div>
                                <div className="mb-3">
                                    <Form.Label htmlFor="formGroupExampleInput">Job Designation</Form.Label>
                                    <Form.Control onChange={(event) => setJobTitle(event.target.value)} type="text" id="formGroupExampleInput"
                                                  placeholder="Employee's Job title"/>
                                </div>
                                <div className="mb-3">
                                    <Form.Label htmlFor="formGroupExampleInput2">Grade Type</Form.Label>
                                    <Select
                                        onChange={(roleOption) => {
                                            if (roleOption && roleOption.value) {
                                                setRole(roleOption.value);
                                            }
                                        }}
                                        options={roleOptions}
                                        isSearchable={true}
                                    />
                                </div>
                                <div className="mb-3">
                                    <Form.Label htmlFor="formGroupExampleInput2">Team</Form.Label>
                                    <Select
                                        onChange={(selectTeamOption) => {
                                            if (selectTeamOption && selectTeamOption.value) {
                                                setTeamId(selectTeamOption.value);
                                            }
                                        }}
                                        options={selectTeamOptions}
                                        isSearchable={true}
                                    />
                                </div>


                            </Card.Body>

                            <Card.Footer>
                                {logSuccess && (
                                    <Alert key={"success"} variant={"success"} className="alert-solid">
                                        Invite sent successfully!
                                    </Alert>
                                )}
                            </Card.Footer>
                            <Form onSubmit={handleSubmit}/>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={lgHandleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSubmit}>
                            Send Invite
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>
            <Footer/>
        </React.Fragment>
    );

}