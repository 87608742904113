import React, { useState } from "react";

import axios from "axios";
import {

  Nav,
  Row,
Form,
Card,
  Tab,
  Col,
  Button,
  Spinner
} from "react-bootstrap";
import { formatRevenue  } from "../../logic/Utils";

import { Link } from "react-router-dom";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import MlrGrid from "./components/MlrGrid";
import Swal from "sweetalert2";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

export default function MlrAnalytics() {
  const [showInputModal, setShowInputModal] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = useState(null);
  const [providerMlr, setProviderMlr] = useState(null);
  const [planMlr, setPlanMlr] = useState(null);
  const[noRevenueClaims,setNoRevenueClaims] = useState ([]);
  const [isProcessed, setIsProcessed] = React.useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [revenueFile, setRevenueFile] = React.useState(null);

  const [claimsFile, setClaimsFile] = React.useState(null);


  const handleUpload = async () => {
    setIsLoading(true);

    setNoRevenueClaims([]);
    setData(null);
    setProviderMlr(null);
    setPlanMlr(null);
    const formData = new FormData();
   
    formData.append("claimsFile", claimsFile); 
    formData.append("revenueFile", revenueFile); 

    try {
      const response = await axios.post(
        `https://mtroflow-fksngumbvq-lz.a.run.app/analytics/upload-mlr?startDate=${startDate}&endDate=${endDate}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
       

        const clientMlr = response.data.result.mlrAnalyticsResponseList.filter(obj => !obj.providerBased);
const providerMlr = response.data.result.mlrAnalyticsResponseList.filter(obj => obj.providerBased);

        setNoRevenueClaims(response.data.result.noRevenueClaims);
        setPlanMlr(response.data.result.planMlrList);
        setData(clientMlr);
        setProviderMlr(providerMlr);
        setIsProcessed(true);
        setIsLoading(false);

        Swal.fire("Upload is successful", "Check the MLR Analytics tab for the result", "success");
      }

     
    } catch (error) {
      setIsLoading(false);
      // Handle errors, e.g., show an error message
      console.error("Upload error:", error);
    }
  };


   const handleSingleUpload = async () => {
    setIsLoading(true);
    const formData = new FormData();
   
    formData.append("claimsFile", claimsFile); 
  

    try {
      const response = await axios.post(
        `https://mtroflow-fksngumbvq-lz.a.run.app/analytics/upload-mlr-invoice?startDate=${startDate}&endDate=${endDate}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
       

        const clientMlr = response.data.result.filter(obj => !obj.providerBased);
const providerMlr = response.data.result.filter(obj => obj.providerBased);


        setData(clientMlr);
        setProviderMlr(providerMlr);
        setIsProcessed(true);
        setIsLoading(false);

        Swal.fire("Upload is successful", "Check the MLR Analytics tab for the result", "success");
      }

     
    } catch (error) {
      setIsLoading(false);
      // Handle errors, e.g., show an error message
      console.error("Upload error:", error);
    }
  };

  const noRevenueCol = [
    { 
      field: "id",
      headerName: "S/N",
      width: 25,
    },

  { field: "clientName", headerName: "Name of Client", width: 400},
  { field: "totalClaims", headerName: "Total Claims Value",valueFormatter: (params) => formatRevenue(params.value), width: 250 },
  { field: "encounterCount", headerName: "Total Encounter Count", width: 250 },

  ];

  return (
    <React.Fragment>
      <Header />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                MLR Analytics
              </li>
            </ol>
            <h4 className="main-title mb-0"> MLR Analytics </h4>
          </div>
        </div>
      {/* Tabs will here */}

      <Tab.Container defaultActiveKey={"uploadTab"} className="mt-5">
            <Nav className="nav-line ">
              <Nav.Item>
                <Nav.Link
                  style={{ marginRight: "20px" }}
                  eventKey="uploadTab"
                >
                  Upload Files
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  style={{ marginRight: "20px" }}
                  eventKey="mlr"
                >
                  MLR Analysis (By Client)
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  style={{ marginRight: "20px" }}
                  eventKey="mlr2"
                >
                  MLR Analysis (By Provider)
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  style={{ marginRight: "20px" }}
                  eventKey="planMlr"
                >
                  MLR Analysis (By Plan)
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  style={{ marginRight: "20px" }}
                  eventKey="noRevenue"
                >
                  Claims without Revenue
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
            <Tab.Pane eventKey="uploadTab">
                {

                 
                  <div>
                   <Card className="card-post mt-4">
                    <Card.Header>
                      <Card.Title>Upload Files</Card.Title>
                      <Link to="" className="link-more">
                        <i className="ri-more-2-fill"></i>
                      </Link>
                    </Card.Header>
                    <Card.Body>
                      <div className="mb-3">
                      
                        <Form.Group controlId="formFile" className="mb-3">
                         <Form.Label>Claims Data Set</Form.Label>
                          <Form.Control
                            onChange={(event) => setClaimsFile(event.target.files[0])}
                            type="file"
                          />
                        </Form.Group>

                        <Form.Group controlId="formFile" className="mb-3">
                         <Form.Label>Revenue Data Set</Form.Label>
                          <Form.Control
                            onChange={(event) => setRevenueFile(event.target.files[0])}
                            type="file"
                          />
                        </Form.Group>

                        <Row   style={{ marginBottom: "20px" }}>
                {" "}
                <Col sm="6">
                  <Form.Label htmlFor="formGroupExampleInput2">
                    Start Date
                  </Form.Label>

                  <Form.Control
                    name="startDate"
                    type="date"
                    id="startDate"
                    
                    className="form-control input-class"
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                  />
                </Col>
                <Col sm="6">
                  <Form.Label htmlFor="formGroupExampleInput2">
                    End Date
                  </Form.Label>

                  <Form.Control
                    name="endDate"
                    type="date"
                    className="form-control input-class"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                  />
                </Col>
              </Row>

                        {!isLoading && (
                          <Button variant="primary" onClick={handleUpload}>
                            Submit
                          </Button>
                        )}
                        {isLoading && (
                          <Button className="btn-sign">
                            Processing...
                            <span />
                            <Spinner size="sm" animation="border" />
                          </Button>
                        )}
                       
                      </div>
                    </Card.Body>
                    <Card.Footer></Card.Footer>
                  </Card>
                    <Card className="card-post mt-4">
                    <Card.Header>
                      <Card.Title>Upload Only Claims File (Using Invoices for analysis)</Card.Title>
                      <Link to="" className="link-more">
                        <i className="ri-more-2-fill"></i>
                      </Link>
                    </Card.Header>
                    <Card.Body>
                      <div className="mb-3">
                      
                        <Form.Group controlId="formFile" className="mb-3">
                         <Form.Label>Claims Data Set</Form.Label>
                          <Form.Control
                            onChange={(event) => setClaimsFile(event.target.files[0])}
                            type="file"
                          />
                        </Form.Group>

                        <Row   style={{ marginBottom: "20px" }}>
                {" "}
                <Col sm="6">
                  <Form.Label htmlFor="formGroupExampleInput2">
                    Start Date
                  </Form.Label>

                  <Form.Control
                    name="startDate"
                    type="date"
                    id="startDate"
                    
                    className="form-control input-class"
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                  />
                </Col>
                <Col sm="6">
                  <Form.Label htmlFor="formGroupExampleInput2">
                    End Date
                  </Form.Label>

                  <Form.Control
                    name="endDate"
                    type="date"
                    className="form-control input-class"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                  />
                </Col>
              </Row>

                        {!isLoading && (
                          <Button variant="primary" onClick={handleSingleUpload}>
                            Submit
                          </Button>
                        )}
                        {isLoading && (
                          <Button className="btn-sign">
                            Processing...
                            <span />
                            <Spinner size="sm" animation="border" />
                          </Button>
                        )}
                       
                      </div>
                    </Card.Body>
                    <Card.Footer></Card.Footer>
                  </Card>
                  </div>
                  
                }
              </Tab.Pane>
              <Tab.Pane eventKey="mlr">
                {(isProcessed && data!=null) && (
                  <MlrGrid data={data} isProvider={false}></MlrGrid>
              )
                }
              </Tab.Pane>
              <Tab.Pane eventKey="mlr2">
                {(isProcessed && providerMlr!=null) && (
                  <MlrGrid data={providerMlr} isProvider={true}></MlrGrid>
              )
                }
              </Tab.Pane>
              <Tab.Pane eventKey="planMlr">
                {(isProcessed && planMlr!=null) && (
                  <MlrGrid data={planMlr} isPlan={true}> </MlrGrid>
              )
                }
              </Tab.Pane>

              <Tab.Pane eventKey="noRevenue">
                { (isProcessed && noRevenueClaims.length > 0)&& (
                  <div style={{ height: 600, width: "100%" }}>
      <DataGrid
        rows={noRevenueClaims}
        slots={{ toolbar: GridToolbar }}
        columns={noRevenueCol}
        pageSize={100}
        search
      />
      </div>
              )
                }
              </Tab.Pane>
           
            </Tab.Content>
          </Tab.Container>

      </div>
    </React.Fragment>
  );
}
