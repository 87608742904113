import axios from "axios";

const SEND_INVITE_TO_USER ="https://mtroflow-fksngumbvq-lz.a.run.app/employee/send-invite";

const CREATE_PASSWORD = "https://mtroflow-fksngumbvq-lz.a.run.app/auth/reset-password/";



class registrationService {

    sendInvite(dto, token) {
        return axios.post(SEND_INVITE_TO_USER ,dto, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    }
    createPassword(dto, verificationToken) {
        return axios.post(CREATE_PASSWORD+verificationToken ,dto, {
        });
    }



    // Implement other methods with similar changes...
}

export const RegistrationService = new registrationService();
