import React, { useEffect, useState } from "react";

import { Card, Col, Row, Table, Nav } from "react-bootstrap";
import { formatRevenue, } from "../../../logic/Utils";

export default function MetricsBySource(props) {
  const dailySalesLeaderBoard = props.metrics.dailySalesLeaderBoard;
  const weeklySalesLeaderBoard = props.metrics.weeklySalesLeaderBoard;
  const monthlySalesLeaderBoard = props.metrics.monthlySalesLeaderBoard;



  const optionBar = {
    indexAxis: "y",
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 80,
        grid: {
          borderColor: "#e2e5ec",
          color: "#f3f5f9",
        },
        ticks: {
          font: {
            size: 11,
          },
        },
      },
      y: {
        grid: {
          borderWidth: 0,
          color: "#f3f5f9",
        },
        ticks: {
          color: "#212830",
          font: {
            size: 14,
          },
        },
      },
    },
  };



  // Changing necessary options between dark and light skin
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  if (skin === "dark") {
    optionBar.scales["x"].grid.color = "#222b41";
    optionBar.scales["x"].ticks.color = "rgba(255,255,255,.65)";
    optionBar.scales["x"].grid.borderColor = "#222b41";
    optionBar.scales["y"].grid.color = "#222b41";
    optionBar.scales["y"].ticks.color = "rgba(255,255,255,.65)";
  } else {
    optionBar.scales["x"].grid.color = "#edeff6";
    optionBar.scales["x"].ticks.color = "#42484e";
    optionBar.scales["x"].grid.borderColor = "#edeff6";
    optionBar.scales["y"].grid.color = "#edeff6";
    optionBar.scales["y"].ticks.color = "#42484e";
  }

  const switchSkin = (skin) => {
    const textPrimary = document.getElementsByClassName("text-primary-dark");

    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }

      for (const txt of textPrimary) {
        txt.classList.add("text-primary");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }

      for (const txt of textPrimary) {
        txt.classList.remove("text-primary");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Row style={{ marginTop: "15px" }} className="g-3">
        <Col sm="6" xl="4">
         
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Daily Leaderboard </Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
               
              {dailySalesLeaderBoard.length > 0 && (
                <Table className="table-five">
                  <tbody>
                    {dailySalesLeaderBoard.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div>
                            <span className={"badge-dot bg-primary"}></span>{" "}
                            <span className="fw-medium">
                              {item.salesOfficerName}
                            </span>
                          </div>
                        </td>
                        <td>{formatRevenue(item.totalRevenue)}</td>
                        <td>
                          <div className={"justify-content-end text-success"}>
                            {item.totalPopulationCount}{" "}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                )}
              </Card.Body>
            </Card>
      
        </Col>

        <Col sm="6" xl="4">
         
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Weekly Leaderboard </Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                
              {weeklySalesLeaderBoard.length > 0 && (
                <Table className="table-five">
                  <tbody>
                    {weeklySalesLeaderBoard.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div>
                            <span className={"badge-dot bg-primary"}></span>{" "}
                            <span className="fw-medium">
                              {item.salesOfficerName}
                            </span>
                          </div>
                        </td>
                        <td>{formatRevenue(item.totalRevenue)}</td>
                        <td>
                          <div className={"justify-content-end text-success"}>
                            {item.totalPopulationCount}{" "}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                )}
              </Card.Body>
            </Card>
        
        </Col>

        <Col sm="6" xl="4">
      
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Monthly Leaderboard </Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                
              {monthlySalesLeaderBoard.length > 0 && (
                <Table className="table-five">
                  <tbody>
                    {monthlySalesLeaderBoard.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div>
                            <span className={"badge-dot bg-primary"}></span>{" "}
                            <span className="fw-medium">
                              {item.salesOfficerName}
                            </span>
                          </div>
                        </td>
                        <td>{formatRevenue(item.totalRevenue)}</td>
                        <td>
                          <div className={"justify-content-end text-success"}>
                            {item.totalPopulationCount}{" "}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                )}
              </Card.Body>
            </Card>
        
        </Col>
      </Row>
    </React.Fragment>
  );
}
