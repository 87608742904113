import React, {Component, useRef, useState} from "react";
import {Link, NavLink, useNavigate} from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import userAvatar from "../assets/img/img1.jpg";
import favicon from "../assets/img/favicon.png";
import TaskContext from "../context/TaskContext";
import {
    dashboardMenu,
    teamReportsMenu,
    pagesMenu,
    hrMenu,
    lineManagerMenu,
    allReportsMenu,
    appsMenu

} from "../data/Menu";
import Avatar from "../components/Avatar";
import {Col, Image} from "react-bootstrap";
import img3 from "../assets/img/img3.jpg";
import {AuthService} from "../service/AuthService";


const Sidebar = () => {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [userDetails, setUserDetails] = useState(
        JSON.parse(localStorage.getItem('userDetails')) || {}
    );
    const navigate = useNavigate(); // Use the useNavigate hook directly
    const scrollBarRef = useRef(null);

    const handleLogout = () => {
        console.log('logging out');
        // Call logout service
        AuthService.logout(localStorage.getItem('token'))
            .then((res) => {


                if (res.status === 200) {
                    console.log('logout success');
                    navigate('/login', { replace: true }); // Use navigate directly
                }
            })
            .catch((err) => {
                console.log(err);
            });

        // Clear the stored JWT token
        localStorage.removeItem('token');
        localStorage.removeItem('userDetails');

        setToken(null);

    };

    const toggleFooterMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest('.sidebar');
        parent.classList.toggle('footer-menu-show');
    };

    return (
        <TaskContext.Consumer>
            {(taskContext) => (
                <div className="sidebar">
                    <div className="sidebar-header">
                        <div className="d-flex align-items-center">
                            <Image
                                src={favicon}
                                fluid={true}
                                width="20px"
                                alt="..."
                                className="mr-2"
                            />
                            <Link to="/" className="sidebar-logo" style={{ marginLeft: '10px' }}>
                                MetroHealth
                            </Link>
                        </div>
                    </div>
                    <PerfectScrollbar
                        className="sidebar-body"
                        containerRef={(ref) => (scrollBarRef.current = ref)}
                    >
                        <SidebarMenu
                            onUpdateSize={() => scrollBarRef.current.updateScroll()}
                        />
                    </PerfectScrollbar>
                    <div className="sidebar-footer">
                        <div className="sidebar-footer-top">
                            <div className="sidebar-footer-thumb">
                                <img src={userAvatar} alt="" />
                            </div>
                            <div className="sidebar-footer-body">
                                <h6>
                                    <Link to="">
                                        {userDetails.firstName ? userDetails.firstName + ' ' + userDetails.lastName : ''}
                                    </Link>
                                </h6>
                                <p>{userDetails ? userDetails.jobTitle : ''}</p>
                            </div>
                            <Link
                                onClick={toggleFooterMenu}
                                to=""
                                className="dropdown-link"
                            >
                                <i className="ri-arrow-down-s-line"></i>
                            </Link>
                        </div>
                        <div className="sidebar-footer-menu">
                            <nav className="nav">
                                <Link to="">
                                    <i className="ri-edit-2-line"></i> Edit Profile
                                </Link>
                                <Link to="">
                                    <i className="ri-profile-line"></i> View Profile
                                </Link>
                            </nav>
                            <hr />
                            <nav className="nav">
                                <Link to="">
                                    <i className="ri-question-line"></i> Help Center
                                </Link>
                                <Link to="">
                                    <i className="ri-lock-line"></i> Privacy Settings
                                </Link>
                                <Link to="">
                                    <i className="ri-user-settings-line"></i> Account
                                    Settings
                                </Link>
                                <Link onClick={handleLogout} to="/login">
                                    <i className="ri-logout-box-r-line"></i> Log Out
                                </Link>
                            </nav>
                        </div>
                    </div>
                </div>
            )}
        </TaskContext.Consumer>
    );
};

export default Sidebar;



class SidebarMenu extends Component {
    populateMenu = (m) => {
        const menu = m.map((m, key) => {
            let sm;
            if (m.submenu) {
                sm = m.submenu.map((sm, key) => {
                    return (
                        <NavLink to={sm.link} className="nav-sub-link" key={key}>{sm.label}</NavLink>
                    )
                })
            }

            return (


                <li key={key} className="nav-item">
                    {(!sm) ? (
                        <NavLink to={m.link} className="nav-link"><i className={m.icon}></i> <span>{m.label}</span></NavLink>
                    ) : (
                        <div onClick={this.toggleSubMenu} className="nav-link has-sub"><i className={m.icon}></i> <span>{m.label}</span></div>
                    )}
                    {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
                </li>
            )
        });

        return (
            <ul className="nav nav-sidebar">
                {menu}
            </ul>
        );
    }

    // Toggle menu group
    toggleMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest('.nav-group');
        parent.classList.toggle('show');

        this.props.onUpdateSize();
    }

    // Toggle submenu while closing siblings' submenu
    toggleSubMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest('.nav-item');
        let node = parent.parentNode.firstChild;

        while (node) {
            if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
                node.classList.remove('show');
            node = node.nextElementSibling || node.nextSibling;
        }

        parent.classList.toggle('show');

        this.props.onUpdateSize();
    }

    render() {

        return (
            <TaskContext.Consumer>
                {(taskContext) => {
                    // const { userDetails } = taskContext;
                    let userDetails = {};
                    const userDetailsJSON = localStorage.getItem('userDetails');
                            userDetails = JSON.parse(userDetailsJSON);
                    const userRole = userDetails ? userDetails.role: '';
                    const allowedRoles = ["MANAGER"];
                    const shouldRenderLineManagerSection = allowedRoles.includes(userRole);
                    const shouldHRManagerSection = (userRole==="HR");
                 
                    return(
                    <React.Fragment>
                       
                            <div className="nav-group show">
                                <div className="nav-label" onClick={this.toggleMenu}>Dashboard</div>
                                {this.populateMenu(dashboardMenu)}
                            </div>

                           
                    
            
                        {(shouldRenderLineManagerSection || shouldHRManagerSection )&& (
                            <div className="nav-group show">
                                <div className="nav-label" onClick={this.toggleMenu}>Line-Manager Section</div>
                                {this.populateMenu(lineManagerMenu)}
                            </div>
                        )}
                        <div className="nav-group show">
                                <div className="nav-label" onClick={this.toggleMenu}>Metro Apps</div>
                                {this.populateMenu(appsMenu)}
                            </div>
                        {shouldHRManagerSection && (
                        <div className="nav-group show">
                            <div className="nav-label" onClick={this.toggleMenu}>HR Section</div>
                            {this.populateMenu(hrMenu)}
                        </div>

                        )}
                        {shouldRenderLineManagerSection && (
                        <div className="nav-group show">
                            <div className="nav-label" onClick={this.toggleMenu}>Reports</div>
                            {this.populateMenu(teamReportsMenu)}
                        </div>
                        )}
                        {shouldHRManagerSection && (
                        <div className="nav-group show">
                            <div className="nav-label" onClick={this.toggleMenu}>Team Reports</div>
                            {this.populateMenu(allReportsMenu)}
                        </div>
                        )}
                        <div className="nav-group show">
                            <div className="nav-label" onClick={this.toggleMenu}>Profile</div>
                            {this.populateMenu(pagesMenu)}
                        </div>
                    </React.Fragment>
                )
                }}
            </TaskContext.Consumer>
           );
    }

}

window.addEventListener("click", function (e) {
    // Close sidebar footer menu when clicked outside of it
    let tar = e.target;
    let sidebar = document.querySelector(".sidebar");
    if (!tar.closest(".sidebar-footer") && sidebar) {
        sidebar.classList.remove("footer-menu-show");
    }

    // Hide sidebar offset when clicked outside of sidebar
    if (!tar.closest(".sidebar") && !tar.closest(".menu-link")) {
        document.querySelector("body").classList.remove("sidebar-show");
    }
});

window.addEventListener("load", function () {
    let skinMode = localStorage.getItem("sidebar-skin");
    let HTMLTag = document.querySelector("html");

    if (skinMode) {
        HTMLTag.setAttribute("data-sidebar", skinMode);
    }
});