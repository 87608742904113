import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Table,
  Row,
  Col,
  Card,
  Nav,
  Form,
} from "react-bootstrap";

import Prism from "prismjs";
import HeaderMobile from "../layouts/HeaderMobile";


const TaskDetails = (props) => {
  const onClose = props.onClose;
  const taskData = props.taskData;
  const employeeName = props.employeeData;

  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const handleClose2 = () => {
    onClose();
  };

  return (
    <React.Fragment>
      <HeaderMobile />
      <div className="main main-docs">
        <Modal show={showModal} centered size="xl">
          <Modal.Header closeButton onHide={handleClose2}></Modal.Header>

          <Modal.Body>
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Tasks Details for {employeeName}</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                {taskData.length > 0 && (
                  <Table className="table-agent2 mb-0" responsive>
                    <thead>
                      <tr>
                       
                        <th>Name</th>
                           <th>Task Description</th>
                        <th>Created By</th>
                        <th> Date Created</th>
                        <th>Due Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {taskData.map((item) => (
                        <tr key={item.taskId}>
                         
                          <td>{item.name}</td>
                          <td>{item.taskDescription}</td>
                          <td>{item.creator}</td>
                          <td>{item.createdTime}</td>
                          <td>{item.dueDate}</td>
                          <td>{item.status}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Card.Body>
            </Card>
          </Modal.Body>

          <Modal.Footer>
            {/* You can add additional buttons or controls here */}
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
};
export default TaskDetails;
