import React, { useEffect, useState} from "react";
import Prism from "prismjs";
import { Modal,Image} from "react-bootstrap";

import HeaderMobile from "../layouts/HeaderMobile";

import success from "../assets/img/success_icon.png"

import {useNavigate} from "react-router-dom";
export default function SuccessModal(props) {

    const message = props.message;
    const destination = props.destination;

    const [showModal, setShowModal] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const handleClose2 = () => {

         setShowModal(false)

         navigate(destination, { replace: true });

    };



    return (
        <React.Fragment>
            <HeaderMobile />
            <div className="main main-docs">
                <Modal show={showModal}  onHide={handleClose2} centered size="sm">
                    <Modal.Header closeButton>
                        {/*<Modal.Title>Modal heading</Modal.Title>*/}
                    </Modal.Header>
                    <Modal.Body>
                        <Image src={success} fluid={true} alt="..." />
                        <h3 className="text-center">{message}</h3>
                    </Modal.Body>
                    <Modal.Footer>
                        {/*<Button variant="secondary" onClick={handleClose2}>*/}
                        {/*    Close*/}
                        {/*</Button>*/}
                    </Modal.Footer>
                </Modal>
            </div>

        </React.Fragment>
    );
}