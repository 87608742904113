import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap";

import { showLoadingModal,hideLoadingModal } from "../../../logic/Utils";
import Swal from "sweetalert2";
import { AnalyticsService } from "../../../service/AnalyticsService";

import Sidebar from "../../../layouts/Sidebar";
import DepartmentDropdown from "../../../components/DepartmentDropdown";

export default function SalesTargetInput(props) {
  const closeModal = props.closeModal;
  useEffect(() => {
    Prism.highlightAll();
    //authorize entry
  }, []);


  const [lgShow, setLgShow] = useState(true);

  const [metromidiValueAmount, setMetromidiValueAmount] = useState(0);
  const [totalRevenueValue, setTotalRevenueValue] = useState(0);
  const [metromidiUnitCount, setMetromidiUnitCount] = useState(0);
  const [metrominiValueAmount, setMetrominiValueAmount] = useState(0);
  const [metrominiUnitCount, setMetrominiUnitCount] = useState(0);
  const [flexiValueAmount, setFlexiValueAmount] = useState(0);
  const [flexiUnitCount, setFlexiUnitCount] = useState(0);
  const [pppValueAmount, setPPPValueAmount] = useState(0);
  const [pppUnitCount, setPPPUnitCount] = useState(0);
  const [nhiaValueAmount, setNhiaValueAmount] = useState(0);
  const [nhiaUnitCount, setNhiaUnitCount] = useState(0);
  const [metromaxiValueAmount, setMetromaxiValueAmount] = useState(0);
  const [metromaxiUnitCount, setMetromaxiUnitCount] = useState(0);

  const userDetails = useState(
    JSON.parse(localStorage.getItem("userDetails")) || {}
  );
  const [salesOfficer, setSalesOfficer] = useState("");
  const [editMode, setEditMode] = useState(false);
  useEffect(() => {


    if (!checkPermitDropdown(userDetails[0]) ){
        setSalesOfficer(userDetails[0].userId);
        getSalesTarget(userDetails[0].userId);
    }
  }, []);
  const validateDto = () => {
    const errorMessages = [];

    // Check if a non-digit was given as input for unit counts and value amounts
    const isNonDigit = (value) => /^\d+$/.test(value);

    // Validate Metro Midi
    if (!isNonDigit(metromidiUnitCount)) {
      errorMessages.push("Metro Midi Unit Count must be a valid number.");
    }

    if (!isNonDigit(metromidiValueAmount)) {
      errorMessages.push("Metro Midi Value Amount must be a valid number.");
    }

    // Validate Metro Mini
    if (!isNonDigit(metrominiUnitCount)) {
      errorMessages.push("Metro Mini Unit Count must be a valid number.");
    }

    if (!isNonDigit(metrominiValueAmount)) {
      errorMessages.push("Metro Mini Value Amount must be a valid number.");
    }

    // Validate Flexi
    if (!isNonDigit(flexiUnitCount)) {
      errorMessages.push("Flexi Unit Count must be a valid number.");
    }

    if (!isNonDigit(flexiValueAmount)) {
      errorMessages.push("Flexi Value Amount must be a valid number.");
    }

    // Validate PPP
    if (!isNonDigit(pppUnitCount)) {
      errorMessages.push("PPP Unit Count must be a valid number.");
    }

    if (!isNonDigit(pppValueAmount)) {
      errorMessages.push("PPP Value Amount must be a valid number.");
    }

    // Validate NHIA
    if (!isNonDigit(nhiaUnitCount)) {
      errorMessages.push("NHIA Unit Count must be a valid number.");
    }

    if (!isNonDigit(nhiaValueAmount)) {
      errorMessages.push("NHIA Value Amount must be a valid number.");
    }

    // Validate Metro Maxi
    if (!isNonDigit(metromaxiUnitCount)) {
      errorMessages.push("Metro Maxi Unit Count must be a valid number.");
    }

    if (!isNonDigit(metromaxiValueAmount)) {
      errorMessages.push("Metro Maxi Value Amount must be a valid number.");
    }
   

    if (!salesOfficer) {
      errorMessages.push("A sales Officer must be selected");
    }

   

    if (!totalRevenueValue>0) {
      errorMessages.push("Total Revenue must be greater than zero.");
    }
    // if (!calculateTotalUnitCount()>0) {
    //   errorMessages.push("Total Sales must be greater than zero.");
    // }


    // Display error messages if any
    if (errorMessages.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: errorMessages.join("\n"),
      });

      return false;
    }

    return true;
  };

  function checkPermitDropdown(userDetails) {
   
    const result = (
      (userDetails.role == "MANAGER" && userDetails.department == "SALES") ||
      userDetails.department == "EXCO" ||
      userDetails.role == "ADMIN"
  );

    return result;
}


  const getSalesTarget = (id) => {
   
    showLoadingModal();
   
    AnalyticsService.getSalesTargetForTeamMember(id, localStorage.getItem("token"))
      .then((res) => {
        if (res.status === 200) {
            hideLoadingModal();
            if(!res.data.result) {
                
                Swal.fire("No target has been set, create one","","info");}
                else{
                  
                  setTotalRevenueValue(res.data.result.annualRevenueAmountTarget);
                  setEditMode(true);}
        }
      })
      .catch((err) => {
        hideLoadingModal();
        console.log(err);
      });
  };
  const handleItemClick = (team) => {
    setSalesOfficer(team.users_id);
    getSalesTarget(team.users_id);
  };

  const calculateTotalUnitCount = () => {
    // Convert the values to numbers before summing
    const metromidiUnit = parseInt(metromidiUnitCount, 10) || 0;
    const metrominiUnit = parseInt(metrominiUnitCount, 10) || 0;
    const flexiUnit = parseInt(flexiUnitCount, 10) || 0;
    const pppUnit = parseInt(pppUnitCount, 10) || 0;
    const nhiaUnit = parseInt(nhiaUnitCount, 10) || 0;
    const metromaxiUnit = parseInt(metromaxiUnitCount, 10) || 0;

    // Calculate the total sum
    const totalUnitCount =
      metromidiUnit +
      metrominiUnit +
      flexiUnit +
      pppUnit +
      nhiaUnit +
      metromaxiUnit;

    return totalUnitCount;
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateDto()) {
      return;
    }

    showLoadingModal();
    let salesDto = {
      revenueAmountTarget: totalRevenueValue,
      pppUnitCountTarget: metromidiUnitCount,
      metrominiUnitCountTarget: metrominiUnitCount,
      flexiUnitCountTarget: flexiUnitCount,
      metromidiUnitCountTarget: pppUnitCount,
      nhiaUnitCountTarget: nhiaUnitCount,
      metromaxiUnitCountTarget: metromaxiUnitCount,
      metromidiValueAmountTarget: metromidiValueAmount,
      metrominiValueAmountTarget: metrominiValueAmount,
      flexiValueAmountTarget: flexiValueAmount,
      pppValueAmountTarget: pppValueAmount,
      nhiaValueAmountTarget: nhiaValueAmount,
      metromaxiValueAmountTarget: metromaxiValueAmount,
      salesOfficerId: salesOfficer,
      totalUnitCountTarget: calculateTotalUnitCount(),
    };


 if (editMode){
    AnalyticsService.editSalesTarget(salesDto, localStorage.getItem("token"),salesOfficer)
    .then((res) => {
      if (res.status === 201) {
        Swal.fire("Sales Target has been added", "", "success").then(
          (result) => {
            if (result.isConfirmed) {
              closeModal();
            }
          }
        );
      }
    })
    .catch((err) => {
      hideLoadingModal();
      console.log(err);
    });
 }

 else{
    AnalyticsService.saveSalesTarget(salesDto, localStorage.getItem("token"))
    .then((res) => {
      if (res.status === 201) {
        Swal.fire("Sales Target has been added", "", "success").then(
          (result) => {
            if (result.isConfirmed) {
              closeModal();
            }
          }
        );
      }
    })
    .catch((err) => {
      hideLoadingModal();
      console.log(err);
    });
 }
  };

  return (
    <React.Fragment>
      <Sidebar/>
      
      <div className="main main-docs">
        <Modal show={lgShow} onHide={closeModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Sales Target</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card>
              <Card.Body>
                <div className="mb-3">
                  <Row>
                    {" "}
                    {checkPermitDropdown(userDetails[0]) && (
                      <div style={{ marginBottom: "20px" }}>
                        <DepartmentDropdown
                          teamId={8}
                          handleItemClick={handleItemClick}
                        ></DepartmentDropdown>
                      </div>
                    )}
                    <br />
                
                  </Row>
                </div>
                <div className="mb-3">
                  <Row>
                    {" "}
                    <Col sm="6">
                      <Form.Label> Total Annual Target</Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setTotalRevenueValue(event.target.value)
                        }
                        type="text"
                        value={totalRevenueValue}
                        id="formGroupExampleInput"
                        placeholder="   Total Annual Target"
                      />
                    </Col>
                   
                  </Row>
                </div>
                <div className="mb-3">
                  <Row>
                    {" "}
                    <Col sm="6">
                      <Form.Label> Unit Sales for PPP</Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setPPPUnitCount(event.target.value)
                        }
                        type="text"
                        value={pppUnitCount}
                        id="formGroupExampleInput"
                        placeholder="  Unit Sales for PPP"
                      />
                    </Col>
                    <Col sm="6">
                      <Form.Label> PPP Value (Naira) </Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setPPPValueAmount(event.target.value)
                        }
                        type="text"
                        value={pppValueAmount}
                        id="formGroupExampleInput"
                        placeholder="  Value for PPP"
                      />
                    </Col>
                  </Row>
                </div>
                <div className="mb-3">
                  <Row>
                    {" "}
                    <Col sm="6">
                      <Form.Label> Unit Sales for Metro Maxi</Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setMetromaxiUnitCount(event.target.value)
                        }
                        type="text"
                        value={metromaxiUnitCount}
                        id="formGroupExampleInput"
                        placeholder="  Unit Sales for Metro Midi"
                      />
                    </Col>
                    <Col sm="6">
                      <Form.Label> Metro Maxi Value (Naira) </Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setMetromaxiValueAmount(event.target.value)
                        }
                        type="text"
                        value={metromaxiValueAmount}
                        id="formGroupExampleInput"
                        placeholder="  Value for Metromaxi"
                      />
                    </Col>
                  </Row>
                </div>
                <div className="mb-3">
                  <Row>
                    {" "}
                    <Col sm="6">
                      <Form.Label> Unit Sales for Metro Maxi</Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setMetromaxiUnitCount(event.target.value)
                        }
                        type="text"
                        value={metromaxiUnitCount}
                        id="formGroupExampleInput"
                        placeholder="  Unit Sales for Metro Midi"
                      />
                    </Col>
                    <Col sm="6">
                      <Form.Label> Metro Maxi Value (Naira) </Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setMetromaxiValueAmount(event.target.value)
                        }
                        type="text"
                        value={metromaxiValueAmount}
                        id="formGroupExampleInput"
                        placeholder="  Value for Metromaxi"
                      />
                    </Col>
                  </Row>
                </div>

                <div className="mb-3">
                  <Row>
                    {" "}
                    <Col sm="6">
                      <Form.Label> Unit Sales for Metro Midi</Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setMetromidiUnitCount(event.target.value)
                        }
                        type="text"
                        value={metromidiUnitCount}
                        id="formGroupExampleInput"
                        placeholder="  Unit Sales for Metro Midi"
                      />
                    </Col>
                    <Col sm="6">
                      <Form.Label> Metro Midi Value (Naira) </Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setMetromidiValueAmount(event.target.value)
                        }
                        type="text"
                        value={metromidiValueAmount}
                        id="formGroupExampleInput"
                        placeholder="  Value for Metromidi"
                      />
                    </Col>
                  </Row>
                </div>

                <div className="mb-3">
                  <Row>
                    {" "}
                    <Col sm="6">
                      <Form.Label> Unit Sales for Metro Mini</Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setMetrominiUnitCount(event.target.value)
                        }
                        type="text"
                        value={metrominiUnitCount}
                        id="formGroupExampleInput"
                        placeholder="  Unit Sales for Metro Mini"
                      />
                    </Col>
                    <Col sm="6">
                      <Form.Label> Metro Mini Value (Naira) </Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setMetrominiValueAmount(event.target.value)
                        }
                        type="text"
                        value={metrominiValueAmount}
                        id="formGroupExampleInput"
                        placeholder="  Value for Metromini"
                      />
                    </Col>
                  </Row>
                </div>

                <div className="mb-3">
                  <Row>
                    {" "}
                    <Col sm="6">
                      <Form.Label> Unit Sales for Flexi</Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setFlexiUnitCount(event.target.value)
                        }
                        type="text"
                        value={flexiUnitCount}
                        id="formGroupExampleInput"
                        placeholder="  Unit Sales for Flexi"
                      />
                    </Col>
                    <Col sm="6">
                      <Form.Label> Flexi Value (Naira) </Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setFlexiValueAmount(event.target.value)
                        }
                        type="text"
                        value={flexiValueAmount}
                        id="formGroupExampleInput"
                        placeholder="  Value for Flexi"
                      />
                    </Col>
                  </Row>
                </div>

                <div className="mb-3">
                  <Row>
                    {" "}
                    <Col sm="6">
                      <Form.Label> Unit Sales for NHIA</Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setNhiaUnitCount(event.target.value)
                        }
                        type="text"
                        value={nhiaUnitCount}
                        id="formGroupExampleInput"
                        placeholder="  Unit Sales for Metro Midi"
                      />
                    </Col>
                    <Col sm="6">
                      <Form.Label> NHIA Value (Naira) </Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setNhiaValueAmount(event.target.value)
                        }
                        type="text"
                        value={nhiaValueAmount}
                        id="formGroupExampleInput"
                        placeholder="  Value for NHIA"
                      />
                    </Col>
                  </Row>
                </div>

                <div className="mb-3">
                  <Row className="justify-content-center">
                   
                    <Col md="auto">
                      <Button
                        variant="success"
                        onClick={handleSubmit}
                        className="btn-sign"
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card.Body>

              <Card.Footer></Card.Footer>
              <Form onSubmit={handleSubmit} />
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
}
