import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Button, Col, Form, Modal, Nav, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Avatar from "../components/Avatar";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import moment from "moment";
import { RRule } from "rrule";

import img6 from "../assets/img/img6.jpg";
import img8 from "../assets/img/img8.jpg";
import img10 from "../assets/img/img10.jpg";
import img12 from "../assets/img/img12.jpg";
import img14 from "../assets/img/img14.jpg";
import img15 from "../assets/img/img15.jpg";
import Sidebar from "../layouts/Sidebar";
import { TeamService } from "../service/TeamService";

export default function AppCalendar() {
  useEffect(() => {
    document.body.classList.add("app-calendar");
    return () => {
      document.body.classList.remove("app-calendar");
    };
  }, []);

  const [startDate, setStartDate] = useState(new Date());

  const curYear = moment().format("YYYY");
  const curMonth = moment().format("MM");

  // Calendar Event Source
  const calendarEvents = {
    id: 1,
    backgroundColor: "#d9e8ff",
    borderColor: "#0168fa",
    events: [
      {
        id: "1",
        start: curYear + "-" + curMonth + "-08T08:30:00",
        end: curYear + "-" + curMonth + "-08T13:00:00",
        title: "ThemeForest Meetup",
        description:
          "In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis az pede mollis...",
      },
      {
        id: "2",
        start: curYear + "-" + curMonth + "-10T09:00:00",
        end: curYear + "-" + curMonth + "-10T17:00:00",
        title: "Design Review",
        description:
          "In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis az pede mollis...",
      },
    ],
  };

  // toggle sidebar calendar
  const [isSidebarShow, setSidebarShow] = useState(false);

  // Modal
  const [modalShow, setModalShow] = useState(false);
  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);
  const [teamsData, setTeamsData] = React.useState([]);
  const [employeeSchedule, setEmployeeSchedule] = useState({});
  const [selectedDay, setSelectedDay] = useState("");
  const [teamSchedule, setTeamSchedule] = useState({});
  const [isLoadingSchedule, setIsLoadingSchedule] = useState(false);
  const [currentDayEmployees, setCurrentDayEmployees] = useState([]);

  // Handle checkbox change event
  const handleCheckboxChange = (userId, checked) => {
    setEmployeeSchedule((prevSchedule) => ({
      ...prevSchedule,
      [userId]: checked,
    }));
  };

  useEffect(() => {
    TeamService.getTeamInfoByManager(localStorage.getItem("token"))
      .then((res) => {
        setTeamsData(res.data.result.memberProfiles);
      })
      .catch((err) => {
        console.log(err);
      });

    TeamService.getTeamSchedule(localStorage.getItem("token"))
      .then((res) => {
        // console.log(res.data.result.employeeSchedules);

        if (res.status === 200) {
          setTeamSchedule(
            mapEmployeeSchedulesToCalendarEvents(
              res.data.result.employeeSchedules
            )
          );
          const employeesToday = getEmployeesWorkingToday(
            res.data.result.employeeSchedules
          );
          setCurrentDayEmployees(employeesToday);
          setIsLoadingSchedule(true);
          console.log(currentDayEmployees);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function getEmployeesWorkingToday(employeeSchedules) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDate = new Date();

    const currentDayOfWeek = daysOfWeek[currentDate.getDay()];

    // const currentDayOfWeek = "Friday";
    if (employeeSchedules[currentDayOfWeek]) {
      return employeeSchedules[currentDayOfWeek];
    } else {
      return [];
    }
  }
  const getTeamSChedule = () => {
    setIsLoadingSchedule(false);

    TeamService.getTeamSchedule(localStorage.getItem("token"))
      .then((res) => {
        // console.log(res.data.result.employeeSchedules);

        if (res.status === 200) {
          setTeamSchedule(
            mapEmployeeSchedulesToCalendarEvents(
              res.data.result.employeeSchedules
            )
          );

        setIsLoadingSchedule(true);
        setIsLoadingSchedule(true);
      
          setIsLoadingSchedule(true);
      
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleScheduleSave = () => {
    const requestDto = {
      dayOfWeek: selectedDay,
      employeeSchedule: employeeSchedule,
    };

    TeamService.saveSchedule(requestDto, localStorage.getItem("token"))
      .then((res) => {
        getTeamSChedule();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function mapEmployeeSchedulesToCalendarEvents(employeeSchedules) {
    const calendarEvents = {
      id: 1,
      backgroundColor: "#d9e8ff",
      borderColor: "#0168fa",
      events: [],
    };

    const dayOfWeekMap = {
      Sunday: "0",
      Monday: "1",
      Tuesday: "2",
      Wednesday: "3",
      Thursday: "4",
      Friday: "5",
      Saturday: "6",
    };

    for (const dayOfWeek in employeeSchedules) {
      const employeesForDay = employeeSchedules[dayOfWeek];

      employeesForDay.forEach((employee, index) => {
        const eventId = `${dayOfWeek}-${index}`;
        const event = {
          id: eventId,
          daysOfWeek: [dayOfWeekMap[dayOfWeek]],
          startTime: "09:00:00",
          endTime: "16:00:00",
          title: employee.fullname,
          description: "Working from home today",
        };

        calendarEvents.events.push(event);
      });
    }

    return calendarEvents;
  }
  
  


  




  

  return (
    <React.Fragment>
      <Sidebar />
      <Header />
      <div className={"main main-calendar" + (isSidebarShow ? " show" : "")}>
        <div className="calendar-sidebar">
          <PerfectScrollbar className="sidebar-body">
            <div className="d-grid mb-3">
              <Button variant="primary" onClick={handleModalShow}>
                Create WFH Schedule
              </Button>
            </div>

            <div className="mb-5"></div>

            <h5 className="section-title section-title-sm mb-4">
              Members Working from Home Today
            </h5>

            <ul className="event-group mb-5">
              {
                /* [
                {
                  title: "Project Concept Meeting",
                  schedule: "08:30am - 11:30am",
                  mutual: {
                    avatar: [img15, img14],
                    user: "Lea",
                    count: 4,
                  },
                }
              ] */

                currentDayEmployees.map((user, users_id) => (
                  <ul className="event-item" key={users_id}>
                    <div className="event-body">
                      <h6>
                        <span>
                          <i className="ri-user-fill"></i>
                        </span>{" "}
                        <Link to="">
                          {user.firstName + " " + user.lastName}
                        </Link>
                      </h6>
                      <p>{user.jobTitle}</p>
                      <div className="mutual-badge"></div>
                    </div>
                  </ul>
                ))
              }
            </ul>
          </PerfectScrollbar>
        </div>

        <div className="calendar-body">
          {isLoadingSchedule && (
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin]}
              initialView="dayGridMonth"
              headerToolbar={{
                left: "custom1 prev,next today",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              }}
              eventSources={[teamSchedule]}
              customButtons={{
                custom1: {
                  icon: "chevron-left",
                  click: function () {
                    setSidebarShow(!isSidebarShow);
                  },
                },
              }}
            />
          )}

          <Modal
            className="modal-event"
            show={modalShow}
            onHide={handleModalClose}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Create WFH Schedule</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="mb-3">
                <Form.Label>Day of the Week:</Form.Label>
                <Form.Select
                  onChange={(e) => {
                    const selectedValue = e.target.value; // Get the selected value from the event
                    setSelectedDay(selectedValue); // Update the state with the selected value
                  }}
                >
                  <option value="">Choose day</option>
                  <option value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>
                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>
                  <option value="Friday">Friday</option>
                  <option value="Saturday">Saturday</option>
                  <option value="Sunday">Sunday</option>
                </Form.Select>
              </div>

              <Row className="g-3 mb-3">
                <Form.Label>Team Member Selection</Form.Label>
                <Col>
                  {teamsData.map((user) => (
                    <Form.Check
                      key={user.users_id} // Replace with your actual user identifier
                      type="checkbox"
                      label={user.firstName + " " + user.lastName} // Replace with the employee name property
                      checked={employeeSchedule[user.users_id] || false}
                      onChange={(e) =>
                        handleCheckboxChange(user.users_id, e.target.checked)
                      }
                    />
                  ))}
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant=""
                className="btn-white"
                onClick={handleModalClose}
              >
                Close
              </Button>
              <Button variant="primary" onClick={handleScheduleSave}>
                Save Schedule
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}
