import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import HeaderMobile from "../../../layouts/HeaderMobile";
import { hideLoadingModal,showLoadingModal} from "../../../logic/Utils";
import Sidebar from "../../../layouts/Sidebar";
import Swal from "sweetalert2";

import { AnalyticsService } from "../../../service/AnalyticsService";

export default function EditSalesRecord(props) {
  const closeModal = props.closeModal;
  const salesRecord = props.salesRecord;
  useEffect(() => {
    Prism.highlightAll();
    //authorize entry
  }, []);

  const [lgShow, setLgShow] = useState(true);
  const [metromidiValueAmount, setMetromidiValueAmount] = useState(salesRecord.metromidiValueAmount);
  const [metromidiUnitCount, setMetromidiUnitCount] = useState(salesRecord.metromidiPopulationCount);
  const [metrominiValueAmount, setMetrominiValueAmount] = useState(salesRecord.metrominiValueAmount);
  const [metrominiUnitCount, setMetrominiUnitCount] = useState(salesRecord.metrominiPopulationCount);
  const [flexiValueAmount, setFlexiValueAmount] = useState(salesRecord.flexiValueAmount);
  const [flexiUnitCount, setFlexiUnitCount] = useState(salesRecord.flexiPopulationCount);
  const [pppValueAmount, setPPPValueAmount] = useState(salesRecord.pppValueAmount);
  const [pppUnitCount, setPPPUnitCount] = useState(salesRecord.pppPopulationCount);
  const [nhiaValueAmount, setNhiaValueAmount] = useState(salesRecord.nhiaValueAmount);
  const [nhiaUnitCount, setNhiaUnitCount] = useState(salesRecord.nhiaPopulationCount);
  const [metromaxiValueAmount, setMetromaxiValueAmount] = useState(salesRecord.metromaxiValueAmount);
  const [metromaxiUnitCount, setMetromaxiUnitCount] = useState(salesRecord.metromaxiPopulationCount);
  const [clientName, setClientName] = useState(salesRecord.nameOfClient);
  const [dateOfSale, setDateOfSale] = useState(salesRecord.dateOfSale);
  const [salesOfficerId, setSalesOfficerId] = useState(salesRecord.salesOfficerId);

  const validateDto = () => {
    
    const errorMessages = [];

  // Check if a non-digit was given as input for unit counts and value amounts
  const isNonDigit = (value) => /^\d+$/.test(value);

  // Validate Metro Midi
  if (!isNonDigit(metromidiUnitCount)) {
    errorMessages.push("Metro Midi Unit Count must be a valid number.");
  }

  if (!isNonDigit(metromidiValueAmount)) {
    errorMessages.push("Metro Midi Value Amount must be a valid number.");
  }

  // Validate Metro Mini
  if (!isNonDigit(metrominiUnitCount)) {
    errorMessages.push("Metro Mini Unit Count must be a valid number.");
  }

  if (!isNonDigit(metrominiValueAmount)) {
    errorMessages.push("Metro Mini Value Amount must be a valid number.");
  }

  // Validate Flexi
  if (!isNonDigit(flexiUnitCount)) {
    errorMessages.push("Flexi Unit Count must be a valid number.");
  }

  if (!isNonDigit(flexiValueAmount)) {
    errorMessages.push("Flexi Value Amount must be a valid number.");
  }

  // Validate PPP
  if (!isNonDigit(pppUnitCount)) {
    errorMessages.push("PPP Unit Count must be a valid number.");
  }

  if (!isNonDigit(pppValueAmount)) {
    errorMessages.push("PPP Value Amount must be a valid number.");
  }

  // Validate NHIA
  if (!isNonDigit(nhiaUnitCount)) {
    errorMessages.push("NHIA Unit Count must be a valid number.");
  }

  if (!isNonDigit(nhiaValueAmount)) {
    errorMessages.push("NHIA Value Amount must be a valid number.");
  }

  // Validate Metro Maxi
  if (!isNonDigit(metromaxiUnitCount)) {
    errorMessages.push("Metro Maxi Unit Count must be a valid number.");
  }

  if (!isNonDigit(metromaxiValueAmount)) {
    errorMessages.push("Metro Maxi Value Amount must be a valid number.");
  }
    if (  !clientName){
        errorMessages.push("A client must be provided.");
      }

    if (  !salesOfficerId){
      errorMessages.push("A sales Officer must be selected");
    }

    if ( !dateOfSale ){
      errorMessages.push("Sale Date is required.");
    }

    // Display error messages if any
    if (errorMessages.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: errorMessages.join("\n"),
      });

      return false; 
    }

    return true; 
  };
  const calculateTotalRevenue = () => {
    // Convert the values to numbers before summing
    const metromidiValue = parseInt(metromidiValueAmount, 10) || 0;
    const metrominiValue = parseInt(metrominiValueAmount, 10) || 0;
    const flexiValue = parseInt(flexiValueAmount, 10) || 0;
    const pppValue = parseInt(pppValueAmount, 10) || 0;
    const nhiaValue = parseInt(nhiaValueAmount, 10) || 0;
    const metromaxiValue = parseInt(metromaxiValueAmount, 10) || 0;
  
    // Calculate the total sum
    const totalRevenue =
      metromidiValue + metrominiValue + flexiValue + pppValue + nhiaValue + metromaxiValue;
  
    return totalRevenue;
  };

  const calculateTotalUnitCount = () => {
    // Convert the values to numbers before summing
    const metromidiUnit = parseInt(metromidiUnitCount, 10) || 0;
    const metrominiUnit = parseInt(metrominiUnitCount, 10) || 0;
    const flexiUnit = parseInt(flexiUnitCount, 10) || 0;
    const pppUnit = parseInt(pppUnitCount, 10) || 0;
    const nhiaUnit = parseInt(nhiaUnitCount, 10) || 0;
    const metromaxiUnit = parseInt(metromaxiUnitCount, 10) || 0;
  
    // Calculate the total sum
    const totalUnitCount =
      metromidiUnit + metrominiUnit + flexiUnit + pppUnit + nhiaUnit + metromaxiUnit;
  
    return totalUnitCount;
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateDto()){
      return;
    }

    showLoadingModal();
    let salesDto = {
      revenueAmount:calculateTotalRevenue(),
      metromidiUnitCount: metromidiUnitCount,
      metrominiUnitCount: metrominiUnitCount,
      flexiUnitCount: flexiUnitCount,
      pppUnitCount: pppUnitCount,
      nhiaUnitCount: nhiaUnitCount,
      metromaxiUnitCount: metromaxiUnitCount,
      metromidiValueAmount: metromidiValueAmount,
      metrominiValueAmount: metrominiValueAmount,
      flexiValueAmount: flexiValueAmount,
      pppValueAmount: pppValueAmount,
      nhiaValueAmount: nhiaValueAmount,
      metromaxiValueAmount: metromaxiValueAmount,
      nameOfClient: clientName,
      dateOfSale: dateOfSale,
      salesOfficerId: salesOfficerId,
      totalUnitCount:calculateTotalUnitCount(),
    };
    
    console.log(salesDto);

    AnalyticsService.editSalesData(salesDto,
     localStorage.getItem("token"),salesRecord.id
    )
      .then((res) => {
        if (res.status === 200) {
        
          Swal.fire("Sales Data has been updated", "", "success").then(
            (result) => {
              if (result.isConfirmed) {
                closeModal();
              }
            }
          );
        }

      })
      .catch((err) => {
        hideLoadingModal();
        console.log(err);
      });
    

  };

  return (
    <React.Fragment>
      <Sidebar />
      <HeaderMobile />
      <div className="main main-docs">
        <Modal show={lgShow} onHide={closeModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Add Sales Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card>
              <Card.Body>
                <div className="mb-3">
                  <Row>
                    {" "}
                    <Col sm="6">
                      <Form.Label>Name of Client/Customer</Form.Label>
                      <Form.Control
                        onChange={(event) => setClientName(event.target.value)}
                        type="text"
                        value={clientName}
                        id="formGroupExampleInput"
                        placeholder="   Name of Client/Customer"
                      />
                    </Col>
                 
                    <Col sm="6">
                      <Form.Label> Date of Sale</Form.Label>

                      <Form.Control
                        type="date" // Use "date" type instead of "datetime-local"
                        name="dateOfSale"
                        value={dateOfSale}
                        onChange={(event) => setDateOfSale(event.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="mb-3">
                  <Row>
                    {" "}
                    <Col sm="6">
                      <Form.Label> Unit Sales for PPP</Form.Label>
                      <Form.Control
                        onChange={(event) => setPPPUnitCount(event.target.value)}
                        type="text"
                        value={pppUnitCount}
                        id="formGroupExampleInput"
                        placeholder="  Unit Sales for PPP"
                      />
                    </Col>
                    <Col sm="6">
                      <Form.Label>  PPP Value (Naira) </Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setPPPValueAmount(event.target.value)
                        }
                        type="text"
                        value={pppValueAmount}
                        id="formGroupExampleInput"
                        placeholder="  Value for PPP"
                      />
                    </Col>
                  </Row>
                </div>
                <div className="mb-3">
                  <Row>
                    {" "}
                    <Col sm="6">
                      <Form.Label> Unit Sales for Metro Maxi</Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setMetromaxiUnitCount(event.target.value)
                        }
                        type="text"
                        value={metromaxiUnitCount}
                        id="formGroupExampleInput"
                        placeholder="  Unit Sales for Metro Midi"
                      />
                    </Col>
                    <Col sm="6">
                    <Form.Label> Metro Maxi Value (Naira) </Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setMetromaxiValueAmount(event.target.value)
                        }
                        type="text"
                        value={metromaxiValueAmount}
                        id="formGroupExampleInput"
                        placeholder="  Value for Metromaxi"
                      />
                    </Col>
                  </Row>
                </div>

                <div className="mb-3">
                  <Row>
                    {" "}
                    <Col sm="6">
                      <Form.Label> Unit Sales for Metro Midi</Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setMetromidiUnitCount(event.target.value)
                        }
                        type="text"
                        value={metromidiUnitCount}
                        id="formGroupExampleInput"
                        placeholder="  Unit Sales for Metro Midi"
                      />
                    </Col>
                    <Col sm="6">
                    <Form.Label> Metro Midi Value (Naira) </Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setMetromidiValueAmount(event.target.value)
                        }
                        type="text"
                        value={metromidiValueAmount}
                        id="formGroupExampleInput"
                        placeholder="  Value for Metromidi"
                      />
                    </Col>
                  </Row>
                </div>

                <div className="mb-3">
                  <Row>
                    {" "}
                    <Col sm="6">
                      <Form.Label> Unit Sales for Metro Mini</Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setMetrominiUnitCount(event.target.value)
                        }
                        type="text"
                        value={metrominiUnitCount}
                        id="formGroupExampleInput"
                        placeholder="  Unit Sales for Metro Mini"
                      />
                    </Col>
                    <Col sm="6">
                    <Form.Label> Metro Mini Value (Naira) </Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setMetrominiValueAmount(event.target.value)
                        }
                        type="text"
                        value={metrominiValueAmount}
                        id="formGroupExampleInput"
                        placeholder="  Value for Metromini"
                      />
                    </Col>
                  </Row>
                </div>


                <div className="mb-3">
                  <Row>
                    {" "}
                    <Col sm="6">
                      <Form.Label> Unit Sales for Flexi</Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setFlexiUnitCount(event.target.value)
                        }
                        type="text"
                        value={flexiUnitCount}
                        id="formGroupExampleInput"
                        placeholder="  Unit Sales for Flexi"
                      />
                    </Col>
                    <Col sm="6">
                    <Form.Label> Flexi Value (Naira) </Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setFlexiValueAmount(event.target.value)
                        }
                        type="text"
                        value={flexiValueAmount}
                        id="formGroupExampleInput"
                        placeholder="  Value for Flexi"
                      />
                    </Col>
                  </Row>
                </div>

                <div className="mb-3">
                  <Row>
                    {" "}
                    <Col sm="6">
                      <Form.Label> Unit Sales for NHIA</Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setNhiaUnitCount(event.target.value)
                        }
                        type="text"
                        value={nhiaUnitCount}
                        id="formGroupExampleInput"
                        placeholder="  Unit Sales for Metro Midi"
                      />
                    </Col>
                    <Col sm="6">
                    <Form.Label> NHIA Value (Naira) </Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          setNhiaValueAmount(event.target.value)
                        }
                        type="text"
                        value={nhiaValueAmount}
                        id="formGroupExampleInput"
                        placeholder="  Value for NHIA"
                      />
                    </Col>
                  </Row>
                </div>


                <div className="mb-3">
                  <Row className="justify-content-center">
                    <Col md="auto">
                      <Button variant="primary">Add New Sales Record</Button>
                    </Col>
                    <Col md="auto">
                      <Button
                        variant="success"
                        onClick={handleSubmit}
                        className="btn-sign"
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card.Body>

              <Card.Footer></Card.Footer>
              <Form onSubmit={handleSubmit} />
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
}
