import React, { useEffect, useState } from "react";
import { formatRevenue, } from "../../../logic/Utils";
import {  Card, Col, Row, Nav,ProgressBar } from "react-bootstrap";

import {  Doughnut } from 'react-chartjs-2';

export default function MetricsByPeriod(props) {

    const metrics = props.metrics;


  const optionBar = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 80,
        grid: {
          borderColor: '#e2e5ec',
          color: '#f3f5f9'
        },
        ticks: {
          font: {
            size: 11
          }
        }
      },
      y: {
        grid: {
          borderWidth: 0,
          color: '#f3f5f9'
        },
        ticks: {
          color: '#212830',
          font: {
            size: 14
          }
        }
      }
    }
  };

  // Events By Category
  const dataPie = {
    labels: ['Festivals', 'Sports', 'Concerts', 'Performing Arts'],
    datasets: [{
      data: [40, 25, 20, 15],
      backgroundColor: ['#506fd9', '#85b6ff', '#50586d', '#b9bdc9']
    }]
  };

  const optionPie = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      }
    },
    animation: {
      animateScale: true,
      animateRotate: true
    }
  };
  
  
  // Changing necessary options between dark and light skin
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  if (skin === 'dark') {
    optionBar.scales['x'].grid.color = '#222b41';
    optionBar.scales['x'].ticks.color = 'rgba(255,255,255,.65)';
    optionBar.scales['x'].grid.borderColor = '#222b41';
    optionBar.scales['y'].grid.color = '#222b41';
    optionBar.scales['y'].ticks.color = 'rgba(255,255,255,.65)';
  } else {
    optionBar.scales['x'].grid.color = '#edeff6';
    optionBar.scales['x'].ticks.color = '#42484e';
    optionBar.scales['x'].grid.borderColor = '#edeff6';
    optionBar.scales['y'].grid.color = '#edeff6';
    optionBar.scales['y'].ticks.color = '#42484e';
  }

  const switchSkin = (skin) => {
    const textPrimary = document.getElementsByClassName('text-primary-dark');

    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }

      for (const txt of textPrimary) {
        txt.classList.add('text-primary');
      }

    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }

      for (const txt of textPrimary) {
        txt.classList.remove('text-primary');
      }
      
    }
  }

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      
       
        <Row  style={{ marginTop: "15px" }}className="g-3">
       
       
          <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Daily Statistics</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-3">
               

                <Row className="g-4 mb-2">
                  
                    <Col xs="6">
                      <label className="fs-sm fw-medium mb-1">Yesterday's Sales (Value)</label>
                      <h3 className="card-value mb-2">
                       <small>{formatRevenue(metrics.totalRevenueGeneratedYesterday)}</small></h3>
                      <ProgressBar now={50} className="ht-5 mb-0" variant="ui-02" />
                    </Col>
                    <Col xs="6">
                      <label className="fs-sm fw-medium mb-1">Today's Sales (Value)</label>
                      <h3 className="card-value mb-2">
                       <small>{formatRevenue(metrics.totalRevenueGeneratedToday)}</small></h3>
                      <ProgressBar now={50} className="ht-5 mb-0" variant="ui-02" />
                    </Col>
                    <Col xs="6">
                      <label className="fs-sm fw-medium mb-1">Yesterday's Total Population </label>
                      <h3 className="card-value mb-2">
                       <small>{metrics.totalPopulationCountYesterday}</small></h3>
                      <ProgressBar now={50} className="ht-5 mb-0" variant="ui-02" />
                    </Col>
                    <Col xs="6">
                      <label className="fs-sm fw-medium mb-1">Today's Total Population</label>
                      <h3 className="card-value mb-2">
                       <small>{metrics.totalPopulationCountToday}</small></h3>
                      <ProgressBar now={50} className="ht-5 mb-0" variant="ui-02" />
                    </Col>
                  
                </Row>
                
              </Card.Body>
            </Card>
          </Col>
        
          <Col md="6" xl="4">
          <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Weekly Statistics</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-3">
               

                <Row className="g-4 mb-2">
                  
                    <Col xs="6">
                      <label className="fs-sm fw-medium mb-1">Last Week's Sales (Value)</label>
                      <h3 className="card-value mb-2">
                       <small>{formatRevenue(metrics.totalRevenueGeneratedLastWeek)}</small></h3>
                      <ProgressBar now={50} className="ht-5 mb-0" variant="ui-02" />
                    </Col>
                    <Col xs="6">
                      <label className="fs-sm fw-medium mb-1">This Week's Sales (Value)</label>
                      <h3 className="card-value mb-2">
                       <small>{formatRevenue(metrics.totalRevenueGeneratedThisWeek)}</small></h3>
                      <ProgressBar now={50} className="ht-5 mb-0" variant="ui-02" />
                    </Col>
                    <Col xs="6">
                      <label className="fs-sm fw-medium mb-1">Last Week's Total Population </label>
                      <h3 className="card-value mb-2">
                       <small>{metrics.totalPopulationCountLastWeek}</small></h3>
                      <ProgressBar now={50} className="ht-5 mb-0" variant="ui-02" />
                    </Col>
                    <Col xs="6">
                      <label className="fs-sm fw-medium mb-1">This Week's Total Population</label>
                      <h3 className="card-value mb-2">
                       <small>{metrics.totalPopulationCountThisWeek}</small></h3>
                      <ProgressBar now={50} className="ht-5 mb-0" variant="ui-02" />
                    </Col>
                  
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col md="6" xl="4">
          <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Monthly Statistics</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-3">
               

                <Row className="g-4 mb-2">
                  
                    <Col xs="6">
                      <label className="fs-sm fw-medium mb-1">Last Month's Sales (Value)</label>
                      <h3 className="card-value mb-2">
                       <small>{formatRevenue(metrics.totalRevenueGeneratedLastMonth)}</small></h3>
                      <ProgressBar now={50} className="ht-5 mb-0" variant="ui-02" />
                    </Col>
                    <Col xs="6">
                      <label className="fs-sm fw-medium mb-1">This Month's Sales (Value)</label>
                      <h3 className="card-value mb-2">
                       <small>{formatRevenue(metrics.totalRevenueGeneratedMonthToDate)}</small></h3>
                      <ProgressBar now={50} className="ht-5 mb-0" variant="ui-02" />
                    </Col>
                    <Col xs="6">
                      <label className="fs-sm fw-medium mb-1">Last Month's Total Population </label>
                      <h3 className="card-value mb-2">
                       <small>{metrics.totalPopulationCountLastMonth}</small></h3>
                      <ProgressBar now={50} className="ht-5 mb-0" variant="ui-02" />
                    </Col>
                    <Col xs="6">
                      <label className="fs-sm fw-medium mb-1">This Month's Total Population</label>
                      <h3 className="card-value mb-2">
                       <small>{metrics.totalPopulationCountMonthToDate}</small></h3>
                      <ProgressBar now={50} className="ht-5 mb-0" variant="ui-02" />
                    </Col>
                  
                </Row>
              </Card.Body>
            </Card>
          </Col>


    
        </Row>

       
     
    </React.Fragment>
  );
}