import React, { useEffect, useState} from "react";
import Prism from "prismjs";
import {Button, Card, Col, Form, Modal, Spinner} from "react-bootstrap";
import Footer from "../layouts/Footer";
import HeaderMobile from "../layouts/HeaderMobile";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import {TaskService} from "../service/TaskService";
import {TeamService} from "../service/TeamService";
import Sidebar from "../layouts/Sidebar";
import SuccessModal from "./SuccessModal";
import Projects from "../pages/WorkPlansForSelf";
import {ProjectService} from "../service/ProjectService";
import {ReportService} from "../service/ReportService";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';


// Import the font files
import BookAntiquaRegular from '../fonts/BookAntiqua-Regular.ttf';
import BookAntiquaBold from '../fonts/BookAntiqua-Bold.ttf';
import {useNavigate} from "react-router-dom";
import EncodedLogo from "../assets/img/EncodedLogo";



export default function GenerateReportForAnEmployee() {
    useEffect(() => {
        Prism.highlightAll();
        //authorize entry

    }, []);


    const [teamsData, setTeamsData] = React.useState([]);
    const navigate = useNavigate();
    const [lgShow, setLgShow] = useState(true);
    const [logSuccess, setLogSuccess] = React.useState(false)
    const bookAntiquaFontUrl = "https://fonts.cdnfonts.com/css/antiqua-2";
    const [isLoading, setIsLoading] = React.useState(false);

// Register the font with pdfMake
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    // pdfMake.fonts = {
    //     Roboto: {
    //         normal: 'Roboto-Regular.ttf',
    //         bold: 'Roboto-Medium.ttf',
    //     },
    // };


    useEffect(() => {


        TeamService.getTeamInfoByManager(localStorage.getItem("token"))
            .then((res) => {
                setTeamsData(res.data.result.memberProfiles);
            })
            .catch((err) => {
                console.log(err);

            });


    }, [setTeamsData]);
    const lgHandleClose = () => {

        setIsLoading(false);

        // setTimeout(() => {
            navigate("/dashboard/reports", { replace: true });
        // }, 1000); // Navigate to "/apps/tasks" upon modal closure
        setLgShow(false);

    };


    const selectOptions = teamsData.map((member, index) => ({
        value: member.users_id,
        label: member.firstName + " " + member.lastName,
    }));

//task varaibles

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [employeeId, setEmployeeId] = useState("");
    const [report, setReport] = useState(null);


    function generatePDFContent(data,logoDataURL) {
        const content = [];

        // Add company logo (placeholder for the image)
        content.push({image: logoDataURL, width: 100,alignment: 'center', margin: [0, 0, 0, 20]});

        // Employee Information
        content.push({text: `Employee Name: ${data.employeeName}`, style: 'header'});
        content.push(`Department: ${data.department}`);
        content.push(`Designation: ${data.jobTitle}`);
        content.push(`Email: ${data.email}`);
        // content.push(`Teams: ${data.teams}`);
        content.push({text: `Task Statistics:`, style: 'subheader'});
        content.push(`Report Start Date: ${data.startDate}`);
        content.push(`Report End Date: ${data.endDate}`);
        content.push(`Total Tasks Completed: ${data.completedTasks}`);
        content.push(`Total Tasks Ongoing: ${data.ongoingTasks}`);
        content.push(`Total Tasks Overdue: ${data.overdueTasks}`);
        content.push(`Average Rating: ${data.averageRating}`);

        // Add other employee details here

        // Create a table for task details
        const tableBody = [];
        data.assignedTasksDetails.forEach((task) => {
            tableBody.push([task.taskType, task.taskDescription, task.projectName, task.name, task.creator, task.dueDate, task.status]);
        });

        const table = {
            style: 'tableExample',
            table: {
                widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                body: [
                    ['Task Type', 'Task Description', 'Project Name', 'Task Name', 'Creator', 'Due Date', 'Status'],
                    ...tableBody,
                ],
            },
            // layout: 'lightHorizontalLines',
        };

        content.push(table);
        return content;
    }

        const handleSubmit = (e) => {
            e.preventDefault();

            setIsLoading(true);

            // post task

            const reportRequest = {
                employeeId,
                startDate,
                endDate,
            };


            ReportService.getReportForAnEmployeeByRange(localStorage.getItem("token"), reportRequest)
                .then((response) => {
                    // Handle successful task creation
                    if (response.status === 200) {
                        setIsLoading(false);

                        setReport(response.data.result)
                        // generatePDFContent(report)
                        // lgHandleClose()

                        setLogSuccess(true);

                        // setTimeout(() => {
                        //     navigate("/apps/assigned-tasks", { replace: true });
                        // }, 5000); //
                    }
                })
                .catch((error) =>{ console.log(error)
            setIsLoading(false);}
                );
            // Reset the form
            // setTaskData({
            //     label: '',
            //     description: ''
            // });
            // Close the modal

        };

        function printReport() {
            // Function to generate the PDF content
            // ...
            const logoDataURL = EncodedLogo;
            const fileName = `TASK_REPORT_FOR_${report.employeeName.replace(/\s+/g, '_')}.pdf`;
            const documentDefinition = {
                content: generatePDFContent(report,logoDataURL),
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 10],
                        // font: 'Roboto-Medium.ttf', // Use the registered font
                    },
                    subheader: {
                        fontSize: 14,
                        bold: false,
                        margin: [0, 10, 0, 5],
                        // font: 'Roboto-Regular.ttf',
                    },
                    tableExample: {
                        margin: [0, 10, 0, 10]
                    },
                },
            };

            // Generate the PDF
            pdfMake.createPdf(documentDefinition).download(fileName);
        }

        useEffect(() => {
            // Call the printReport function when the report state updates
            if (report) {
                console.log("report generated")
                printReport();
                // lgHandleClose();
                // setLogSuccess(true);
            }
        }, [report]);


        return (
            <React.Fragment>
                <Sidebar/>
                <HeaderMobile/>
                <div className="main main-docs">
                    <Modal show={lgShow} onHide={lgHandleClose} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>Run a Report</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <Card>
                                <Card.Body>
                                    <div className="mb-3">
                                        <Col sm="6">
                                            <Form.Label htmlFor="formGroupExampleInput2">Start Date</Form.Label>
                                            <ReactDatePicker
                                                showTimeSelect
                                                selected={startDate}
                                                onChange={(startDate) => setStartDate(startDate)}
                                                className="form-control"/>
                                        </Col>
                                        <Col sm="6">
                                            <Form.Label htmlFor="formGroupExampleInput2">End Date</Form.Label>
                                            <ReactDatePicker
                                                showTimeSelect
                                                selected={endDate}
                                                onChange={(endDate) => setEndDate(endDate)}
                                                className="form-control"/>
                                        </Col>
                                    </div>
                                    <div className="mb-3">
                                        <Form.Label htmlFor="formGroupExampleInput2">Report For Who?</Form.Label>
                                        {/* Conditional rendering for the Select component */}
                                        {selectOptions.length > 0 ? (
                                            <Select
                                                onChange={(e) => {
                                                    if (e && e.value) {
                                                        setEmployeeId(e.value);
                                                    }
                                                }}
                                                options={selectOptions} isSearchable={true}
                                                placeholder={"Search Member Names"}/>
                                        ) : (
                                            <p>Loading Team Members...</p>
                                        )}
                                    </div>
                                </Card.Body>

                                <Card.Footer>

                                </Card.Footer>
                                <Form onSubmit={handleSubmit}/>
                            </Card>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={lgHandleClose}>
                                Close
                            </Button>
                            {!isLoading && ( <Button variant="primary" onClick={handleSubmit}>
                                Create Report
                            </Button>)}
                            {isLoading && (
                                <Button  className="btn-sign">
                                    Processing...<span/><Spinner  size="sm" animation="border" /></Button>
                            )}
                        </Modal.Footer>
                    </Modal>
                    {logSuccess && (
                        <SuccessModal message={"Report creation was successful. A download will start shortly."} destination={"dashboard/reports"}/>
                    )}

                </div>

            </React.Fragment>
        );

}