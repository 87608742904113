import React, {useContext, useEffect} from "react";
import { Link } from "react-router-dom";
import { Card, Col, Nav, Row, Form, Button } from "react-bootstrap";
import Footer from "../layouts/Footer";
import Avatar from "../components/Avatar";

import img6 from "../assets/img/img6.jpg";

import Header from "../layouts/Header";
import TaskContext from "../context/TaskContext";
import {TeamService} from "../service/TeamService";
import Sidebar from "../layouts/Sidebar";


export default function ManagerTeams() {

    const { updateTeamId, teamId } = useContext(TaskContext);
    const [teamsData, setTeamsData] = React.useState([]);
    const [teamName, setTeamName] = React.useState("Team Name");


    useEffect(() => {


        TeamService.getTeamInfoByManager(localStorage.getItem("token"))
            .then((res) => {
                setTeamsData(res.data.result.memberProfiles);

                setTeamName(res.data.result.name)
            })
            .catch((err) => {
                console.log(err);

            });



    }, [ setTeamsData,updateTeamId, teamId]);


    // function changeTeam(id) {
    //     //
    //     console.log(id);
    //     updateTeamId(id);
    // }

    return (
        <React.Fragment>
            <Sidebar />
            <Header />
            <div className="main main-app p-3 p-lg-4 p-xxl-5">
                <Row className="g-5">
                    <Col xl>
                        <ol className="breadcrumb fs-sm mb-2">
                            <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
                            <li className="breadcrumb-item"><Link to="#">Teams</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">My Team</li>
                        </ol>
                        <h2 className="main-title">Teams</h2>

                        <Nav className="nav-line mb-4">
                            <Nav.Link href="" className="active">My Team</Nav.Link>
                        </Nav>

                        <div className="main-label-group mb-3">
                            <label>{teamName}</label>
                            <Link to="">See All</Link>
                        </div>

                        <Row className="g-2 g-xxl-3 mb-5">
                            {teamsData.length > 0 ? ( // Check if teamsData has data before rendering
                                <Row className="g-2 g-xxl-3 mb-5">
                                    {teamsData.map((member, index) => (
                                        <Col sm="6" md="4" key={index}>
                                            <Card className="card-people">
                                                <Card.Body>
                                                    <Link to=""><Avatar img={img6} size="xl" status="online" /></Link>
                                                    <h6 className="mt-3"><Link to="">{member.firstName + " " + member.lastName}</Link></h6>
                                                    <p>{member.jobTitle}</p>
                                                    <div className="d-grid"><Button variant="outline-primary">View Profile</Button></div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            ) : (
                                <p>Loading team members...</p>
                            )}
                            {/* End of conditional rendering */}

                        </Row>



                    </Col>
                    <Col xl="4" xxl="3" className="d-none d-xl-block">
                        <h5 className="section-title">Find Team Members</h5>

                        <div className="form-search">
                            <i className="ri-search-line"></i>
                            <Form.Control type="text" placeholder="Enter name or email" />
                        </div>

                        <hr className="my-4 opacity-0" />
                    </Col>
                </Row>

                <Footer />
            </div>
        </React.Fragment>
    );
}
