import React, {useEffect, useState} from "react";
import { Button, Card,Form } from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {RegistrationService} from "../service/RegistrationService";

import PasswordFailure from "../components/PasswordFailure";

import PasswordSuccess from "../components/PasswordSuccess";


export default function CreatePassword() {
    const [verificationToken, setVerificationToken] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const navigate = useNavigate();

    const [logError, setLogError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState("Something went wrong");

    const [logSuccess, setLogSuccess] = React.useState(false);

    useEffect(() => {
        // Define a regular expression to match the 'token' parameter in the URL
        const tokenRegex = /token=([^&]+)/;

        // Get the current URL from the browser
        const currentUrl = window.location.href;

        // Use the regular expression to extract the token from the URL
        const tokenMatch = currentUrl.match(tokenRegex);

        // Check if a token was found and extract it from the match
        const token = tokenMatch ? tokenMatch[1] : null;

        // Do something with the token (e.g., assign it to a variable)
        if (token) {
         setVerificationToken(token)
        }
    }, []);



    const passwordDto = {
        newPassword,
        confirmPassword,
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        // post dto

        RegistrationService.createPassword(passwordDto, verificationToken)
            .then((response) => {
                // Handle successful task creation
                if (response.status === 201) {
                    setLogSuccess(true)
                }


            })
            .catch((error) => {
                    setLogError(true)
                    setErrorMessage(error.response.data.result)
                    console.log(error);
                }
            );

    };


    return (
        <div className="page-sign">
            <Card className="card-sign">
                <Card.Header>
                    <Link to="/" className="header-logo mb-4">MetroHealth</Link>
                    <Card.Title>Create your Password</Card.Title>
                    <Card.Text>Welcome! <br/> Please create your login credentials to continue.</Card.Text>
                </Card.Header>
                <Card.Body>
                    <Form method="get" action="">
                        <div className="mb-4">
                            <Form.Label className="d-flex justify-content-between">New Password </Form.Label>
                            <Form.Control onChange={(event) => setNewPassword(event.target.value)} type="password" placeholder="Enter your password"  />
                        </div>
                        <div className="mb-4">
                            <Form.Label className="d-flex justify-content-between">Confirm Password </Form.Label>
                            <Form.Control onChange={(event) => setConfirmPassword(event.target.value)} type="password" placeholder="Enter your password"/>
                        </div>
                        <Button onClick={handleSubmit} type="submit" variant="primary" className="btn-sign">Create Password</Button>


                    </Form>

                </Card.Body>
                <Card.Footer>
                    
                    Is your token expired? <Link to="/forgot-password">Resend Reset Link</Link>
                </Card.Footer>
            </Card>
            {logError && (
                <PasswordFailure message={errorMessage}/>
            )}

            {logSuccess && (
                <PasswordSuccess message={"Password was successfully set"}/>
            )}

        </div>
    )
}