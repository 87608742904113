import React, {useContext, useEffect, useState} from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import {Badge, Card, Col, Nav, ProgressBar, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import SingleTask from "../../components/SingleTask";
import TaskContext from "../../context/TaskContext";
import {TaskService} from "../../service/TaskService";
import Sidebar from "../../layouts/Sidebar";
import TaskListing from "../../apps/TaskListing";
import Lottie from "lottie-react";
import animationData from "../../assets/animation/animation_lmawejkm.json";



export default function ExtensionTasks() {
    useEffect(() => {
        document.body.classList.add('app-task')
        return () => {
            document.body.classList.remove('app-task')
        }
    }, []);
    const [loading, setLoading] = useState(true);
    const [extensionTasks, setExtensionTasks] = React.useState([]);
    const {selectedTask, updateTask} = useContext(TaskContext);
    const {updateModalState} = useContext(TaskContext);

    function handleTaskClick(task) {

        // pass the task to the context
        updateTask(task);
        //open modal
        updateModalState(true);
    }

    useEffect(() => {

        TaskService.getAllExtensionRequestedTasks(localStorage.getItem("token"))
            .then((res) => {
                setExtensionTasks(res.data.result);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });



    }, [ setExtensionTasks]);

    const highPriorityTasks = extensionTasks.filter((task) => task.priority === "HIGH");
    const mediumPriorityTasks = extensionTasks.filter((task) => task.priority === "MEDIUM");
    const lowPriorityTasks = extensionTasks.filter((task) => task.priority === "LOW" || task.priority === "");



    return (
        <React.Fragment>
            <Sidebar />
            <Header />
            <div className="main main-app p-3 p-lg-4 p-xxl-5">
                <Row className="row-cols-auto g-4 g-xxl-5">
                    <Col xl>
                        <h2 className="main-title fs-4 mb-1">Welcome to your Task Manager!</h2>
                        <p className="text-secondary mb-4">Streamline your Workflow with Ease</p>

                        <Nav className="nav-line mb-4">
                            <Nav.Link href="" className="nav-link active" >
                                Tasks Marked For Date Extension
                            </Nav.Link>
                        </Nav>
                        {loading && (
              <div className={`fullscreen-loader ${loading ? "show" : "hide"}`}>
                <Lottie
                  animationData={animationData}
                  loop={true}
                  autoPlay={true}
                />
              </div>
            )}
            {!loading && (
                        <Row className="g-3 g-lg-4">

                            <Col md>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <label className="task-label">High Priority</label>
                                    <div className="dropdown dropdown-task">
                                        <Link to="" className="dropdown-link">Date Added <i
                                            className="ri-arrow-down-s-line"></i></Link>
                                    </div>
                                </div>
                                {/* first 5 tasks*/}
                                <TaskListing taskList={highPriorityTasks} taskClickFunction={handleTaskClick} />
                            </Col>
                            <Col md>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <label className="task-label">Medium Priority</label>
                                    <div className="dropdown dropdown-task">
                                        <Link to="" className="dropdown-link">Date Added <i
                                            className="ri-arrow-down-s-line"></i></Link>
                                    </div>
                                </div>

                                {/*nest 5*/}
                                <TaskListing taskList={mediumPriorityTasks} taskClickFunction={handleTaskClick} />


                            </Col>
                            <Col md className="mt-5 mt-md-3 mt-lg-4">
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <label className="task-label"> Low Priority</label>
                                    <div className="dropdown dropdown-task">
                                        <Link to="" className="dropdown-link">Date Added <i
                                            className="ri-arrow-down-s-line"></i></Link>
                                    </div>
                                </div>

                                {/*next 5*/}
                                <TaskListing taskList={lowPriorityTasks} taskClickFunction={handleTaskClick} />
                            </Col>
                        </Row>)}
                        {selectedTask && <SingleTask/>}

                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );

}