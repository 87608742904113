import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import HeaderMobile from "../layouts/HeaderMobile";
import Select from "react-select";
import { TaskService } from "../service/TaskService";
import { TeamService } from "../service/TeamService";
import Sidebar from "../layouts/Sidebar";
import SuccessModal from "./SuccessModal";
import { ProjectService } from "../service/ProjectService";
import Swal from "sweetalert2";
import FailureModal from "./FailureModal";
import { useNavigate } from "react-router-dom";
import TaskContext from "../context/TaskContext";
import { EmployeeService } from "../service/EmployeeService";

export default function CreateTask() {
  useEffect(() => {
    Prism.highlightAll();
    //authorize entry
  }, []);

  function getDefaultDateAndTime() {
    const today = new Date();
    const currentHour = today.getHours();
  
    // Check if it's a weekend (Saturday or Sunday)
    const isWeekend = today.getDay() === 0 || today.getDay() === 6;
  
    // Set the default time to 16:00
    let defaultHour = 16;
  
    // If it's a weekday and the current time is after 16:00, set the date to the next day
    if (!isWeekend && currentHour >= 16) {
      today.setDate(today.getDate() + 1);
    }
  
    // If it's a weekend, set the date to the next Monday
    if (isWeekend) {
      const daysUntilMonday = 1 + ((1 + 7 - today.getDay()) % 7);
      today.setDate(today.getDate() + daysUntilMonday);
    }
  
    today.setHours(defaultHour, 0, 0, 0);
  
    const year = today.getFullYear();
    const month = `${today.getMonth() + 1}`.padStart(2, "0");
    const day = `${today.getDate()}`.padStart(2, "0");
    const hours = `${today.getHours()}`.padStart(2, "0");
    const minutes = `${today.getMinutes()}`.padStart(2, "0");
  
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  
  const [teamsData, setTeamsData] = React.useState([]);
  const [projectsData, setProjects] = React.useState([]);
  const [lgShow, setLgShow] = useState(true);
  const [logSuccess, setLogSuccess] = React.useState(false);
  const [kpiId, setKpiData] = useState("");
  const { modalState, updateModalState } = useContext(TaskContext);
  const[showKpi,setShowKpi]= useState (false);
  const [kpiOptions, setKpiOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    TeamService.getTeamInfoByManager(localStorage.getItem("token"))
      .then((res) => {
        setTeamsData(res.data.result.memberProfiles);
      })
      .catch((err) => {
        console.log(err);
      });

    ProjectService.getAllProjectsOfManager(localStorage.getItem("token"))
      .then((res) => {
        setProjects(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [setTeamsData, setProjects]);

  const handleItemClick = (options) => {
    if (options && options.length > 0) {
      const option = options[0];
  
      if (option && option.users_id !== undefined) {
        setShowKpi(false);
  
        EmployeeService.getKpiFOrUser(localStorage.getItem("token"),option.users_id)
          .then((res) => {
            const selectOptions = res.data.result.map((kpi, index) => ({
              value: kpi.id,
              label: kpi.description,
            }));
  
            setKpiOptions(selectOptions);
            setShowKpi(true);
          })
          .catch((err) => {
            console.log(err);
          });
      } 
    } 
  };
  

  const lgHandleClose = () => {
    setTimeout(() => {
      navigate("/tasks/team/new", { replace: true });
    }, 1000); // Navigate to "/apps/tasks" upon modal closure
    setLgShow(false);
  };

  const selectOptions = teamsData.map((member, index) => ({
    value: member.email,
    label: member.firstName + " " + member.lastName,
    users_id:member.users_id
  }));

  const projectOptions = Array.isArray(projectsData)
    ? projectsData.map((member, index) => ({
        value: member.id,
        label: member.name,
      }))
    : [];

  //task varaibles

  const [projectId, setProjectId] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [dueDate, setDueDate] = useState(getDefaultDateAndTime());
  
  const [name, setName] = useState("");
  const [priority, setPriority] = useState("");
  const [assigneesEmails, setAssigneesEmails] = useState([]);
  const [logError, setLogError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isMultiple, setIsMultiple] = useState(false);
  const taskDto = {
    projectId,
    taskDescription,
    dueDate,
    priority,
    assigneesEmails,
    isMultiple,
    name,
    kpiId
  };
  const priorityOptions = [
    { value: "HIGH", label: "HIGH" },
    { value: "MEDIUM", label: "MEDIUM" },
    { value: "LOW", label: "LOW" },
  ];
  const currentDate = new Date();
  const validateTaskDto = (taskDto) => {
    const errorMessages = [];

    if (assigneesEmails.length === 0){
      errorMessages.push("A team member must be Selected.");
    }

    if (!name) {
      errorMessages.push("Title is required.");
    }

    if (!taskDescription) {
      errorMessages.push("Task Description is required.");
    }



    if (!dueDate) {
      errorMessages.push("Due Date is required.");
    }

    // if (!kpiId) {
    //   errorMessages.push("Associated KPI is required.");
    // }

    // Display error messages if any
    if (errorMessages.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: errorMessages.join("\n"),
      });

      return false; // Validation failed
    }

    return true; // Validation passed
  };




  const changeParentModlal = () => {
    setLogError(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateTaskDto()){
      return;
    }
    setIsLoading(true);

    // Perform the task creation logic here


    // if(!isMultiple){
    TaskService.createTask(taskDto, localStorage.getItem("token"))
      .then((response) => {
        // Handle successful task creation
        if (response.status === 201) {
          lgHandleClose();
          setLogSuccess(false);

          setLogSuccess(true);

      
        }
      })
      .catch((error) => {
        console.log("error modal show");
        setLogError(true);
        setLogSuccess(false);
        setErrorMessage(error.response.data.result);
        setIsLoading(false);
        updateModalState(true);
        console.log(error);
      });
  
  };

  return (
    <React.Fragment>
      <Sidebar />
      <HeaderMobile />
      <div className="main main-docs">
        <Modal show={lgShow} onHide={lgHandleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Create a Task</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card>
              <Card.Body>
                <div className="mb-3">
                  <Form.Label htmlFor="formGroupExampleInput">
                    Task Title
                  </Form.Label>
                  <Form.Control
                    onChange={(event) => setName(event.target.value)}
                    type="text"
                    id="formGroupExampleInput"
                    placeholder="Task Title "
                  />
                </div>
                <div className="mb-3">
                  <Form.Label htmlFor="formGroupExampleInput2">
                    Task Description
                  </Form.Label>
                  <Form.Control
                    onChange={(event) => setTaskDescription(event.target.value)}
                    as="textarea"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    placeholder="Enter message here..."
                  ></Form.Control>
                </div>
                <div className="mb-3">
                <Row>
                    {" "}
                  <Col sm="6">
                    <Form.Label htmlFor="formGroupExampleInput2">
                      Due Date
                    </Form.Label>
                    
                              <Form.Control
                                type="datetime-local"
                                name="dueDate"
                                value={dueDate}
                                min={currentDate}
                                onChange={(dueDate) => setDueDate(dueDate.target.value)}
    
                              />
                  </Col>
                  <Col sm="6">
                    <Form.Label htmlFor="formGroupExampleInput2">
                      Priority
                    </Form.Label>
                    <Select
                      onChange={(e) => {
                        if (e && e.value) {
                          setPriority(e.value);
                        }
                      }}
                      options={priorityOptions}
                      isSearchable={true}
                    />
                  </Col>

                  </Row>
                </div>
              

                <div className="mb-3">
                  <Form.Label htmlFor="formGroupExampleInput2">
                    Assign to Who?
                  </Form.Label>
                  {/* Conditional rendering for the Select component */}
                  {selectOptions.length > 0 ? (
                    <Select
                      placeholder={
                        "You can assign to multiple people(Each will receive a copy of this task)"
                      }
                      onChange={(selectedOptions) => {
                        // Extract the array of selected values from the selectedOptions object
                        const selectedValues = selectedOptions.map(
                          (option) => option.value
                
                        );
                        setAssigneesEmails(selectedValues);
                        handleItemClick(selectedOptions);
                        
                      }}
                      options={selectOptions}
                      isMulti={true}
                      isSearchable={true}
                    />
                  ) : (
                    <p>Loading Team Members...</p>
                  )}
                </div>

                <div className="mb-3">
                    <Form.Label htmlFor="formGroupExampleInput2">
                      Associated KPI
                    </Form.Label>
                    {showKpi  ? (
                      <Select
                      onChange={(kpiOption) => {
                        if (kpiOption && kpiOption.value) {
                          setKpiData(kpiOption.value);
                        }
                      }}
                      options={kpiOptions}
                      isSearchable={true}
                      required
                    />
                  ) : (
                    <p>Loading KPIs...</p>
                  )}
                    
                  </div>
           
              
      

                <div className="mb-3">
                  <Form.Label htmlFor="formGroupExampleInput2">
                    Associated Project(Optional)
                  </Form.Label>
                  {projectOptions !== null ? (
                    <Select
                      onChange={(e) => {
                        if (e && e.value) {
                          setProjectId(e.value);
                        }
                      }}
                      options={projectOptions}
                      isSearchable={true}
                      placeholder={"Search Projects"}
                    />
                  ) : (
                    <p>Loading Projects...</p>
                  )}
                </div>
              </Card.Body>

              <Card.Footer></Card.Footer>
              <Form onSubmit={handleSubmit} />
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={lgHandleClose}>
              Close
            </Button>
            {!isLoading && (
              <Button variant="primary" onClick={handleSubmit}>
                Create Task
              </Button>
            )}
            {isLoading && (
              <Button className="btn-sign">
                Processing...
                <span />
                <Spinner size="sm" animation="border" />
              </Button>
            )}
          </Modal.Footer>
        </Modal>
        {logSuccess && (
          <SuccessModal
            message={"Task creation was successful"}
            destination={"/tasks/ongoing"}
          />
        )}
        {logError && (
          <FailureModal message={errorMessage} action={changeParentModlal} />
        )}
      </div>
    </React.Fragment>
  );
}
