
import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Link, useNavigate } from "react-router-dom";
import HeaderMobile from "../layouts/HeaderMobile";
import {
  Badge,
  Button,
  Card,
  Form,
  Modal,
  Nav,
  ProgressBar,
  Spinner,
} from "react-bootstrap";
import TaskContext from "../context/TaskContext";
import { TaskPostService } from "../service/TaskPostService";

import Sidebar from "../layouts/Sidebar";
import SuccessModal from "./SuccessModal";
import FailureModal from "./FailureModal";
import Select from "react-select";
import Swal from "sweetalert2";
import ReactDOMServer from "react-dom/server";

export default function SingleTask(props) {
  const isNewSection = props.isNewSection;
  function getDefaultDateAndTime() {
    const today = new Date();
    const currentHour = today.getHours();
  
    // Check if it's a weekend (Saturday or Sunday)
    const isWeekend = today.getDay() === 0 || today.getDay() === 6;
  
    // Set the default time to 16:00
    let defaultHour = 16;
  
    // If it's a weekday and the current time is after 16:00, set the date to the next day
    if (!isWeekend && currentHour >= 16) {
      today.setDate(today.getDate() + 1);
    }
  
    // If it's a weekend, set the date to the next Monday
    if (isWeekend) {
      const daysUntilMonday = 1 + ((1 + 7 - today.getDay()) % 7);
      today.setDate(today.getDate() + daysUntilMonday);
    }
  
    today.setHours(defaultHour, 0, 0, 0);
  
    const year = today.getFullYear();
    const month = `${today.getMonth() + 1}`.padStart(2, "0");
    const day = `${today.getDate()}`.padStart(2, "0");
    const hours = `${today.getHours()}`.padStart(2, "0");
    const minutes = `${today.getMinutes()}`.padStart(2, "0");
  
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const { selectedTask, modalState, updateModalState } =
    useContext(TaskContext);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userRole = userDetails ? userDetails.role : "";
  const allowedRoles = ["MANAGER", "HR"];
  const shouldRenderLineManagerSection = allowedRoles.includes(userRole);
  const [newDueDate, setNewDueDate] = useState(getDefaultDateAndTime());
  const [isNewDate, setIsNewDate] = useState(false);
  const [logSuccess, setLogSuccess] = React.useState(false);
  const [logError, setLogError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showRateForm, setShowRateForm] = React.useState(false);
  const [comment, setComment] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const showLoadingModal = () => {
    // Convert the React component to an HTML string
    const spinnerHtml = ReactDOMServer.renderToString(
      <Spinner animation="border" />
    );

    // Open Swal modal with the Bootstrap Spinner component
    Swal.fire({
      title: "Processing",
      html: `
                <div class="text-center">
                  ${spinnerHtml}
                </div>
              `,
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
  };

  // Function to hide the loading modal
  const hideLoadingModal = () => {
    // Close the Swal modal
    Swal.close();
  };
  const currentDate = new Date();
  const navigate = useNavigate();

  const successAction = () => {
    Swal.fire({
      title: "Action successful",
      text: "",
      icon: "success",
    }).then((result) => {
      // Check if the user clicked "OK"
      if (result.isConfirmed) {
        // Call your function here
        lgHandleClose();
      }
    });
  };

  ///rating

  const [timeliness, setTimeliness] = useState("");
  const [efficiency, setEfficiency] = useState("");
  const [communication, setCommunication] = useState("");
  const [accuracy, setAccuracy] = useState("");

  const ratingOptions = [
    { value: "EXCELLENT", label: "EXCELLENT" },
    { value: "VERY_GOOD", label: "VERY GOOD" },
    { value: "GOOD", label: "GOOD" },
    { value: "AVERAGE", label: "AVERAGE" },
    { value: "POOR", label: "POOR" },
    // Add more departments from the provided list
  ];

  const ratingDto = {
    efficiency,
    timeliness,
    accuracy,
    communication,
  };

  const lgHandleClose = () => {
    // setLgShow(false);
    updateModalState(false);
    setIsNewDate(false);
    hideLoadingModal();

    setShowRateForm(false);
  };

  const changeParentModlal = () => {
    setLogError(false);
    hideLoadingModal();
    setShowRateForm(false);
  };
  const task = selectedTask;

  const startTask = () => {
    showLoadingModal();

    TaskPostService.startTask(task.taskId, localStorage.getItem("token"))
      .then((response) => {
        if (response.status === 200) {
          hideLoadingModal();
        successAction();
          
        }
      })
      .catch((error) => {
        hideLoadingModal();

        Swal.fire("Error", error.response.data, "error");
        console.log(error);
      });
  };
  const submitTask = () => {
    showLoadingModal();
    TaskPostService.submitTask(task.taskId, localStorage.getItem("token"))
      .then((response) => {
        if (response.status === 200) {
        
          hideLoadingModal();
          successAction();
         
        }
      })
      .catch((error) => {
        hideLoadingModal();

        Swal.fire("Error", error.response.data, "error");
        console.log(error);
      });
  };

  const requestExtension = () => {
    showLoadingModal();
    TaskPostService.requestExtension(task.taskId, localStorage.getItem("token"))
      .then((res) => {
        if (res.status === 200) {
          hideLoadingModal();
          successAction();
        }
      })
      .catch((error) => {
        hideLoadingModal();

        Swal.fire("Error", error.response.data, "error");
        Swal.fire("Error", error.response.data, "error");
        console.log(error);
      });
  };

  const handleCommentAction = () => {

    if (comment.trim() === "") {
        Swal.fire("Error", "Comment cannot be empty", "error");
        return; 
      }
   showLoadingModal();
   const commentDto = {
    comment: comment
   }
    TaskPostService.addComment(task.taskId, commentDto,  localStorage.getItem("token"))
      .then((response) => {

        if (response.status === 201) {
            hideLoadingModal();
            Swal.fire("Comment Added", "Your comment has been logged", "success");
            setComment("");
        }
      })
      .catch((error) => {
        hideLoadingModal();
        Swal.fire("Error", error.response.data, "error");
        console.log(error);
      });
  };
  const approveTask = () => {
    showLoadingModal();

    if(task.status !== "PENDING_APPROVAL"){
      Swal.fire("Stop!", "Task is still ongoing and is yet to be submitted for approval", "error");
    }

    TaskPostService.approveTask(task.taskId, localStorage.getItem("token"))
      .then((response) => {
        if (response.status === 200) {
          hideLoadingModal();
          setShowRateForm(true);
          // lgHandleClose();
        }
      })
      .catch((error) => {
        Swal.fire("Error", error.response.data, "error");
        console.log(error);
      });
  };

  const openNewDate = () => {
    setIsNewDate(true);
  };

  const viewActivity = () => {
    updateModalState(false);
    navigate("tasks?task=" + task.taskId, { replace: true });
  };
  const approveExtension = () => {
    showLoadingModal();
    console.log(newDueDate);
    TaskPostService.approveExtension(
     
      task.taskId,
      newDueDate,
      localStorage.getItem("token")
    )
      .then((response) => {
        if (response.status === 200) {
          hideLoadingModal();
          successAction();
        }
      })
      .catch((error) => {
        hideLoadingModal();

        Swal.fire("Error", error.response.data, "error");
        console.log(error);
      });
  };

  const cancelTask = () => {
    showLoadingModal();

    TaskPostService.cancelTask(task.taskId, localStorage.getItem("token"))
      .then((response) => {
        hideLoadingModal();
        if (response.status === 200) {
        
          successAction();
        }
      })
      .catch((error) => {
        hideLoadingModal();
        Swal.fire("Error", error.response.data, "error");
        console.log(error);
      });
  };

  const rateTask = () => {
    if (!showRateForm) {
      setShowRateForm(true);
      return;
    }

    showLoadingModal();

    TaskPostService.rateTask(
      task.taskId,
      localStorage.getItem("token"),
      ratingDto
    )
      .then((response) => {
        hideLoadingModal();
        if (response.status === 200) {
          successAction();
        }
      })
      .catch((error) => {
        hideLoadingModal();
        Swal.fire("Error", error.response.data, "error");
        console.log(error);
      });
  };
  return (
    <React.Fragment>
      <Sidebar />
      <HeaderMobile />
      <div className="main main-docs">

      {
        task && 
        <Modal show={modalState} onHide={lgHandleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Task Overview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card className="card-task" key={task.taskId}>
              <Card.Body className="p-3 pb-1">
                {/* Render task data */}
                <div className="d-flex flex-row-reverse align-items-center justify-content-between mt-2 mb-1">
                  {/*  {task.taskType}  */}
                  {/*{task.badge.map((badge, ind) => (*/}
                  <Badge
                    key="0"
                    bg={
                      task.priority === "HIGH"
                        ? "danger"
                        : task.priority === "MEDIUM"
                        ? "warning"
                        : "secondary"
                    }
                  >
                    {task.priority === "HIGH"
                      ? "High Priority"
                      : task.priority === "MEDIUM"
                      ? "Medium Priority"
                      : "Low Priority"}
                  </Badge>
                  <Badge key="0" bg="success">
                    {task.taskType === "PROJECT"
                      ? task.projectName.toLocaleString().toUpperCase()
                      : "ROUTINE"}
                  </Badge>

                  {/*))}*/}
                </div>
                <ProgressBar
                  style={{ marginTop: "18px" }}
                  now={task.progress}
                  className="mb-2"
                />
                <div className="d-flex flex-row-reverse align-items-center justify-content-between mt-2 mb-1">
                  <span className="card-date">
                    {task.rated === false
                      ? "NOT YET RATED"
                      : "Rating: " + task.rating}
                  </span>
                  <Card.Title as="h6">{task.name}</Card.Title>
                </div>
                <p className="fs-xs">
                  Assigned to: <Link to="">{task.assignees}</Link>
                </p>
                {task.taskDescription && (
                  <p className="fs-sm">{task.taskDescription}</p>
                )}

                <div className="d-flex align-items-center justify-content-between fs-xs text-secondary mb-1">
                  {/*<span>*/}
                  <span>
                    <Link to="">{"Time Created:  " + task.createdTime}</Link>
                    {}
                  </span>
                  <span>
                    <Link to="">{"Deadline:  " + task.dueDate}</Link>
                    {}
                  </span>
                  {/*</span>*/}
                  {/*<span>{task.hour}</span>*/}
                </div>
                <ProgressBar now={task.progress} className="mb-2" />
                {isNewDate && shouldRenderLineManagerSection && (
                  <>
                    <Form.Label>New Due Date</Form.Label>
                             <Form.Control
                                type="datetime-local"
                                name="newDueDate"
                                value={newDueDate}
                                min={newDueDate}
                                onChange={(dueDate) => setNewDueDate(dueDate.target.value)}
    
                              />
                  </>
                )}

                {/*rating section*/}
                {showRateForm && shouldRenderLineManagerSection && (
                  <div>
                    <div className="mb-3">
                      <br />
                      <h6>How would you rate this task?</h6>
                      <Form.Label htmlFor="formGroupExampleInput2">
                        Timeliness
                      </Form.Label>
                      <Select
                        onChange={(option) => {
                          if (option && option.value) {
                            setTimeliness(option.value);
                          }
                        }}
                        options={ratingOptions}
                        isSearchable={true}
                      />
                    </div>
                    <div className="mb-3">
                      <Form.Label htmlFor="formGroupExampleInput2">
                        Accuracy
                      </Form.Label>
                      <Select
                        onChange={(option) => {
                          if (option && option.value) {
                            setAccuracy(option.value);
                          }
                        }}
                        options={ratingOptions}
                        isSearchable={true}
                      />
                    </div>
                    <div className="mb-3">
                      <Form.Label htmlFor="formGroupExampleInput2">
                        Efficiency
                      </Form.Label>
                      <Select
                        onChange={(option) => {
                          if (option && option.value) {
                            setEfficiency(option.value);
                          }
                        }}
                        options={ratingOptions}
                        isSearchable={true}
                      />
                    </div>
                    <div className="mb-3">
                      <Form.Label htmlFor="formGroupExampleInput2">
                        Communication
                      </Form.Label>
                      <Select
                        onChange={(option) => {
                          if (option && option.value) {
                            setCommunication(option.value);
                          }
                        }}
                        options={ratingOptions}
                        isSearchable={true}
                      />
                    </div>
                  </div>
                )}
              </Card.Body>
              <Card.Footer className="p-3 pt-0">
                <p className="fs-xs">
                  Assigned by: <Link to="">{task.creator}</Link>
                </p>
                <Nav as="nav">
                  <Nav.Link href="">
                    <i className="ri-eye-2-line"></i> {task.views}
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-chat-1-line"></i> {task.comments}
                  </Nav.Link>
                </Nav>
              </Card.Footer>

              <div className="chat-body-footer">
                <Nav className="nav-icon">
                  <Nav.Link href="">
                    <i className="ri-add-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-image-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-gift-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-emotion-happy-line"></i>
                  </Nav.Link>
                </Nav>
                <div className="msg-box">
                  <Form.Control
                    type="text"
                    placeholder="Add a comment..."
                    onChange={(event) => setComment(event.target.value)}
                    value={comment}
                  />
                  <Link href="" className="msg-send" onClick={handleCommentAction}>
                    <i className="ri-send-plane-2-line"></i>
                  </Link>
                </div>
              </div>
            </Card>
          </Modal.Body>

          <Modal.Footer>
            <Link to={`/tasks?task=${task.taskId}`}>
              <Button variant="primary">View Activity</Button>
            </Link>
            {task.status !== "COMPLETED" && shouldRenderLineManagerSection && (
              <Button variant="primary" onClick={cancelTask}>
                Cancel Task
              </Button>
            )}
            {task.status === "NEW" && isNewSection && (
              <div>
                <Button variant="primary" onClick={startTask}>
                  Start Task
                </Button>
              </div>
            )}
            {task.status !== "COMPLETED" &&
              task.status !== "EXTENSION_REQUESTED" &&
              task.status !== "PENDING_APPROVAL" &&
              !shouldRenderLineManagerSection && (
                <div>
                  <Button variant="primary" onClick={requestExtension}>
                    Request For An Extension
                  </Button>
                </div>
              )}

            {(task.status === "ONGOING" ||
              (task.status === "OVERDUE" && isNewSection)) && (
              <Button variant="primary" onClick={submitTask}>
                Submit For Approval
              </Button>
            )}
            {/*{(task.status === "OVERDUE" && isNewSection)&& <Button variant="primary" onClick={requestExtension}>*/}
            {/*    Request For An Extension*/}
            {/*</Button>}*/}
            {/*///vutton options for manager*/}

            {task.status === "OVERDUE" && shouldRenderLineManagerSection && (
              <Button variant="primary" onClick={openNewDate}>
                Reset Due Date
              </Button>
            )}
            {task.status === "PENDING_APPROVAL" &&
              shouldRenderLineManagerSection &&
              !showRateForm && (
                <Button variant="primary" onClick={approveTask}>
                  Approve Task
                </Button>
              )}
            {(showRateForm ||
              (task.status === "COMPLETED" && task.rated === false)) && (
              <Button variant="primary" onClick={rateTask}>
                Rate Task
              </Button>
            )}
            {task.status === "EXTENSION_REQUESTED" &&
              shouldRenderLineManagerSection &&
              !isNewDate && (
                <Button variant="primary" onClick={openNewDate}>
                  Reset Due Date
                </Button>
              )}
            {isNewDate && (
              <Button variant="primary" onClick={approveExtension}>
                Approve Extension
              </Button>
            )}
            <Button variant="secondary" onClick={lgHandleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      }
       
        {logSuccess && <SuccessModal message={"Successful"} />}
        {logError && (
          <FailureModal message={errorMessage} action={changeParentModlal} />
        )}
        <Modal show={isLoading} centered size="sm">
          <Modal.Header></Modal.Header>
          <Modal.Body>
            <div align={"center"}>
              <h3 className="text-center">Processing...</h3>
              <Spinner animation="border" />
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
}
