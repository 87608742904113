import React from "react";
import {Badge, ProgressBar, Nav, Card} from "react-bootstrap";


import {Link, useNavigate} from "react-router-dom";

export default function TaskListing(props) {

    const taskData = props.taskList;
    const isSingleTask = props.isSingleTask;
    const singleTask = props.singleTask;
    const handleTaskClick = props.taskClickFunction;



    return (
        <React.Fragment>
            {!isSingleTask && (
                <div>
                    {taskData.map((task, index) => (
                        <Card className="card-task" key={index} onClick={() => handleTaskClick(task)}>
                            <Card.Body className="p-3 pb-1">
                                {/* Render task data */}
                                <div className="d-flex flex-row-reverse align-items-center justify-content-between mt-2 mb-1">
                                    {/*  {task.taskType}  */}
                                    {/*{task.badge.map((badge, ind) => (*/}
                                    <Badge key="0" bg={task.priority === "HIGH" ? "danger" : task.priority === "MEDIUM" ? "warning" : "secondary"}>
                                        {task.priority === "HIGH" ? "High Priority" : task.priority === "MEDIUM" ? "Medium Priority" : "Low Priority"}
                                    </Badge>
                                    <Badge key="1" bg="success">
                                        {task.taskType}
                                    </Badge>

                                    {/*))}*/}
                                </div>
                                <ProgressBar style={{marginTop:"18px"}} now={task.progress} className="mb-2"/>
                                <div
                                    className="d-flex flex-row-reverse align-items-center justify-content-between mt-2 mb-1">
                                    <span className="card-date">{task.rated === false ? "NOT YET RATED" : "Rating: " + task.rating}</span>
                                    <Card.Title as="h6">{task.name}</Card.Title>
                                </div>
                                <p className="fs-xs">
                                    Assigned to: <Link to="">{task.assignees}</Link>
                                </p>
                                {task.taskDescription && <p className="fs-sm">{task.taskDescription}</p>}

                                <div
                                    className="d-flex align-items-center justify-content-between fs-xs text-secondary mb-1">
                                    {/*<span>*/}
                                    {/*  <strong className="fw-medium">{task.progress}%</strong> complete*/}
                                    <span className="card-date">{"Deadline:" + task.dueDate}</span>

                                    {/*</span>*/}
                                    <span>{task.hour}</span>
                                </div>
                                <ProgressBar now={task.progress} className="mb-2"/>
                            </Card.Body>
                            <Card.Footer className="p-3 pt-0">
                                <p className="fs-xs">
                                    Assigned by: <Link to="">{task.creator}</Link>
                                </p>
                                <Nav as="nav">
                                    <Nav.Link href="">
                                        <i className="ri-eye-2-line"></i> {task.views}
                                    </Nav.Link>
                                    <Nav.Link href="">
                                        <i className="ri-chat-1-line"></i> {task.comments}
                                    </Nav.Link>
                                </Nav>
                            </Card.Footer>

                        </Card>
                    ))}


                    {taskData.length === 0 && (
                        <Card>
                            <Card.Body>
                                <div className="text-center">
                                    <h4 className="mb-3">No Tasks Found</h4>
                                </div>
                            </Card.Body>
                        </Card>
                    )}
                </div>
            )}
            {
                isSingleTask && (
                    <Card className="card-task" key={55} onClick={() => handleTaskClick(singleTask)}>
                        <Card.Body className="p-3 pb-1">
                            {/* Render task data */}
                            <div className="d-flex flex-row-reverse align-items-center justify-content-between mt-2 mb-1">
                                {/*  {task.taskType}  */}
                                {/*{task.badge.map((badge, ind) => (*/}
                                <Badge key="0" bg={singleTask.priority === "HIGH" ? "danger" : singleTask.priority === "MEDIUM" ? "warning" : "secondary"}>
                                    {singleTask.priority === "HIGH" ? "High Priority" : singleTask.priority === "MEDIUM" ? "Medium Priority" : "Low Priority"}
                                </Badge>
                                <Badge key="1" bg="success">
                                    {singleTask.taskType}
                                </Badge>

                                {/*))}*/}
                            </div>
                            <ProgressBar style={{marginTop:"18px"}} now={singleTask.progress} className="mb-2"/>
                            <div
                                className="d-flex flex-row-reverse align-items-center justify-content-between mt-2 mb-1">
                                <span className="card-date">{singleTask.rated === false ? "NOT YET RATED" : "Rating: " + singleTask.rating}</span>
                                <Card.Title as="h6">{singleTask.name}</Card.Title>
                            </div>
                            <p className="fs-xs">
                                Assigned to: <Link to="">{singleTask.assignees}</Link>
                            </p>
                            {singleTask.taskDescription && <p className="fs-sm">{singleTask.taskDescription}</p>}

                            <div
                                className="d-flex align-items-center justify-content-between fs-xs text-secondary mb-1">
                                {/*<span>*/}
                                {/*  <strong className="fw-medium">{task.progress}%</strong> complete*/}
                                <span className="card-date">{"Deadline:" + singleTask.dueDate}</span>

                                {/*</span>*/}
                                <span>{singleTask.hour}</span>
                            </div>
                            <ProgressBar now={singleTask.progress} className="mb-2"/>
                        </Card.Body>
                        <Card.Footer className="p-3 pt-0">
                            <p className="fs-xs">
                                Assigned by: <Link to="">{singleTask.creator}</Link>
                            </p>
                            <Nav as="nav">
                                <Nav.Link href="">
                                    <i className="ri-eye-2-line"></i> {singleTask.views}
                                </Nav.Link>
                                <Nav.Link href="">
                                    <i className="ri-chat-1-line"></i> {singleTask.comments}
                                </Nav.Link>
                            </Nav>
                        </Card.Footer>

                    </Card>
                )

            }
        </React.Fragment>
    );
}