import React, { useEffect, useState } from "react";

import { Button, Card, Col, Row } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import {Link} from "react-router-dom";
import { formatRevenue, } from "../../../logic/Utils";

export default function MetricsOverview(props) {

    const metrics = props.metrics;
 
  const d1 = [[0, 38], [1, 32], [2, 31], [3, 33], [4, 34], [5, 35], [6, 38], [7, 37], [8, 39], [9, 34], [10, 33], [11, 32], [12, 34], [13, 38], [14, 42], [15, 43], [16, 45], [17, 43], [18, 45], [19, 48], [20, 45], [21, 46], [22, 44], [23, 42], [24, 46], [25, 48], [26, 55], [27, 54], [28, 58], [29, 69]];
  const d2 = [[0, 12], [1, 11], [2, 9], [3, 10], [4, 15], [5, 9], [6, 11], [7, 12], [8, 7], [9, 16], [10, 18], [11, 24], [12, 16], [13, 10], [14, 9], [15, 16], [16, 15], [17, 28], [18, 20], [19, 15], [20, 20], [21, 12], [22, 15], [23, 12], [24, 10], [25, 12], [26, 14], [27, 18], [28, 20], [29, 21],];

  // Tickets Earnings
  const seriesOne = [{
    name: 'series1',
    data: d1
  }, {
    name: 'series2',
    data: d2
  }];

  const optionOne = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false },
      stacked: true,
      sparkline: { enabled: true }
    },
    colors: ['#85b6fe', '#506fd9'],
    stroke: {
      curve: 'straight',
      width: 2
    },
    yaxis: {
      min: 0,
      max: 150,
      show: false
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
      }
    },
    tooltip: { enabled: false }
  };

  // Tickets Available
  const seriesTwo = [{
    data: [[0, 1], [1, 2], [2, 5], [3, 6], [4, 8], [5, 10], [6, 15], [7, 18], [8, 13], [9, 11], [10, 13], [11, 15], [12, 13], [13, 7], [14, 5], [15, 8], [16, 11], [17, 7], [18, 5], [19, 5], [20, 6], [21, 6], [22, 5], [23, 5], [24, 6], [25, 2], [26, 1]]
  }, {
    data: [[0, 2], [1, 3], [2, 4], [3, 8], [4, 4], [5, 12], [6, 4], [7, 6], [8, 5], [9, 10], [10, 4], [11, 5], [12, 10], [13, 2], [14, 6], [15, 16], [16, 5], [17, 17], [18, 14], [19, 6], [20, 5], [21, 2], [22, 12], [23, 4], [24, 7], [25, 1], [26, 2], [27, 1], [28, 2], [29, 2], [30, 1], [31, .2], [32, .2], [33, .2], [34, .2], [35, .2], [36, .2], [37, .2], [38, .2], [39, .2], [40, .2], [41, .2], [42, .2], [43, .2], [44, .2], [45, .2], [46, .2], [47, .2], [48, .2], [49, .2]]
  }];

  const optionTwo = {
    chart: {
      stacked: true,
      sparkline: { enabled: true }
    },
    states: {
      hover: {
        filter: { type: 'none' }
      },
      active: {
        filter: { type: 'none' }
      }
    },
    colors: ['#506fd9', '#e5e9f2'],
    grid: {
      padding: {
        bottom: 10,
        left: 0,
        right: 0
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '40%',
        endingShape: 'rounded'
      },
    },
    stroke: {
      curve: 'straight',
      lineCap: 'square'
    },
    yaxis: {
      min: 0,
      max: 30
    },
    fill: { opacity: 1 },
    tooltip: { enabled: false }
  };

  // Tickets Sold
  const seriesThree = [{
    name: 'series1',
    data: d1
  }];

  const optionThree = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false },
      sparkline: { enabled: true }
    },
    colors: ['#506fd9'],
    stroke: {
      curve: 'straight',
      width: 2
    },
    xaxis: { max: 20 },
    yaxis: {
      min: 0,
      max: 160,
      show: false
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
      }
    },
    tooltip: { enabled: false }
  };

  // Tickets Unsold
  const optionFour = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false },
      sparkline: { enabled: true }
    },
    colors: ['#85b6ff'],
    stroke: {
      curve: 'straight',
      width: 2
    },
    xaxis: {
      min: 3,
      max: 25
    },
    yaxis: {
      min: 0,
      max: 160,
      show: false
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
      }
    },
    tooltip: { enabled: false }
  };

 

  const optionBar = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 80,
        grid: {
          borderColor: '#e2e5ec',
          color: '#f3f5f9'
        },
        ticks: {
          font: {
            size: 11
          }
        }
      },
      y: {
        grid: {
          borderWidth: 0,
          color: '#f3f5f9'
        },
        ticks: {
          color: '#212830',
          font: {
            size: 14
          }
        }
      }
    }
  };


  
  // Changing necessary options between dark and light skin
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  if (skin === 'dark') {
    optionBar.scales['x'].grid.color = '#222b41';
    optionBar.scales['x'].ticks.color = 'rgba(255,255,255,.65)';
    optionBar.scales['x'].grid.borderColor = '#222b41';
    optionBar.scales['y'].grid.color = '#222b41';
    optionBar.scales['y'].ticks.color = 'rgba(255,255,255,.65)';
  } else {
    optionBar.scales['x'].grid.color = '#edeff6';
    optionBar.scales['x'].ticks.color = '#42484e';
    optionBar.scales['x'].grid.borderColor = '#edeff6';
    optionBar.scales['y'].grid.color = '#edeff6';
    optionBar.scales['y'].ticks.color = '#42484e';
  }

  const switchSkin = (skin) => {
    const textPrimary = document.getElementsByClassName('text-primary-dark');

    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }

      for (const txt of textPrimary) {
        txt.classList.add('text-primary');
      }

    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }

      for (const txt of textPrimary) {
        txt.classList.remove('text-primary');
      }
      
    }
  }

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      
       
        <Row  style={{ marginTop: "15px" }}className="g-3">
          <Col xl="6">
            <Card className="card-one card-ticket-earnings">
              <Card.Body className="d-flex flex-column p-4">
                <h1 className="card-value mb-3 fs-40 ls--2">{formatRevenue(metrics.totalRevenueGeneratedYearToDate)}</h1>
                <label className="card-label fw-semibold text-dark mb-1">Annual Total Value (Sales)</label>
                <p className="w-75 fs-sm text-secondary mb-4">This is the value of sales generated by all company employees this year.</p>
                <p className="mb-5">
                <Link to="/analytics/sales-records">
  <Button variant="primary" className="fs-sm">
    View Sales Records
  </Button>
</Link>

                 
                </p>

                <div className="d-flex gap-4 fs-sm mt-auto text-primary-dark lh-1 opacity-75">
                  <span><strong className="fw-semibold ff-numerals">{metrics.totalBusinessCasesYearToDate}</strong> Closed Business Cases</span>
                  <span><strong className="fw-semibold ff-numerals">{metrics.totalPopulationCountYearToDate}</strong> Total Population Count</span>
                </div>
              </Card.Body>
              <ReactApexChart series={seriesOne} options={optionOne} type="area" height={390} className="apex-chart-two d-flex align-items-end" />
            </Card>
          </Col>
          <Col xl="6">
            <Row className="g-3">
              <Col xs="12">
                <Card className="card-one card-ticket-available">
                  <Card.Body className="p-4">
                    <div className="d-flex justify-content-end">
                      <div className="w-45">
                        <h1 className="card-value fs-32 mb-2 ls--1">{formatRevenue(metrics.annualRevenueTarget)}</h1>
                        <label className="card-label fw-semibold text-dark mb-1">Annual Target (Value)</label>
                        <p className="text-secondary fs-sm mb-0">This is the annual Target for the company (sales)</p>
                      </div>
                    </div>
                  </Card.Body>
                  <ReactApexChart series={seriesTwo} options={optionTwo} type="bar" height={170} className="apex-chart" />
                </Card>
              </Col>
              <Col sm="6">
                <Card className="card-one card-ticket-sold">
                  <Card.Body className="p-4">
                    <div className="d-flex flex-column align-items-center">
                      <h1 className="card-value fs-32 mb-2 ls--1">{formatRevenue(metrics.revenueGeneratedYearToDateBySalesTeam)}</h1>
                      <label className="card-label fw-semibold text-dark mb-1">Sales Team</label>
                      <p className="text-secondary text-center fs-sm mb-0">This is the value of sales generated by the sales team for this year.</p>
                    </div>
                  </Card.Body>
                  <ReactApexChart series={seriesThree} options={optionThree} type="area" height={210} className="apex-chart" />
                </Card>
              </Col>
              <Col sm="6">
                <Card className="card-one card-ticket-sold">
                  <Card.Body className="p-4">
                    <div className="d-flex flex-column align-items-center">
                      <h1 className="card-value fs-32 mb-2 ls--1">{formatRevenue(metrics.revenueGeneratedYearToDateByOtherTeams)}</h1>
                      <label className="card-label fw-semibold text-dark mb-1">Other Teams</label>
                      <p className="text-secondary text-center fs-sm mb-0">This is the value of sales generated by the other team-members for this year</p>
                    </div>
                  </Card.Body>
                  <ReactApexChart series={seriesThree} options={optionFour} type="area" height={210} className="apex-chart" />
                </Card>
              </Col>
            </Row>
          </Col>
          <Col xl="12">
            <Row className="g-3">
              <Col xs="6" sm>
                <Card className="card-one">
                  <Card.Body className="p-3">
                    <div className="d-block fs-40 lh-1 text-primary mb-1">
                      <i className="ri-calendar-todo-line"></i>
                    </div>
                    <h3 className="card-value mb-0 ls--1 fs-32">{metrics.salesHighlights.highestPerformingSalesOfficer}</h3>
                    <label className="d-block mb-1 fw-medium text-dark">Highest Performing Officer(Sales)</label>
                    <small>
                      <span className="d-inline-flex text-success">{metrics.salesHighlights.highestPerformingSalesOfficerPercentage}% <i className="ri-arrow-up-line"></i></span> 
                    </small>
                  </Card.Body>
                </Card>
              </Col>
  
              <Col sm>
                <Card className="card-one">
                  <Card.Body className="p-3">
                    <div className="d-block fs-40 lh-1 text-secondary mb-1">
                      <i className="ri-calendar-2-line"></i>
                    </div>
                    <h3 className="card-value mb-0 fs-32 ls--1">{metrics.salesHighlights.highestPerformingNonSalesOfficer}</h3>
                    <label className="d-block mb-1 fw-medium text-dark">Highest Performing Employee (Non-Sales) </label>
                    <small><span className="d-inline-flex text-success">0% <i className="ri-arrow-up-line"></i></span></small>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs="6" sm>
                <Card className="card-one">
                  <Card.Body className="p-3">
                    <div className="d-block fs-40 lh-1 text-ui-02 mb-1">
                      <i className="ri-calendar-check-line"></i>
                    </div>
                    <h3 className="card-value mb-0 fs-32 ls--1">Not Set</h3>
                    <label className="d-block mb-1 fw-medium text-dark">Most Used Health Plan</label>
                    <small><span className="d-inline-flex text-success">0% <i className="ri-arrow-up-line"></i></span></small>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm>
                <Card className="card-one">
                  <Card.Body className="p-3">
                    <div className="d-block fs-40 lh-1 text-secondary mb-1">
                      <i className="ri-calendar-2-line"></i>
                    </div>
                    <h5 className="card-value mb-0 fs-32 ls--1">{formatRevenue(metrics.salesHighlights.highestSalesRecordValue)}</h5>
                    <label className="d-block mb-1 fw-medium text-dark">Highest Sales Record</label>
                    <small>Total Population Count: <span className="d-inline-flex text-success">{metrics.salesHighlights.populationForHighestSalesRecord} </span></small>
                 </Card.Body>
                </Card>
              </Col>
              <Col sm>
                <Card className="card-one">
                  <Card.Body className="p-3">
                    <div className="d-block fs-40 lh-1 text-secondary mb-1">
                      <i className="ri-calendar-2-line"></i>
                    </div>
                    <h1 className="card-value mb-0 fs-32 ls--1">{metrics.salesHighlights.highestSalesRecordClientName} </h1>
                    <label className="d-block mb-1 fw-medium text-dark">Highest Sales Record</label>
        
                    <small>Sold by: <span className="d-inline-flex text-success">{metrics.salesHighlights.soldBy} </span></small>
                    <br/>
                    <small>Date Sold: <span className="d-inline-flex text-success">{metrics.salesHighlights.highestSalesRecordDate} </span></small>
                  </Card.Body>
                </Card>
              </Col>
      
            </Row>
          </Col>
        </Row>

  
     
    </React.Fragment>
  );
}