import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button  } from "react-bootstrap";

import { formatRevenue  } from "../../../logic/Utils";


import MlrView from "./MlrView";
const MlrGrid = (props) => {
  const [selectedMlrRecord, setSelectedMlrRecord] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const rows = props.data;
  const isProvider = props.isProvider;
  const isPlan = props.isPlan;


  const clientColumns = [
    { 
      field: "id",
      headerName: "S/N",
      width: 25,
    },

  { field: "clientName", headerName: "Name of Client", width: 300},
  { field: "totalClaims", headerName: "Total Claims Value",valueFormatter: (params) => formatRevenue(params.value),  width: 150},
  { field: "totalRevenue", headerName: "Total Revenue Value",valueFormatter: (params) => formatRevenue(params.value), width: 150 },
  { field: "totalEncounterCount", headerName: "Total Encounter Count" },
  { field: "mlr", headerName: "MLR"},
  
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
        <Button
            variant="primary"
            style={{ marginRight: "10px" }}
            // startIcon={<EditIcon />}
            onClick={() => handleView(params.row)}
          >
            View Breakdown
          </Button>
        </>
      ),
    },
  ];

  const providerColumns = [
    { 
      field: "id",
      headerName: "S/N",
      width: 25,
    },

  { field: isProvider?"providerName":"planType", headerName: isProvider?"Name of Provider":"Plan Name", width: 250},
  { field: "totalClaims", headerName: "Total Claims Value",valueFormatter: (params) => formatRevenue(params.value),  width: 150},
  { field: "totalRevenue", headerName: "Total Revenue Value",valueFormatter: (params) => formatRevenue(params.value), width: 150 },
  { field: "totalEncounterCount", headerName: "Total Encounter Count" },
  { field: "mlr", headerName: "MLR"},
  
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
        <Button
            variant="primary"
            style={{ marginRight: "10px" }}
            // startIcon={<EditIcon />}
            onClick={() => handleView(params.row)}
          >
            View Breakdown
          </Button>
        </>
      ),
    },
  ];

  const handleView = (row) => {

    // if (validateOwnership(row)) {
 
    //    return;
    // }
 
     
     setShowModal(true);
      setViewMode(true);
     setSelectedMlrRecord(row);
    
   };



  const handleClose = () => {
    // Implement your edit logic here
    setShowModal(false);
    setSelectedMlrRecord(null);
  };



  return (
    <div style={{ height: 600, width: "100%" }}>
      <DataGrid
        rows={rows}
        slots={{ toolbar: GridToolbar }}
        columns={isProvider || isPlan?providerColumns:clientColumns}
        pageSize={20}
        // onSelectionModelChange={(selection) =>
        //   setSelectedMlrRecord(selection.selectionModel[0])
        // }
        search
      />
    

      {(selectedMlrRecord && viewMode)&& (
        <div>
          {showModal && (
            <MlrView mlrRecord={selectedMlrRecord} isProvider = {isProvider} isPlan = {isPlan}closeModal={handleClose} />
          )}
        </div>
      )}
    </div>
  );
};

export default MlrGrid;
