import React from "react";

import HrReports from "../../components/HrReports";

export default function OperationsReportTeam() {

    const teamId = 9;
    const teamName = "Operations Team";
    return (
        <React.Fragment>
            <HrReports teamId={teamId} teamName={teamName} />
        </React.Fragment>
    );
}