import React from "react";
import RouteResolver from "./routes/RouteResolver";
import TaskProvider from "./context/TaskProvider";
import LogoutAndRedirect from "./logic/LogoutAndRedirect";





export default function App() {

    // const token = localStorage.getItem("token");
    //
    // LoginValidityChecker(token);

  return (
      <TaskProvider>
      <RouteResolver />

      </TaskProvider>
  );
}