import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Nav, Card, Button, Tab } from "react-bootstrap";

import projects from "../assets/img/projects.png";
import Header from "../layouts/Header";
import SingleWorkPlan from "../components/SingleWorkPlan";
import TeamDropdown from "../components/TeamDropdown";
import Sidebar from "../layouts/Sidebar";
import { ProjectService } from "../service/ProjectService";
import Lottie from "lottie-react";
import animationData from "../assets/animation/animation_lmawejkm.json";

export default function WorkPlans() {
  const [selectedProject, setSelectedProject] = React.useState({});

  const [workplanData, setWorkPlanData] = React.useState([]);

  const [showProjectModal, setShowProjectModal] = React.useState(false);

  const [ongoingTasks, setOngoingTasks] = useState([]);
  const [inactiveTasks, setInactiveTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);

  const [loading, setLoading] = React.useState(false);

  const handleItemClick = (team) => {
    getUsersWorkplan(team.users_id);
  };

  async function getUsersWorkplan(userId) {
    try {
      setLoading(true);
  
      const response = await ProjectService.getAllProjectsByUser(
        localStorage.getItem("token"),
        userId
      );
  
      const result = response.data.result;
  
      const ongoing = result.filter((task) => task.status === "ONGOING");
      const inactive = result.filter((task) => task.status === "INACTIVE");
      const completed = result.filter((task) => task.status === "COMPLETED");
  
      setOngoingTasks(ongoing);
      setInactiveTasks(inactive);
      setCompletedTasks(completed);
  
      console.log(ongoing);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }
  

  function handleProjectClick(project) {
    //open modal
    setSelectedProject(project);
    setShowProjectModal(true);
  }

  function handleProjectModalClose() {
    setShowProjectModal(false);
  }

  return (
    <React.Fragment>
      <Sidebar />
      <Header />
      <div className="main main-app p-3 p-lg-4 p-xxl-5">
        <Row className="g-5">
          <Col xl>
            <ol className="breadcrumb fs-sm mb-2">
              <li className="breadcrumb-item">
                <Link to="#">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Work Plans
              </li>
            </ol>
            <h2 className="main-title">Work Plans</h2>

            <TeamDropdown handleItemClick={handleItemClick} />
            <br />

            {loading && (
              <div className={`fullscreen-loader ${loading ? "show" : "hide"}`}>
                <Lottie
                  animationData={animationData}
                  loop={true}
                  autoPlay={true}
                />
              </div>
            )}
            {!loading && (
              <>
                <Tab.Container
                  defaultActiveKey={"health-plan-info"}
                  className="mt-5"
                >
                  <Nav className="nav-line mb-4">
                    <Nav.Item>
                      <Nav.Link
                        style={{ marginRight: "20px" }}
                        eventKey="health-plan-info"
                      >
                        Work Plans to Approve
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        style={{ marginRight: "20px" }}
                        eventKey="payment-history"
                      >
                        Approved Work Plans
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        style={{ marginRight: "20px" }}
                        eventKey="completed"
                      >
                        Completed Work Plans
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <br />
                  <Tab.Content>
                    <Tab.Pane eventKey="health-plan-info">
                      <div>
                        {inactiveTasks.length > 0 && (
                          <Row className="g-3">
                            {inactiveTasks.map((event, index) => (
                              <Col sm="4" key={index}>
                                <Card
                                  className="card-event"
                                  onClick={() => handleProjectClick(event)}
                                >
                                  <Link to="#">
                                    <img
                                      src={projects}
                                      className="card-img-top"
                                      alt=""
                                    />
                                  </Link>
                                  <Card.Body>
                                    <h6>
                                      <Link to="">{event.name}</Link>
                                    </h6>
                                    <p className="mb-0">
                                      {event.startDate + " - " + event.endDate}
                                    </p>
                                    <p className="text-secondary fs-sm">
                                      {event.name}
                                    </p>

                                    <div className="mutual-badge">
                                      <ul>
                                        {/*{event.mutual.map((img, ind) => (*/}
                                        {/*  <li key={ind}><Avatar img={img} /></li>*/}
                                        {/*))}*/}
                                      </ul>
                                      <label>
                                        {" "}
                                        You and {event.assigneeCount} others are
                                        part of this.
                                      </label>
                                    </div>
                                  </Card.Body>
                                  <Card.Footer>
                                    <Button
                                      variant="primary"
                                      onClick={() =>
                                        handleProjectClick(selectedProject)
                                      }
                                    >
                                      <i className="ri-star-line"></i>View Work
                                      Plan
                                    </Button>
                                  </Card.Footer>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        )}
                        {inactiveTasks.length === 0 && (
                          <Card>
                            <Card.Body>
                              <div className="text-center">
                                <h4 className="mb-3">No WorkPlans Found</h4>
                              </div>
                            </Card.Body>
                          </Card>
                        )}
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="payment-history">
                      <div>
                        {ongoingTasks.length > 0 && (
                          <Row className="g-3">
                            {ongoingTasks.map((event, index) => (
                              <Col sm="4" key={index}>
                                <Card
                                  className="card-event"
                                  onClick={() => handleProjectClick(event)}
                                >
                                  <Link to="#">
                                    <img
                                      src={projects}
                                      className="card-img-top"
                                      alt=""
                                    />
                                  </Link>
                                  <Card.Body>
                                    <h6>
                                      <Link to="">{event.name}</Link>
                                    </h6>
                                    <p className="mb-0">
                                      {event.startDate + " - " + event.endDate}
                                    </p>
                                    <p className="text-secondary fs-sm">
                                      {event.name}
                                    </p>

                                    <div className="mutual-badge">
                                      <ul>
                                        {/*{event.mutual.map((img, ind) => (*/}
                                        {/*  <li key={ind}><Avatar img={img} /></li>*/}
                                        {/*))}*/}
                                      </ul>
                                      <label>
                                        {" "}
                                        You and {event.assigneeCount} others are
                                        part of this.
                                      </label>
                                    </div>
                                  </Card.Body>
                                  <Card.Footer>
                                    <Button
                                      variant="primary"
                                      onClick={() =>
                                        handleProjectClick(selectedProject)
                                      }
                                    >
                                      <i className="ri-star-line"></i>View Work
                                      Plan
                                    </Button>
                                  </Card.Footer>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        )}
                        {ongoingTasks.length === 0 && (
                          <Card>
                            <Card.Body>
                              <div className="text-center">
                                <h4 className="mb-3">No WorkPlans Found</h4>
                              </div>
                            </Card.Body>
                          </Card>
                        )}
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="completed">
                      <div>
                        {completedTasks.length > 0 && (
                          <Row className="g-3">
                            {completedTasks.map((event, index) => (
                              <Col sm="6" key={index}>
                                <Card
                                  className="card-event"
                                  onClick={() => handleProjectClick(event)}
                                >
                                  <Link to="#">
                                    <img
                                      src={projects}
                                      className="card-img-top"
                                      alt=""
                                    />
                                  </Link>
                                  <Card.Body>
                                    <h6>
                                      <Link to="">{event.name}</Link>
                                    </h6>
                                    <p className="mb-0">
                                      {event.startDate + " - " + event.endDate}
                                    </p>
                                    <p className="text-secondary fs-sm">
                                      {event.name}
                                    </p>

                                    <div className="mutual-badge">
                                      <ul>
                                        {/*{event.mutual.map((img, ind) => (*/}
                                        {/*  <li key={ind}><Avatar img={img} /></li>*/}
                                        {/*))}*/}
                                      </ul>
                                      <label>
                                        {" "}
                                        You and {event.assigneeCount} others are
                                        part of this.
                                      </label>
                                    </div>
                                  </Card.Body>
                                  <Card.Footer>
                                    <Button
                                      variant="primary"
                                      onClick={() =>
                                        handleProjectClick(selectedProject)
                                      }
                                    >
                                      <i className="ri-star-line"></i>View Work
                                      Plan
                                    </Button>
                                  </Card.Footer>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        )}
                        {completedTasks.length === 0 && (
                          <Card>
                            <Card.Body>
                              <div className="text-center">
                                <h4 className="mb-3">No WorkPlans Found</h4>
                              </div>
                            </Card.Body>
                          </Card>
                        )}
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>

                <div>
                  {showProjectModal && (
                    <SingleWorkPlan
                      selectedProject={selectedProject}
                      closeModal={handleProjectModalClose}
                    />
                  )}
                </div>
              </>
            )}
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}
