import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button} from "react-bootstrap";

import { formatRevenue, showLoadingModal,hideLoadingModal } from "../../../logic/Utils";

import Swal from "sweetalert2";
import { InvoiceService } from "../../../service/InvoiceService";

const InvoiceGrid = (props) => {

 
  const [invoiceRecord, setInvoiceRecord] = useState({});

  const rows = props.data;
  const userDetails = useState(
    JSON.parse(localStorage.getItem("userDetails")) || {}
  );


  const columns = [
    { 
      field: "id",
      headerName: "S/N",
      width: 25,
    },
{ field: "invoiceNumber", headerName: "Invoice Number", width: 200 },
  { field: "groupName", headerName: "Group Name", width: 250},
  { field: "invoiceAmount", headerName: "Invoice Amount",valueFormatter: (params) => formatRevenue(params.value), },
  { field: "invoiceType", headerName: "Invoice Type" },
  { field: "invoiceStartDate", headerName: "Invoice Start Date"},
  { field: "invoiceEndDate", headerName: "Invoice End Date"},
  { field: "isRenewed", headerName: "Is Renewed"},
    {
      field: "actions",
      headerName: "Renewal Status",
      width: 150,
      renderCell: (params) => (
        <>
        <Button
            variant="primary"
            style={{ marginRight: "10px" }}
            // startIcon={<EditIcon />}
            onClick={() => handleAction(params.row,true)}
          >
            Mark 
          </Button>
          <Button
            variant="success"
            style={{ marginRight: "10px" }}
            // startIcon={<EditIcon />}
            onClick={() => handleAction(params.row,false)}
          >
            UnMark
          </Button>
          {/* <Button
            variant="warning"
            // startIcon={<DeleteIcon />}
            onClick={() => handleDelete(params.row)}
          >
            Delete
          </Button> */}
        </>
      ),
    },
  ];



  const handleDelete = (row) => {
    if (validateOwnership(row)) {

      return;
   }
    showLoadingModal();

 
    InvoiceService.getAllInvoices(
      localStorage.getItem("token"),row.id
     )
       .then((res) => {
         if (res.status === 200) {
           Swal.fire("Sales Data has been deleted", "", "success");
         }
 
       })
       .catch((err) => {
         hideLoadingModal();
         console.log(err);
       });
 
  };


  const handleAction = (row,mark) => {

    showLoadingModal();

    const status = mark? "renewed" : "not renewed";


    InvoiceService.markInvoices(status,
        localStorage.getItem("token"),row.id
       )
         .then((res) => {
           if (res.status === 200) {
             Swal.fire("Invoice has been updated", "", "success");
           }
   
         })
         .catch((err) => {
           hideLoadingModal();
           console.log(err);
         });



  };



  const validateOwnership = (row) => {
    if (row.salesOfficerId !== userDetails[0].userId) {
      Swal.fire("You are not the owner of this record", "", "error");
      return true;
    }
    return false;
  }
  


  return (
    <div style={{ height: 600, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={20}
        onSelectionModelChange={(selection) =>
          setInvoiceRecord(selection.selectionModel[0])
        }
        search
      />
 
    </div>
  );
};

export default InvoiceGrid;
