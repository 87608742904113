import React, { useState } from "react";
import {

  Nav,
  OverlayTrigger,

  Tab,
  Tooltip,
  Button,

} from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../layouts/Header";


import Sidebar from "../../layouts/Sidebar";
import SalesInput from "../../components/SalesInput";
import SalesBySource from "./SalesBySource";
import SalesTeam from "./SalesTeam";
import SalesTargetInput from "./components/SalesTargetInput";
import SalesOverview from "./SalesOverview";


export default function AnalyticsTabs() {
  const [showInputModal, setShowInputModal] = useState(false);
  const [showTargetInputModal, setShowTargetInputModal] = useState(false);


  const closeModal = () => {
    setShowInputModal(false);
    setShowTargetInputModal(false);
  };



 

 

  return (
    <React.Fragment>
      <Header />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Sales Analytics
              </li>
            </ol>
            <h4 className="main-title mb-0"> Sales Analytics </h4>
          </div>

          <Nav as="nav" className="nav-icon nav-icon-lg">
          <Button
              style={{ marginRight: "15px" }}
              variant="success"
              onClick={() => setShowInputModal(true)}
            >
              Add Sales Record
            </Button>
            <Button
              style={{ marginRight: "15px" }}
              variant="success"
              onClick={() => setShowTargetInputModal(true)}
            >
              View/Edit Sales Target
            </Button>
            <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
              <Nav.Link href="">
                <i className="ri-share-line"></i>
              </Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Print</Tooltip>}>
              <Nav.Link href="">
                <i className="ri-printer-line"></i>
              </Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Report</Tooltip>}>
              <Nav.Link href="">
                <i className="ri-bar-chart-2-line"></i>
              </Nav.Link>
            </OverlayTrigger>
          </Nav>
        </div>
      {/* Tabs will here */}

      <Tab.Container defaultActiveKey={"salesForTeam"} className="mt-5">
            <Nav className="nav-line ">
              <Nav.Item>
                <Nav.Link
                  style={{ marginRight: "20px" }}
                  eventKey="salesBySource"
                >
                  Sales By Source
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  style={{ marginRight: "20px" }}
                  eventKey="salesForTeam"
                >
                  Sales Team Analytics
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  style={{ marginRight: "20px" }}
                  eventKey="salesOverview"
                >
                  Sales Overview
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="salesBySource">
                {
                  <SalesBySource/>
                }
              </Tab.Pane>
              <Tab.Pane eventKey="salesForTeam">
                {
                  <SalesTeam/>
                }
              </Tab.Pane>
              <Tab.Pane eventKey="salesOverview">
                {
                  <SalesOverview/>
                }
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>

        {showInputModal && <SalesInput closeModal={closeModal} />}
        {showTargetInputModal && <SalesTargetInput closeModal={closeModal} />}

       
      </div>
    </React.Fragment>
  );
}
