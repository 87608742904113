import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Card,
  Container,
  Nav,

  Form,
  Button,
  Tab,
  Spinner,
} from "react-bootstrap";
import Footer from "../layouts/Footer";
import Sidebar from "../layouts/Sidebar";
import Header from "../layouts/Header";
import Select from "react-select";

import { Link } from "react-router-dom";
import ClaimsResult from "../components/ClaimsResult";
import { EmployeeService } from "../service/EmployeeService";
import KpiResult from "../components/KpiResult";

export default function EmployeeKpi() {


  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isProcessed, setIsProcessed] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [assigneeIds, setAssigneesIds] = React.useState([]);



  useEffect(() => {


    EmployeeService.getAllEmployees(localStorage.getItem("token"))
        .then((res) => {
       
          const selectOptions = res.data.result.map((member, index) => ({
            value: member.users_id,
            label: member.firstName + " " + member.lastName,
          }));
        
          setData(selectOptions);
        })
        .catch((err) => {
          console.log(err);

        });


  }, []);



  const [file, setFile] = React.useState(null);

  useEffect(() => {
    Prism.highlightAll();

  }, []);



  const handleUpload = async () => {
    setIsLoading(true);

    const batchKpiUpload= {
        assigneeIds: assigneeIds,
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('batchKpiUpload', assigneeIds);
    
    try {
        const token = localStorage.getItem("token");
      const response = await EmployeeService.uploadKpiForAListOfUsers(formData,token);

      const transformedData = response.data.result.map((item) => ({
        description: item.description,
        weight: item.weight,
    }));

    
      setData(transformedData);
    
   
      setIsProcessed(true);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // Handle errors, e.g., show an error message
      console.error("Upload error:", error);
    }
  };




  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Container>
          <label className="main-title-label">KPI MANAGER</label>
          {/* <h2 className="main-title"></h2> */}

          <hr className="main-separator" />

          <Tab.Container defaultActiveKey={"uploadTab"} className="mt-5">
            <Nav className="nav-line ">
              <Nav.Item>
                <Nav.Link style={{ marginRight: "20px" }} eventKey="uploadTab">
                  Upload KPI
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="uploadTab">
                {
                  <Card className="card-post mt-4">
              
                  <Card.Body>
                    <div className="mb-3">

                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>
                          KPI DOCUMENT  (XLSX files only)
                        </Form.Label>
                        <Form.Control
                          onChange={(event) => setFile(event.target.files[0])}
                          type="file"
                        />
                      </Form.Group>

            
                    </div>
                    <div className="mb-3">
                  <Form.Label htmlFor="formGroupExampleInput2">
                    Assign to Who?
                  </Form.Label>
                  {/* Conditional rendering for the Select component */}
                  {setData.length > 0 ? (
                    <Select
                      placeholder={
                        "You can assign to multiple people(If the KPIS are the same)"
                      }
                      onChange={(selectedOptions) => {
                        // Extract the array of selected values from the selectedOptions object
                        const selectedValues = selectedOptions.map(
                          (option) => option.value
                        );
                        setAssigneesIds(selectedValues); // Update the assignees state with an array of selected values
                      }}
                      options={data}
                      isMulti={true}
                      isSearchable={true}
                    />
                  ) : (
                    <p>Loading Team Members...</p>
                  )}
                </div>
      
                {!isLoading && (
                        <Button variant="primary" onClick={handleUpload}>
                          Submit
                        </Button>
                      )}
                      {isLoading && (
                        <Button className="btn-sign">
                          Processing...
                          <span />
                          <Spinner size="sm" animation="border" />
                        </Button>
                      )}
                    <br />
                    <br />
                    <br />

                    {isProcessed && <KpiResult data={data} />}
                  </Card.Body>
                
                </Card> }
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Container>
        <Footer />
      </div>
    </React.Fragment>
  );
}
