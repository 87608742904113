import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Nav,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Button,
  Form,
} from "react-bootstrap";

import { Bar } from "react-chartjs-2";
import { AnalyticsService } from "../../service/AnalyticsService";
import {
  getCurrentDate,
  showLoadingModal,
  hideLoadingModal,
  calculateCumulativeValueAmounts,
  calculateCumulativeUnitSales,
} from "../../logic/Utils";
import Swal from "sweetalert2";

import Sidebar from "../../layouts/Sidebar";
import SalesInput from "../../components/SalesInput";
import SalesTable from "../../components/SalesTable";
import SalesHighlights from "../../components/SalesHighlights";
import EmployeeDropdown from "../../components/EmployeeDropdown";
import SalesTableBySource from "../../components/SalesTableBySource";

export default function SalesBySource() {
  const [showInputModal, setShowInputModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [chartData, setChartData] = useState(null);
  const [revenueChartData, setRevenueChartData] = useState(null);
  const [salesMap, setSalesMap] = useState(null);
  const [salesMetrics, setSalesMetrics] = useState(null);

  const [employeeId, setEmployeeId] = useState("");
  const handleItemClick = (team) => {
    setEmployeeId(team.users_id);
  };

  const validateDates = () => {
    const errorMessages = [];

    if (!employeeId) {
      errorMessages.push("A sales team member must be selected.");
    }

    if (!endDate) {
      errorMessages.push("End Date is required.");
    }

    if (!startDate) {
      errorMessages.push("Start Date is required.");
    }

    // Display error messages if any
    if (errorMessages.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: errorMessages.join("\n"),
      });

      return false;
    }

    return true;
  };
  const handleSubmit = (e) => {
    showLoadingModal();
    if (!validateDates()) {
      return;
    }

    console.log(employeeId);

    AnalyticsService.getAnalyticsForSalesTeamMemberByRange(
      localStorage.getItem("token"),
      employeeId,
      startDate,
      endDate
    )
      .then((res) => {
        const salesMap = res.data.result.salesMap;
        setSalesMap(salesMap);
        setSalesMetrics(res.data.result.salesMetrics);
        if (res.data.result.salesMetrics.numberOfSales == 0) {
          Swal.fire("No sales were recorded during this period", "", "info");
          return;
        }
        setChartData(calculateCumulativeUnitSales(salesMap));
        setRevenueChartData(calculateCumulativeValueAmounts(salesMap));

        hideLoadingModal();
        setIsLoaded(true);
      })
      .catch((err) => {
        hideLoadingModal();
        console.log(err);
      });
  };

  const closeModal = () => {
    setShowInputModal(false);
  };

  const chartOption = {
    indexAxis: "y",
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 100,
        grid: {
          borderColor: "#000",
          color: "#f3f5f9",
        },
        ticks: {
          color: "#212830",
          font: {
            size: 10,
            weight: "500",
          },
        },
      },
      y: {
        grid: {
          borderWidth: 0,
          color: "#f3f5f9",
        },
        ticks: {
          color: "#212830",
          font: {
            size: 12,
          },
        },
      },
    },
  };

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  if (skin === "dark") {
    chartOption.scales["x"].grid.color = "#222b41";
    chartOption.scales["x"].ticks.color = "rgba(255,255,255,.65)";
    chartOption.scales["x"].grid.borderColor = "#222b41";
    chartOption.scales["y"].grid.color = "#222b41";
    chartOption.scales["y"].ticks.color = "rgba(255,255,255,.65)";
  } else {
    chartOption.scales["x"].grid.color = "#edeff6";
    chartOption.scales["x"].ticks.color = "#42484e";
    chartOption.scales["x"].grid.borderColor = "#edeff6";
    chartOption.scales["y"].grid.color = "#edeff6";
    chartOption.scales["y"].ticks.color = "#42484e";
  }


  const optionBar3 = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        stacked: true,
      },
      x: {
        stacked: true,
      },
    },
  };

 

  return (
    <React.Fragment>
      

        <Card className="card-one mt-3 mb-3">
          <Card.Body>
            <div className="mb-3">
            <div style={{ marginBottom: "20px" }}>
              <EmployeeDropdown
                teamId={8}
                handleItemClick={handleItemClick}
              ></EmployeeDropdown>
               </div>
              <Row>
                {" "}
                <Col sm="6">
                  <Form.Label htmlFor="formGroupExampleInput2">
                    Start Date
                  </Form.Label>

                  <Form.Control
                    name="startDate"
                    type="date"
                    id="startDate"
                    max={getCurrentDate()}
                    className="form-control input-class"
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                  />
                </Col>
                <Col sm="6">
                  <Form.Label htmlFor="formGroupExampleInput2">
                    End Date
                  </Form.Label>

                  <Form.Control
                    name="endDate"
                    type="date"
                    className="form-control input-class"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </div>
          </Card.Body>

          <Card.Footer>
            <Button variant="primary" onClick={handleSubmit}>
              Submit
            </Button>
           
          </Card.Footer>
        </Card>
        {isLoaded && (
          <Row className="g-3">
            {salesMetrics != null && (
              <SalesHighlights
                salesTarget={salesMetrics.salesTarget}
                revenueAmount={salesMetrics.revenueAmount}
                revenueTarget={salesMetrics.revenueTarget}
                totalPopulationCountTarget={
                  salesMetrics.totalPopulationCountTarget
                }
                totalPopulationCount={salesMetrics.totalPopulationCount}
                numberOfSales={salesMetrics.numberOfSales}
                totalPopulationCountAchievedPercentage={
                  salesMetrics.totalPopulationCountAchievedPercentage
                }
                revenueTargetAchievedPercentage={
                  salesMetrics.revenueTargetAchievedPercentage
                }
                salesTargetAchievedPercentage={
                  salesMetrics.salesTargetAchievedPercentage
                }
                annualRevenueTarget={salesMetrics.annualRevenueTarget}
                annualTotalPopulationCountTarget={salesMetrics.annualTotalPopulationCountTarget}
              />
            )}
            <Col xl="7">
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">
                    Sales Chart by Product Type - Revenue
                  </Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body>
                  {chartData != null && (
                    <Bar
                      data={revenueChartData}
                      options={optionBar3}
                      className="ht-300"
                    />
                  )}
                </Card.Body>
                {/* <Card.Footer>
                  <div className="p-2">
                    <Row className="g-3">
                      <Col sm="6">
                        <h3 className="card-value mb-2">
                          <span>₦</span>
                          {highestPerformingPeriodValue}
                        </h3>
                        <label className="card-title fw-semibold text-dark mb-2">
                          Highest Performing Period
                        </label>
                        <p className="mb-0 fs-xs text-secondary">
                          : {highestPerformingPeriod}
                        </p>
                      </Col>
                      <Col sm="6">
                        <h3 className="card-value mb-2">
                          <span>₦</span>
                          {lowestPerformingPeriodValue}
                        </h3>
                        <label className="card-title fw-semibold text-dark mb-2">
                          Lowest Performing Period
                        </label>
                        <p className="mb-0 fs-xs text-secondary">
                          {lowestPerformingPeriod}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Card.Footer> */}
              </Card>
            </Col>
            <Col md="6" xl="5">
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">
                    {" "}
                     Table Breakdown - Revenue
                  </Card.Title>
                  <Nav as="nav" className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body>
              
                  {salesMap != null && <SalesTableBySource salesMap={salesMap} tableCategory={"REVENUE"} />}

                  {/* <Card className="p-3 d-flex flex-row mb-2">
                    <div className="card-icon bg-primary">
                      <i className="ri-bar-chart-grouped-line"></i>
                    </div>
                    <div className="ms-3">
                      <h4 className="card-value mb-1">58394</h4>
                      <label className="card-title fw-medium text-dark mb-1">
                        Direct Traffic
                      </label>
                      <p className="fs-xs text-secondary mb-0 lh-4">
                        Highest Channel this Month - December
                      </p>
                    </div>
                  </Card>
                  <Card className="p-3 d-flex flex-row">
                    <div className="card-icon bg-ui-02">
                      <i className="ri-bar-chart-grouped-line"></i>
                    </div>
                    <div className="ms-3">
                      <h4 className="card-value mb-1">8</h4>
                      <label className="card-title fw-medium text-dark mb-1">
                        Channels
                      </label>
                      <p className="fs-xs text-secondary mb-0 lh-4">
                        Wheree we get
                      </p>
                    </div>
                  </Card> */}
                </Card.Body>
              </Card>
            </Col>
            <Col xl="5">
              <Card className="card-analytics mb-3">
                <Card.Header>
                  <Card.Title as="h6">Unit Sales By Product Type</Card.Title>
                  <Nav as="nav" className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="p-3 p-xl-4">
                {salesMap != null && <SalesTableBySource salesMap={salesMap} tableCategory={"UNITSALES"} />}

                </Card.Body>
              </Card>

    
            </Col>
            <Col xl="7">
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">
                    Unit Sales By Product Type (Chart)
                  </Card.Title>
                  <Nav as="nav" className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="p-3 p-xl-4">
                  {chartData != null && (
                    <Bar
                      data={chartData}
                      options={optionBar3}
                      className="ht-300"
                    />
                  )}
                </Card.Body>
              </Card>
            </Col> 
            )
          </Row>
        )}

        {showInputModal && <SalesInput closeModal={closeModal} />}

     
    </React.Fragment>
  );
}
