import React from 'react';
import { Table } from 'react-bootstrap';

const SalesTable = ({ salesMap }) => {
  const officerNames = Object.keys(salesMap).sort();

  const monthOrder = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'];


  // Collect all unique months from the data
  const allMonths = officerNames.reduce((months, officer) => {
    Object.keys(salesMap[officer]).forEach((month) => {
      if (!months.includes(month)) {
        months.push(month);
      }
    });
    return months.sort((a, b) => monthOrder.indexOf(a) - monthOrder.indexOf(b));;
  }, []);

  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>Month/Week</th>
          {officerNames.map((officer) => (
            <th key={officer}>{officer}</th>
          ))}
          <th>Total Revenue</th>
        </tr>
      </thead>
      <tbody>
        {allMonths.map((month) => (
          <tr key={month}>
            <td>{month}</td>
            {officerNames.map((officer) => (
              <td key={`${officer}-${month}`}>
                {salesMap[officer][month]
                  ? salesMap[officer][month].reduce(
                      (sum, record) => sum + record.revenueAmount,
                      0
                    )
                  : 0}
              </td>
            ))}
            <td>
              {officerNames.reduce(
                (sum, officer) =>
                  sum +
                  (salesMap[officer][month]
                    ? salesMap[officer][month].reduce(
                        (total, record) => total + record.revenueAmount,
                        0
                      )
                    : 0),
                0
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default SalesTable;
