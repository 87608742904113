import React, {useState} from "react";
import { Button, Card, Col, Container, Form, Nav, Row } from "react-bootstrap";
import {Link} from "react-router-dom";

import pageSvg from "../assets/svg/forgot_password.svg";
import {AuthService} from "../service/AuthService";
import PasswordSuccess from "../components/PasswordSuccess";

export default function ForgotPassword() {

  document.body.classList.remove("sidebar-show");

  const [logSuccess, setLogSuccess] = React.useState(false);
  const [email, setEmail] = useState("");



  const passwordDto = {
    email,
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // post dto

    AuthService.forgotPassword(passwordDto)
        .then((response) => {

          // Handle successful task creation
          if (response.status === 200) {
            setLogSuccess(true);
            // navigate("/login", { replace: true });
          }


        })
        .catch((error) => console.log(error)

        );

  };

  return (
    <div className="page-auth">
      <div className="header">
        <Container>
          <Link to="/" className="header-logo">MetroHealth</Link>
          <Nav className="nav-icon">
            <Nav.Link href=""><i className="ri-twitter-fill"></i></Nav.Link>
            <Nav.Link href=""><i className="ri-github-fill"></i></Nav.Link>
            <Nav.Link href=""><i className="ri-dribbble-line"></i></Nav.Link>
          </Nav>
        </Container>
      </div>

      <div className="content">
        <Container>
          <Card className="card-auth">
            <Card.Body className="text-center">
              <div className="mb-5">
                <object type="image/svg+xml" data={pageSvg} className="w-50" aria-label="svg image"></object>
              </div>
              <Card.Title>Please Reset your password</Card.Title>
              <Card.Text className="mb-5">Enter your email address and we will send you a link to reset your password.</Card.Text>

              <Row className="g-2">
                <Col sm="8">
                  <div className="mb-4">
                    {/*<Form.Label className="d-flex justify-content-between">New Password </Form.Label>*/}
                    <Form.Control onChange={(event) => setEmail(event.target.value)} type="text" placeholder="Enter your MetroHealth Email"  />
                  </div>
                </Col>
                <Col><Button onClick={handleSubmit} type="submit" variant="primary" className="btn-sign">Send Reset Link </Button></Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
        {logSuccess && (
            <PasswordSuccess message={"Link was successfully sent to your Mail"}/>
        )}
      </div>
    </div>
  );
}