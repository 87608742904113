import React, {useEffect, useState} from "react";
import Prism from "prismjs";
import {Button, Card, Form, Modal} from "react-bootstrap";
import Footer from "../layouts/Footer";
import HeaderMobile from "../layouts/HeaderMobile";
import {useNavigate} from 'react-router-dom';
import Sidebar from "../layouts/Sidebar";

export default function CreateTeam() {
    useEffect(() => {
        Prism.highlightAll();
    }, []);


    const [lgShow, setLgShow] = useState(true);

    const navigate = useNavigate();
    const lgHandleClose = () => {
        setLgShow(false);
        navigate('/teams'); // Navigate to "/apps/tasks" upon modal closure
    };
    const [taskData, setTaskData] = useState({
        label: '',
        description: ''
    });
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTaskData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform the task creation logic here
        // Reset the form
        setTaskData({
            label: '',
            description: ''
        });
        // Close the modal
        lgHandleClose();
    };


    return (
        <React.Fragment>
            <Sidebar />
            <HeaderMobile/>
            <div className="main main-docs">
                <Modal show={lgShow} onHide={lgHandleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Create a Team</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Card >
                            <Card.Body>
                                <div className="mb-3">
                                    <Form.Label htmlFor="formGroupExampleInput">Example label</Form.Label>
                                    <Form.Control onClick={handleInputChange} type="text" id="formGroupExampleInput"
                                                  placeholder="Example input placeholder"/>
                                </div>
                                <div className="mb-3">
                                    <Form.Label htmlFor="formGroupExampleInput2">Another label</Form.Label>
                                    <Form.Control onClick={handleInputChange} type="text" id="formGroupExampleInput2"
                                                  placeholder="Another input placeholder"/>
                                </div>
                                <div className="mb-3">
                                    <Form.Label htmlFor="formGroupExampleInput2">Another label</Form.Label>
                                    <Form.Control onClick={handleInputChange} type="text" id="formGroupExampleInput2"
                                                  placeholder="Another input placeholder"/>
                                </div>
                            </Card.Body>

                            <Card.Footer>
                            </Card.Footer>
                            <Form onSubmit={handleSubmit}/>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={lgHandleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={lgHandleClose}>
                            Create Task
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>
            <Footer/>
        </React.Fragment>
    );
}