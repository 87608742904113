import React, {useContext, useEffect} from "react";
import TaskContext from "../context/TaskContext";
import {TaskService} from "../service/TaskService";
import Sidebar from "../layouts/Sidebar";
import Header from "../layouts/Header";
import {Card, Col, Form, Spinner, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import TaskListing from "../apps/TaskListing";
import SingleTask from "../components/SingleTask";
import img1 from "../assets/img/img1.jpg";
import * as PropTypes from "prop-types";
import SingleWorkPlan from "../components/SingleWorkPlan";
import { ProjectService } from "../service/ProjectService";
import Swal from "sweetalert2";
import SingleWorkPlanDetails from "../components/SingleWorkPlanDetails";

function TaskComments(props) {

    const date = props.log.date;
    const activityDescription = props.log.activityDescription;
    const createdBy = props.log.createdBy;
    return (
        <React.Fragment>
            <li className="activity-date">{date}</li>
            <li className="activity-item comment">
                <p className="d-sm-flex align-items-center mb-2">
                    <Link to="" className="avatar avatar-xs me-2 d-none d-sm-inline"><img src={img1} alt="" /></Link>
                    <span className="fs-sm"><strong>{createdBy}</strong> </span>
                    <span className="text-secondary fs-xs ms-auto">{date}</span>
                </p>
                <Card className="card-comment">
                    <Card.Body>
                        {activityDescription}
                    </Card.Body>
                </Card>
            </li>
        </React.Fragment>
    );
}

TaskComments.propTypes = {task: PropTypes.any};



export default function WorkPlanDetails() {
    useEffect(() => {
        document.body.classList.add('app-task')
        return () => {
            document.body.classList.remove('app-task')
        }
    }, []);


    const [data, setData] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    let workplanId = null;
    const [taskLog, setTaskLog] = React.useState([]);

    useEffect(() => {

        const regex = /workplan=([^&]+)/;
        // Get the current URL from the browser
        const currentUrl = window.location.href;

        // Use the regular expression to extract the token from the URL
        const match = currentUrl.match(regex);
        workplanId = match && match[1]; // Extract the captured group
        console.log("WorkPlan Id is : " + workplanId);

        if (workplanId) {
            // Fetch task using Axios GET request
            ProjectService.getInfo(localStorage.getItem("token"), workplanId)
                .then(response => {

                    setIsLoaded(true);
                    setData(response.data.result);


                })
                .catch(error => {
                    console.log(error);
                    Swal.fire("Error", error.data.result, "error");
                });
        }
        // ProjectService.getProjectLog(localStorage.getItem("token"), workplanId)
        //     .then(response => {
        //         setIsLoaded(true);
        //         setTaskLog(response.data.result);
              
        //     })
        //     .catch(error => {
        //         Swal.fire("Error", error, "error");
        //     });




    }, [workplanId]);



    return (
        <React.Fragment>
            <Sidebar />
            <Header />
            <div className="main p-4 p-lg-5">
                <Row className="g-5">
                    <Col xl="9">
                        <br/>  <br/>  <br/>


                        <h2 className="main-title mb-3">WorkPlan Details</h2>
                        {isLoaded &&  (    
                        
                        <SingleWorkPlanDetails
                            selectedProject={data}
                        />
                        
              
                     
                    )}
                        <br/>  <br/>  <br/>

                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <h5 className="section-title mb-0">WorkPlan Logs</h5>
                           
                        </div>

                        {taskLog.length === 0 && (
                            <div>
                                <p>Fetching activity logs</p>
                                <Spinner animation="border" variant="success" />
                            </div>


                        )}

                        {taskLog.length > 0 && (
                            <div>
                                <ul className="activity-group mb-5">
                                    {taskLog.map((log, index) => (

                                        <TaskComments key={index} log={log} />
                                        ))}
                                </ul>

                            </div>
                        )}




                    </Col>


                </Row>
               

            </div>
        </React.Fragment>
    );

}