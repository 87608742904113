import React from "react";
import { Table } from "react-bootstrap";

const SalesTableForTeam = ({ salesMap, tableCategory }) => {
  const officerNames = Object.keys(salesMap).sort();
  const monthOrder = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];

  const formatValues = (value) => {
    // Format the number with commas
    const formattedValue = value.toLocaleString("en-US");

    // Add the Naira symbol
    const valueWithCurrency = `₦${formattedValue}`;

    return valueWithCurrency;
  };

  const allMonths = officerNames.reduce((months, officer) => {
    Object.keys(salesMap[officer]).forEach((month) => {
      if (!months.includes(month)) {
        months.push(month);
      }
    });
    return months.sort((a, b) => monthOrder.indexOf(a) - monthOrder.indexOf(b));
  }, []);

  return (
    <div>
  {tableCategory === "REVENUE" && (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>Month/Week</th>
          {officerNames.map((officer) => (
            <th key={officer}>{officer}</th>
          ))}
          <th>Total Revenue</th>
        </tr>
      </thead>
      <tbody>
        {allMonths.map((month) => (
          <tr key={month}>
            <td>{month}</td>
            {officerNames.map((officer) => (
              <td key={`${officer}-${month}`}>
                {formatValues(
                  salesMap[officer][month]
                    ? salesMap[officer][month].reduce(
                        (sum, record) => sum + record.revenueAmount,
                        0
                      )
                    : 0
                )}
              </td>
            ))}
            <td>
              {formatValues(
                officerNames.reduce(
                  (sum, officer) =>
                    sum +
                    (salesMap[officer][month]
                      ? salesMap[officer][month].reduce(
                          (total, record) => total + record.revenueAmount,
                          0
                        )
                      : 0),
                  0
                )
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>)}

    {tableCategory === "UNITSALES" && (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Month/Week</th>
              {officerNames.map((officer) => (
                <th key={officer}>{officer}</th>
              ))}
              <th>Total Unit Sales</th>
            </tr>
          </thead>
          <tbody>
            {allMonths.map((month) => (
              <tr key={month}>
                <td>{month}</td>
                {officerNames.map((officer) => (
                  <td key={`${officer}-${month}`}>
                    {salesMap[officer][month]
                      ? salesMap[officer][month].reduce(
                          (sum, record) => sum + record.totalPopulationCount,
                          0
                        )
                      : 0}
                  </td>
                ))}
                <td>
                  {officerNames.reduce(
                    (sum, officer) =>
                      sum +
                      (salesMap[officer][month]
                        ? salesMap[officer][month].reduce(
                            (total, record) =>
                              total + record.totalPopulationCount,
                            0
                          )
                        : 0),
                    0
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  
  );
};

export default SalesTableForTeam;
