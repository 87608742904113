import React from "react";
import HrReports from "../../components/HrReports";


export default function ClientReports() {

    const teamId = 7;
    const teamName = "Client Services Team";
    return (
        <React.Fragment>
            <HrReports teamId={teamId} teamName={teamName} />
        </React.Fragment>
    );
}