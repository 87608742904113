import React from "react";
import HrReports from "../../components/HrReports";


export default function MedicalServicesReports() {

    const teamId = 6;
    const teamName = "Medical Services Team";
    return (
        <React.Fragment>
            <HrReports teamId={teamId} teamName={teamName} />
        </React.Fragment>
    );
}