import axios from "axios";


const GET_SALES_TEAM_ANALYTICS = "https://mtroflow-fksngumbvq-lz.a.run.app/analytics/sales-team";



class analyticsService {

 

    getAnalyticsForSalesTeamByRange(token, startDate, endDate) {

        return axios.get(`${GET_SALES_TEAM_ANALYTICS}?startDate=${startDate}&endDate=${endDate}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    
}
getAnalyticsForSalesTeamMemberByRange(token, employeeId, startDate, endDate) {

    return axios.get(`https://mtroflow-fksngumbvq-lz.a.run.app/analytics/sales-source/${employeeId}?startDate=${startDate}&endDate=${endDate}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    });
}


getSalesRecordsForTeamByRange(token, startDate, endDate) {

    return axios.get(`https://mtroflow-fksngumbvq-lz.a.run.app/sales/records/?startDate=${startDate}&endDate=${endDate}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    });
}

getAuditHistoryForTeamByRange(token, startDate, endDate) {

    return axios.get(`https://mtroflow-fksngumbvq-lz.a.run.app/sales/audit-history/?startDate=${startDate}&endDate=${endDate}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    });
}

getSalesOverview(token) {

    return axios.get(`https://mtroflow-fksngumbvq-lz.a.run.app/analytics/sales/overview`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    });
}

getSalesRecordsForTeamMemberByRange(token, employeeId, startDate, endDate) {

    return axios.get(`https://mtroflow-fksngumbvq-lz.a.run.app/sales/${employeeId}/records/?startDate=${startDate}&endDate=${endDate}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    });
}

getSalesTargetForTeamMember(employeeId,token) {

    return axios.get(`https://mtroflow-fksngumbvq-lz.a.run.app/sales/target/${employeeId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    });
}

saveSalesTarget(dto, token) {
    return axios.post("https://mtroflow-fksngumbvq-lz.a.run.app/sales/add/target" ,dto, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    });
}

editSalesTarget(dto, token,id) {
    return axios.post(`https://mtroflow-fksngumbvq-lz.a.run.app/sales/edit/target${id}` ,dto, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    });
}
saveSalesData(dto, token) {
    return axios.post("https://mtroflow-fksngumbvq-lz.a.run.app/sales/add" ,dto, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    });
}
editSalesData(dto, token,salesRecordId) {
    return axios.post(`https://mtroflow-fksngumbvq-lz.a.run.app/sales/edit/${salesRecordId}` ,dto, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    });
}
deleteSalesData(token,salesRecordId) {
    return axios.post(`https://mtroflow-fksngumbvq-lz.a.run.app/sales/delete/${salesRecordId}` ,{}, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    });
}

}

export const AnalyticsService = new analyticsService();
