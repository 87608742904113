import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Card,
  Container,
  Nav,
  Row,
  Form,
  Button,
  Tab,
  Col,
  Table,
} from "react-bootstrap";

import {
  getCurrentDate,
  showLoadingModal,
  hideLoadingModal,
} from "../../logic/Utils";
import Swal from "sweetalert2";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";

import { AnalyticsService } from "../../service/AnalyticsService";
import { Link } from "react-router-dom";
import RecordsGrid from "./components/RecordsGrid";

export default function SalesRecords() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [salesRecordTeam, setSalesRecordTeam] = React.useState([]);
  const [mySalesRecords, setMySalesRecords] = React.useState([]);
  const [auditData, setAuditData] = React.useState([]);
  const [isProcessed, setIsProcessed] = React.useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(getCurrentDate());
  const userDetails = useState(
    JSON.parse(localStorage.getItem("userDetails")) || {}
  );
  const validateDates = () => {
    const errorMessages = [];

    if (!endDate) {
      errorMessages.push("End Date is required.");
    }

    if (!startDate) {
      errorMessages.push("Start Date is required.");
    }

    // Display error messages if any
    if (errorMessages.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: errorMessages.join("\n"),
      });

      return false;
    }

    return true;
  };

  const handleAuditLogFetch = (e) => {
    showLoadingModal();
    if (!validateDates()) {
      return;
    }

    AnalyticsService.getAuditHistoryForTeamByRange(
      localStorage.getItem("token"),
      startDate,
      endDate
    )
      .then((res) => {
        const audit = res.data.result;
        setAuditData(audit);

        if (res.data.result.length == 0) {
          Swal.fire("No logs are available for this period", "", "info");
          return;
        }

        hideLoadingModal();
        setIsProcessed(true);
      })
      .catch((err) => {
        hideLoadingModal();
        console.log(err);
      });
  };
  const handleSubmit = (e) => {
    showLoadingModal();
    if (!validateDates()) {
      return;
    }

    AnalyticsService.getSalesRecordsForTeamByRange(
      localStorage.getItem("token"),
      startDate,
      endDate
    )
      .then((res) => {
        const salesRecordTeam = res.data.result;
        setSalesRecordTeam(salesRecordTeam);

        if (res.data.result.length == 0) {
          Swal.fire("No sales were recorded during this period", "", "info");
          return;
        }

        hideLoadingModal();
        setIsProcessed(true);
      })
      .catch((err) => {
        hideLoadingModal();
        console.log(err);
      });
  };

  const handleMyRecordsRequest = (e) => {
    showLoadingModal();
    if (!validateDates()) {
      return;
    }

    AnalyticsService.getSalesRecordsForTeamMemberByRange(
      localStorage.getItem("token"),
      userDetails[0].userId,
      startDate,
      endDate
    )
      .then((res) => {
        const salesRecordTeam = res.data.result;
        setMySalesRecords(salesRecordTeam);

        if (res.data.result.length == 0) {
          Swal.fire("No sales were recorded during this period", "", "info");
          return;
        }

        hideLoadingModal();
        setIsProcessed(true);
      })
      .catch((err) => {
        hideLoadingModal();
        console.log(err);
      });
  };
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Container>
          <label className="main-title-label">Sales Records</label>
          <h2 className="main-title">Sales Records</h2>

          <Tab.Container defaultActiveKey={"mySalesRecord"} className="mt-5">
            <Nav className="nav-line ">
              <Nav.Item>
                <Nav.Link
                  style={{ marginRight: "20px" }}
                  eventKey="mySalesRecord"
                >
                  My Sales Record
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  style={{ marginRight: "20px" }}
                  eventKey="salesRecordTeam"
                >
                  Sales Record For Team
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  style={{ marginRight: "20px" }}
                  eventKey="auditHistory"
                >
                  Audit History For Team
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="mySalesRecord">
                {
                  <Card className="card-post mt-4">
                    <Card.Header>
                      <Card.Title>Select Date Range</Card.Title>
                      <Link to="" className="link-more">
                        <i className="ri-more-2-fill"></i>
                      </Link>
                    </Card.Header>
                    <Card.Body>
                    <div className="mb-3">
                        <Row>
                          {" "}
                          <Col sm="6">
                            <Form.Label htmlFor="formGroupExampleInput2">
                              Start Date
                            </Form.Label>

                            <Form.Control
                              name="startDate"
                              type="date"
                              id="startDate"
                              max={getCurrentDate()}
                              class="form-control input-class"
                              onChange={(e) => {
                                setStartDate(e.target.value);
                              }}
                            />
                          </Col>
                          <Col sm="6">
                            <Form.Label htmlFor="formGroupExampleInput2">
                              End Date
                            </Form.Label>

                            <Form.Control
                              name="endDate"
                              type="date"
                              class="form-control input-class"
                              value={endDate}
                              onChange={(e) => {
                                setEndDate(e.target.value);
                              }}
                            />
                          </Col>
                        </Row>
                      </div>

                      <Button variant="primary" onClick={handleMyRecordsRequest}>
                        Submit
                      </Button>
                      <br />
                      <br />
                      <br />
                      {isProcessed && mySalesRecords.length > 0 && (
                        <RecordsGrid data={mySalesRecords} />
                      )}
                    </Card.Body>
                  </Card>
                }
              </Tab.Pane>
              <Tab.Pane eventKey="salesRecordTeam">
                {
                  <Card className="card-one mt-3 mb-3">
                    <Card.Header>
                      <Card.Title>Select Date Range</Card.Title>
                      <Link to="" className="link-more">
                        <i className="ri-more-2-fill"></i>
                      </Link>
                    </Card.Header>
                    <Card.Body>
                      <div className="mb-3">
                        <Row>
                          {" "}
                          <Col sm="6">
                            <Form.Label htmlFor="formGroupExampleInput2">
                              Start Date
                            </Form.Label>

                            <Form.Control
                              name="startDate"
                              type="date"
                              id="startDate"
                              max={getCurrentDate()}
                              class="form-control input-class"
                              onChange={(e) => {
                                setStartDate(e.target.value);
                              }}
                            />
                          </Col>
                          <Col sm="6">
                            <Form.Label htmlFor="formGroupExampleInput2">
                              End Date
                            </Form.Label>

                            <Form.Control
                              name="endDate"
                              type="date"
                              class="form-control input-class"
                              value={endDate}
                              onChange={(e) => {
                                setEndDate(e.target.value);
                              }}
                            />
                          </Col>
                        </Row>
                      </div>

                      <Button variant="primary" onClick={handleSubmit}>
                        Submit
                      </Button>
                      <br />
                      <br />
                      <br />
                      {isProcessed && salesRecordTeam.length > 0 && (
                        <RecordsGrid data={salesRecordTeam} />
                      )}
                    </Card.Body>
                  </Card>
                }
              </Tab.Pane>
              <Tab.Pane eventKey="auditHistory">
                {
                  <Card className="card-one mt-3 mb-3">
                    <Card.Header>
                      <Card.Title>Select Date Range</Card.Title>
                      <Link to="" className="link-more">
                        <i className="ri-more-2-fill"></i>
                      </Link>
                    </Card.Header>
                    <Card.Body>
                      <div className="mb-3">
                        <Row>
                          {" "}
                          <Col sm="6">
                            <Form.Label htmlFor="formGroupExampleInput2">
                              Start Date
                            </Form.Label>

                            <Form.Control
                              name="startDate"
                              type="date"
                              id="startDate"
                              max={getCurrentDate()}
                              class="form-control input-class"
                              onChange={(e) => {
                                setStartDate(e.target.value);
                              }}
                            />
                          </Col>
                          <Col sm="6">
                            <Form.Label htmlFor="formGroupExampleInput2">
                              End Date
                            </Form.Label>

                            <Form.Control
                              name="endDate"
                              type="date"
                              class="form-control input-class"
                              value={endDate}
                              onChange={(e) => {
                                setEndDate(e.target.value);
                              }}
                            />
                          </Col>
                        </Row>
                      </div>

                      <Button variant="primary" onClick={handleAuditLogFetch}>
                        Submit
                      </Button>
                      <br />
                      <br />
                      <br />
                      {isProcessed && auditData.length > 0 && (
                        <Table striped bordered hover responsive>
                          <thead>
                            <tr>
                              <th>Description</th>
                              <th>Created By</th>
                              <th>Timestamp</th>
                            </tr>
                          </thead>
                          <tbody>
                            {auditData.map((item) => (
                              <tr key={item.id}>
                                <td>{item.changeDescription}</td>

                                <td>{item.createdByName}</td>

                                <td>{item.timestamp}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      )}
                    </Card.Body>
                  </Card>
                }
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Container>
      </div>
    </React.Fragment>
  );
}
