import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Modal, Form } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { showLoadingModal,hideLoadingModal } from "../logic/Utils";
const MetroTariff = (props) => {
  const [selectedTariff, setSelectedTariff] = useState(null);
  const [showTariffModal, setShowTariffModal] = useState(false);
  const userDetails = useState(
    JSON.parse(localStorage.getItem("userDetails")) || {}
  );
  function checkPermission(userDetails) {
   
    const result = (
      (userDetails.role == "MANAGER") ||
      userDetails.department == "EXCO" ||
      userDetails.role == "HR" || userDetails.firstName =="BLESSING"
  );

    return result;
}
  const rows = props.data;


  const handleDelete = async (row) => {

   if (!checkPermission(userDetails[0])) {
    Swal.fire({
      title: "Permission Denied",
      text: "You do not have permission to save tariffs",
      icon: "error",
    });
    return;
  }

    showLoadingModal();
    console.log(row);
  
    try {
      const res = await axios.post(
        `https://metrotariff-fksngumbvq-lz.a.run.app/tariff/delete/${row.id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      if (res.status === 200) {
        Swal.fire("Tariff has been deleted", "", "success");
      }
    } catch (err) {
      hideLoadingModal();
      console.log(err);
    }
  };
  




  const columns = [
    {
      field: "tariffDescription",
      headerName: "Tariff Description",
      width: 300,
    },
    { field: "tariffCode", headerName: "Tariff Code", width: 150 },
    { field: "category", headerName: "Category", width: 150 },
    { field: "isVariant", headerName: "Variant Type", width: 150 },
    { field: "originalDescription", headerName: "Original Description", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <>
          <Button
            variant="primary"
            style={{ marginRight: "10px" }}
            // startIcon={<EditIcon />}
            onClick={() => handleEdit(params.row)}
          >
            Edit
          </Button>

          <Button
            variant="warning"
            style={{ marginRight: "10px" }}
            // startIcon={<EditIcon />}
            onClick={() => handleDelete(params.row)}
          >
            Delete
          </Button>
          
        </>
      ),
    },
  ];

  const handleSubmit = (tariffId) => {

    if (!checkPermission(userDetails[0])) {
      Swal.fire({
        title: "Permission Denied",
        text: "You do not have permission to save tariffs",
        icon: "error",
      });
      return;
    }
    showLoadingModal();

    try {
      const response = axios.post(
        `https://metrotariff-fksngumbvq-lz.a.run.app/tariff/edit/${tariffId}`,
        selectedTariff,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        Swal.fire("Tariff has been edited", "", "success");
      }

      hideLoadingModal();
    } catch (error) {
      console.error("Upload error:", error);
      hideLoadingModal();
    }

    setShowTariffModal(false);
  };


  const handleEdit = (row) => {
    // Implement your edit logic here
    setShowTariffModal(true);
    setSelectedTariff(row);
  };


  const handleClose = () => {
    // Implement your edit logic here
    setShowTariffModal(false);
    setSelectedTariff(null);
  };



  return (
    <div style={{ height: 600, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={20}
        onSelectionModelChange={(selection) =>
          setSelectedTariff(selection.selectionModel[0])
        }
        search
      />
    
      {selectedTariff && (
        <div>
          {showTariffModal && (
            <Modal show={showTariffModal} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Tariff</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Label>Tariff Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Tariff Code"
                    value={selectedTariff ? selectedTariff.tariffCode : ""}
                    onChange={(e) =>
                      setSelectedTariff({
                        ...selectedTariff,
                        tariffCode: e.target.value,
                      })
                    }
                    style={{ marginBottom: "20px" }}
                  />

                  <Form.Label>Tariff Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Tariff Description"
                    value={
                      selectedTariff ? selectedTariff.tariffDescription : ""
                    }
                    onChange={(e) =>
                      setSelectedTariff({
                        ...selectedTariff,
                        tariffDescription: e.target.value,
                      })
                    }
                    style={{ marginBottom: "20px" }}
                  />

                  <Form.Label>Tariff Category</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Tariff Category"
                    value={selectedTariff ? selectedTariff.category : ""}
                    onChange={(e) =>
                      setSelectedTariff({
                        ...selectedTariff,
                        category: e.target.value,
                      })
                    }
                    style={{ marginBottom: "20px" }}
                  />

                  <Button variant="primary" onClick={() => handleSubmit(selectedTariff.id)}>
                    Submit
                  </Button>
                </Form>
              </Modal.Body>
              <Modal.Footer>
            
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      )}
    </div>
  );
};

export default MetroTariff;
