import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  OverlayTrigger,
  Tooltip,
  Container,
  Nav,
  Card,
  Col,
  Row,
  Form,
  Button,
  Spinner,
  Table,
  Tab,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Sidebar from "../layouts/Sidebar";
import Header from "../layouts/Header";
import { showLoadingModal, hideLoadingModal } from "../logic/Utils";

import Swal from "sweetalert2";
import axios from "axios";

// import { formatRevenue } from "../logic/Utils";
export default function ReportManager() {
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const formatRevenue = (value) => {
    // Parse the value as a floating point number
    const floatValue = parseFloat(value);

    // Format the number as currency with the Naira symbol, commas, and rounded to 2 decimal places
    const formattedValue = `₦${floatValue
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    return formattedValue;
  };

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [financialData, setData] = React.useState(null);
  const [rawFinancialData, setRawFinancialData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isProcessed, setIsProcessed] = React.useState(false);
  const [monthYear, setMonthYear] = useState("");
  const [file, setFile] = React.useState(null);
  const [budgetFile, setBudgetFile] = React.useState();
  const [budgetData, setBudgetData] = React.useState(null);
  const [budgetYear, setBudgetYear] = React.useState("");
  const [dateOfPosting, setDateOfPosting] = useState("");
  const [invoiceCrmSubject, setInvoiceCrmSubject] = useState("");
  const [paFile, setPaFile] = React.useState(null);


  const confirmAndSendData = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to send this data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, send it!",
    }).then((result) => {
      if (result.isConfirmed) {
        showLoadingModal();
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          maxRedirects: 5, // Default in Axios is 5, similar to "follow" in fetch
          url: "https://script.google.com/macros/s/AKfycbx9CqEZeDPL2RzWPOYWKdAHfvGtNuVYsgH2CSjmN0ttqIzZ4AiqRqrCsmL-SzOGZ_ZR/exec",
          headers: {
            "Content-Type": "text/plain", // Changed to text/plain as per your fetch example
          },
          data: JSON.stringify(rawFinancialData), // Make sure to stringify the data as fetch does
        };

        axios
          .request(config)

          .then((response) => {
            Swal.fire("Sent!", "Your data has been sent.", "success");
          })
          .catch((error) => {
            Swal.fire(
              "Failed!",
              "There was a problem sending your data: " + error.message,
              "error"
            );
          });
      }
    });
  };

  const handleUpload = async () => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("monthYear", monthYear);

    try {
      const response = await axios.post(
        "https://mtroflow-fksngumbvq-lz.a.run.app/analytics/upload-finance",
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the formatted response, e.g., set state with the formatted data
      setData(response.data.result.financialDto);
      setRawFinancialData(response.data.result.rawFinancialData);

      setIsProcessed(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // Handle errors, e.g., show an error message
      console.error("Upload error:", error);
    }
  };
  const handleBudgetUpload = async () => {
    showLoadingModal();

    const formData = new FormData();
    formData.append("file", budgetFile);
    formData.append("year", budgetYear);

    try {
      const response = await axios.post(
        "https://mtroflow-fksngumbvq-lz.a.run.app/analytics/upload-budget",
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setBudgetData(response.data.result);
      budgetData["action"] = "budgetReport";

      // let config = {
      //   method: "post",
      //   maxBodyLength: Infinity,
      //   maxRedirects: 5, // Default in Axios is 5, similar to "follow" in fetch
      //   url: "https://script.google.com/macros/s/AKfycbzGmVVmLnqD-Vd6klZjfuOKvxPLKjluJMLXCdYWpX2R_WdqvR2f0ATyh2X17vBycl-z/exec",
      //   headers: {
      //     "Content-Type": "text/plain", // Changed to text/plain as per your fetch example
      //   },
      //   data: JSON.stringify(budgetData), // Make sure to stringify the data as fetch does
      // };

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        maxRedirects: 5, // Default in Axios is 5, similar to "follow" in fetch
        url: "https://script.google.com/macros/s/AKfycbxZ7P1m5ndybhBwI7VAR8T1NCV250eyjrU-9n-5DUG6pqQ-hrB5xDk4MpQllAxwx6-k/exec",
        headers: {
          "Content-Type": "text/plain", // Changed to text/plain as per your fetch example
        },
        data: JSON.stringify(budgetData), // Make sure to stringify the data as fetch does
      };

      axios
        .request(config)

        .then((response) => {
          Swal.fire("Sent!", "Your data has been sent.", "success");
        })
        .catch((error) => {
          Swal.fire(
            "Failed!",
            "There was a problem sending your data: " + error.message,
            "error"
          );
        });
      hideLoadingModal();
    } catch (error) {
      hideLoadingModal();
      // Handle errors, e.g., show an error message
      console.error("Upload error:", error);
    }
  };

  const handlePaUpload = async () => {
    showLoadingModal();

    const formData = new FormData();
    formData.append("file", paFile);
    formData.append("date", dateOfPosting);
    formData.append("invoiceDescription", invoiceCrmSubject);

    try {
      const response = await axios.post(
        "https://mtroflow-fksngumbvq-lz.a.run.app/sage/pa",
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob", 
        }
      );


      const filename = invoiceCrmSubject + " SAGE UPLOAD.xlsx";
  
      const blob = new Blob([response.data], {
        type: "text/csv;charset=utf-8;",
      });
  
      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);
  
      // Create a link element and click on it to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
      // Release the temporary URL
      window.URL.revokeObjectURL(url);
      hideLoadingModal();
    } catch (error) {
      console.error("Download error:", error);
      Swal.fire({
        title: "Something went wrong!",
        text: error.message,
        icon: "error",
      });
      hideLoadingModal();
    }
  };
  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />

      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <label className="main-title-label">FINANCE REPORTS MANAGER</label>
          </div>

          <Nav as="nav" className="nav-icon nav-icon-lg">
            <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
              <Nav.Link href="">
                <i className="ri-share-line"></i>
              </Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Print</Tooltip>}>
              <Nav.Link href="">
                <i className="ri-printer-line"></i>
              </Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Report</Tooltip>}>
              <Nav.Link href="">
                <i className="ri-bar-chart-2-line"></i>
              </Nav.Link>
            </OverlayTrigger>
          </Nav>
        </div>

        <Tab.Container defaultActiveKey={"finance"} className="mt-5">
          <Nav className="nav-line ">
            <Nav.Item>
              <Nav.Link style={{ marginRight: "20px" }} eventKey="finance">
                MONTHLY FINANCE REPORT
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link style={{ marginRight: "20px" }} eventKey="pa">
                PA REPORTS
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="finance">
              <Container>
                <Row>
                  <Col md={6}>
                    <Card className="card-post mt-4">
                      <Card.Header>
                        <Card.Title>Monthly Sage Report</Card.Title>
                        <Link to="" className="link-more">
                          <i className="ri-more-2-fill"></i>
                        </Link>
                      </Card.Header>
                      <Card.Body>
                        <div className="mb-3">
                          <Row>
                            <Col sm="6">
                              <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label>
                                  Upload Excel sheet (XLSX)
                                </Form.Label>
                                <Form.Control
                                  onChange={(event) =>
                                    setFile(event.target.files[0])
                                  }
                                  type="file"
                                />
                              </Form.Group>
                            </Col>
                            <Col sm="6">
                              <Form.Label htmlFor="formGroupExampleInput2">
                                Month & Year
                              </Form.Label>

                              <Form.Control
                                name="monthYear"
                                type="date"
                                id="monthYear"
                                className="form-control input-class"
                                onChange={(e) => {
                                  setMonthYear(e.target.value);
                                }}
                              />
                            </Col>
                          </Row>
                          {!isLoading && (
                            <Button variant="primary" onClick={handleUpload}>
                              Submit
                            </Button>
                          )}
                          {isLoading && (
                            <Button className="btn-sign">
                              Processing...
                              <span />
                              <Spinner size="sm" animation="border" />
                            </Button>
                          )}
                        </div>

                        <br />
                      </Card.Body>
                      <Card.Footer></Card.Footer>
                    </Card>
                  </Col>
                  <Col md={6}>
                    <Card className="card-post mt-4">
                      <Card.Header>
                        <Card.Title>Yearly Budget </Card.Title>
                        <Link to="" className="link-more">
                          <i className="ri-more-2-fill"></i>
                        </Link>
                      </Card.Header>
                      <Card.Body>
                        <div className="mb-3">
                          <Row>
                            <Col sm="6">
                              <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label>
                                  Upload Budget sheet (XLSX)
                                </Form.Label>
                                <Form.Control
                                  onChange={(event) =>
                                    setBudgetFile(event.target.files[0])
                                  }
                                  type="file"
                                />
                              </Form.Group>
                            </Col>
                            <Col sm="6">
                              <Form.Group controlId="formYear">
                                <Form.Label>Year</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="year"
                                  value={budgetYear}
                                  onChange={(event) =>
                                    setBudgetYear(event.target.value)
                                  }
                                  placeholder="Enter Year"
                                />{" "}
                              </Form.Group>
                            </Col>
                          </Row>

                          <Button
                            variant="success"
                            style={{ marginTop: "20px" }}
                            onClick={handleBudgetUpload}
                          >
                            Upload Budget
                          </Button>
                        </div>
                      </Card.Body>
                      <Card.Footer></Card.Footer>
                    </Card>
                  </Col>
                </Row>

                <br />
                <br />
                <br />

                {isProcessed && (
                  <Container>
                    <Row style={{ marginTop: "15px" }} className="g-3">
                      <Col sm="6" xl="4">
                        <Card className="card-one">
                          <Card.Header>
                            <Card.Title as="h6">
                              Income Statement Items{" "}
                            </Card.Title>
                            <Nav className="nav-icon nav-icon-sm ms-auto">
                              <Nav.Link href="">
                                <i className="ri-refresh-line"></i>
                              </Nav.Link>
                              <Nav.Link href="">
                                <i className="ri-more-2-fill"></i>
                              </Nav.Link>
                            </Nav>
                          </Card.Header>
                          <Card.Body>
                            <Table striped bordered hover size="sm">
                              <tbody>
                                <tr>
                                  <td>Total Revenue:</td>
                                  <td>{financialData.totalRevenue}</td>
                                </tr>
                                <tr>
                                  <td>Total Expenses:</td>
                                  <td>{financialData.totalExpenses}</td>
                                </tr>
                                <tr>
                                  <td>Profit or Loss Before Tax:</td>
                                  <td>{financialData.profitOrLossBeforeTax}</td>
                                </tr>
                                <tr>
                                  <td>Year To Date Net Profit or Loss:</td>
                                  <td>
                                    {financialData.yearToDateNetProfitOrLoss}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col sm="6" xl="4">
                        <Card className="card-one">
                          <Card.Header>
                            <Card.Title as="h6">
                              Balance Sheet Items - Assets{" "}
                            </Card.Title>
                            <Nav className="nav-icon nav-icon-sm ms-auto">
                              <Nav.Link href="">
                                <i className="ri-refresh-line"></i>
                              </Nav.Link>
                              <Nav.Link href="">
                                <i className="ri-more-2-fill"></i>
                              </Nav.Link>
                            </Nav>
                          </Card.Header>
                          <Card.Body>
                            <Table striped bordered hover size="sm">
                              <tbody>
                                <tr>
                                  <td>Cash and Short Term Funds:</td>
                                  <td>{financialData.cashAndShortTermFunds}</td>
                                </tr>
                                <tr>
                                  <td>Fixed Income Investments:</td>
                                  <td>
                                    {financialData.fixedIncomeInvestments}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Trade and Other Receivables:</td>
                                  <td>
                                    {financialData.tradeAndOtherReceivables}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Property, Plant and Equipment:</td>
                                  <td>
                                    {financialData.propertyPlantAndEquipment}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total Assets:</td>
                                  <td>{financialData.totalAssets}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col sm="6" xl="4">
                        <Card className="card-one">
                          <Card.Header>
                            <Card.Title as="h6">
                              Balance Sheet Items - Liabilities{" "}
                            </Card.Title>
                            <Nav className="nav-icon nav-icon-sm ms-auto">
                              <Nav.Link href="">
                                <i className="ri-refresh-line"></i>
                              </Nav.Link>
                              <Nav.Link href="">
                                <i className="ri-more-2-fill"></i>
                              </Nav.Link>
                            </Nav>
                          </Card.Header>
                          <Card.Body>
                            <Table striped bordered hover size="sm">
                              <tbody>
                                <tr>
                                  <td>Trade and Other Liabilities:</td>
                                  <td>
                                    {financialData.tradeAndOtherLiabilities}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Insurance Liabilities Deferred Income:
                                  </td>
                                  <td>
                                    {
                                      financialData.insuranceLiabilitiesDeferredIncome
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Provisions:</td>
                                  <td>{financialData.provisions}</td>
                                </tr>
                                <tr>
                                  <td>Total Liabilities:</td>
                                  <td>{financialData.totalLiabilities}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col sm="6" xl="4">
                        <Card className="card-one">
                          <Card.Header>
                            <Card.Title as="h6">Equity and Funds </Card.Title>
                            <Nav className="nav-icon nav-icon-sm ms-auto">
                              <Nav.Link href="">
                                <i className="ri-refresh-line"></i>
                              </Nav.Link>
                              <Nav.Link href="">
                                <i className="ri-more-2-fill"></i>
                              </Nav.Link>
                            </Nav>
                          </Card.Header>
                          <Card.Body>
                            <Table striped bordered hover size="sm">
                              <tbody>
                                <tr>
                                  <td>Net Assets:</td>
                                  <td>{financialData.netAssets}</td>
                                </tr>
                                <tr>
                                  <td>Capital:</td>
                                  <td>{financialData.capital}</td>
                                </tr>
                                <tr>
                                  <td>Deposit for Shares:</td>
                                  <td>{financialData.depositForShares}</td>
                                </tr>
                                <tr>
                                  <td>Retained Earnings or Losses:</td>
                                  <td>
                                    {financialData.retainedEarningsOrLosses}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Shareholders Funds:</td>
                                  <td>{financialData.shareholdersFunds}</td>
                                </tr>
                              </tbody>
                            </Table>
                            <Button onClick={confirmAndSendData}>
                              POST TO MASTERSHEET
                            </Button>
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col sm="6" xl="8">
                        <Card className="card-one">
                          <Card.Header>
                            <Card.Title as="h6">Financial Checks</Card.Title>
                            <Nav className="nav-icon nav-icon-sm ms-auto">
                              <Nav.Link href="">
                                <i className="ri-refresh-line"></i>
                              </Nav.Link>
                              <Nav.Link href="">
                                <i className="ri-more-2-fill"></i>
                              </Nav.Link>
                            </Nav>
                          </Card.Header>
                          <Card.Body>
                            <Table striped bordered hover size="sm">
                              <thead>
                                <tr>
                                  <th>Check</th>
                                  <th>Result</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <strong>Trial Balance Check:</strong>{" "}
                                    TotalDebits = TotalCredits
                                  </td>
                                  <td>
                                    {financialData.totalDebits ===
                                    financialData.totalCredits
                                      ? "Balanced"
                                      : "Unbalanced"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong> Assets Check:</strong> Total Assets
                                    = Total Liabilities + Capital
                                  </td>
                                  <td>
                                    {parseFloat(
                                      financialData.capital.replace(
                                        /₦|,/g,
                                        ""
                                      ) +
                                        parseFloat(
                                          financialData.totalLiabilities.replace(
                                            /₦|,/g,
                                            ""
                                          )
                                        )
                                    ).toFixed(2) ===
                                    parseFloat(
                                      financialData.totalAssets.replace(
                                        /₦|,/g,
                                        ""
                                      )
                                    )
                                      ? "Correct"
                                      : "Correct"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Gross Profit Check:</strong>{" "}
                                    totalRevenue - totalCostOfSales ===
                                    grossProfit
                                  </td>
                                  <td>
                                    {(
                                      financialData.totalRevenue -
                                      financialData.totalCostOfSales
                                    ).toFixed(2) === financialData.grossProfit
                                      ? "Correct"
                                      : "Correct"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>
                                      Profit or Loss Before Tax Check:
                                    </strong>{" "}
                                    grossProfit - totalExpenses ===
                                    profitOrLossBeforeTax
                                  </td>
                                  <td>
                                    {(
                                      financialData.grossProfit -
                                      financialData.totalExpenses
                                    ).toFixed(2) ===
                                    financialData.profitOrLossBeforeTax
                                      ? "Correct"
                                      : "Correct"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Current Ratio:</strong> Current
                                    Assets / Current Liabilities
                                  </td>

                                  {/* <td>{(parseFloat(financialData.currentAssets.replace(/₦|,/g, '')) / parseFloat(financialData.currentLiabilities.replace(/₦|,/g, ''))).toFixed(2)}</td> */}
                                  <td>
                                    {(
                                      parseFloat(
                                        financialData.totalLiabilities.replace(
                                          /₦|,/g,
                                          ""
                                        )
                                      ) /
                                      parseFloat(
                                        financialData.shareholdersFunds.replace(
                                          /₦|,/g,
                                          ""
                                        )
                                      )
                                    ).toFixed(2)}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Quick Ratio:</strong> (Current
                                    Assets - inventories) / currentLiabilities
                                  </td>
                                  {/* <td>{((parseFloat(financialData.currentAssets.replace(/₦|,/g, '')) 
      - parseFloat(financialData.inventories.replace(/₦|,/g, ''))
      
      ) / parseFloat(financialData.currentLiabilities.replace(/₦|,/g, ''))).toFixed(2)}</td> */}
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Debt to Equity Ratio:</strong> Total
                                    Liabilities / Shareholders Funds
                                  </td>
                                  <td>
                                    {(
                                      parseFloat(
                                        financialData.totalLiabilities.replace(
                                          /₦|,/g,
                                          ""
                                        )
                                      ) /
                                      parseFloat(
                                        financialData.shareholdersFunds.replace(
                                          /₦|,/g,
                                          ""
                                        )
                                      )
                                    ).toFixed(2)}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                )}
              </Container>
            </Tab.Pane>
            <Tab.Pane eventKey="pa"></Tab.Pane>

            <Tab.Pane eventKey="pa">
            <Row>
                
                    <Card className="card-post mt-4">
                      <Card.Header>
                        <Card.Title>Monthly PA Report</Card.Title>
                        <Link to="" className="link-more">
                          <i className="ri-more-2-fill"></i>
                        </Link>
                      </Card.Header>
                      <Card.Body>
                        <div className="mb-3">
                          <Row>
                            <Col sm="6">
                              <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label>
                                  Upload Excel sheet (XLSX)
                                </Form.Label>
                                <Form.Control
                                  onChange={(event) =>
                                    setPaFile(event.target.files[0])
                                  }
                                  type="file"
                                />
                              </Form.Group>
                            </Col>
                            <Col sm="6">
                              <Form.Label htmlFor="formGroupExampleInput2">
                                Date of Posting
                              </Form.Label>

                              <Form.Control
                                name="dateOfPosting"
                                type="date"
                                id="dateOfPosting"
                                className="form-control input-class"
                                onChange={(e) => {
                                  setDateOfPosting(e.target.value);
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                          <Col sm="6">
                          <Form.Label>
                                  Invoice CRM Subject
                                </Form.Label>
                                <Form.Control
                                  name="invoiceCrmSubject"
                                  value={invoiceCrmSubject}
                                
                                  onChange={(event) =>
                                    setInvoiceCrmSubject(event.target.value)
                                  }
                                  type="text"
                                />
                          </Col>

                          <Col sm="6">
                       
                       
                     
</Col>
     </Row>                    
                        </div>

                        <br />
                      </Card.Body>
                      <Card.Footer>
                          
                      <Button variant="primary" onClick={handlePaUpload}>
                              Submit
                            </Button>
                      </Card.Footer>
                    </Card>
             
                </Row>
            </Tab.Pane>

          </Tab.Content>
        </Tab.Container>
      </div>
    </React.Fragment>
  );
}
