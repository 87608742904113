import React from "react";
import { Card } from "react-bootstrap";
import { Grid } from "gridjs-react";
import { _ } from "gridjs-react";
import { useState } from "react";

export default function ClaimsResult(props) {
  const data = props.data;

  const [claimsData, setClaimsData] = useState(data);

  return (
    <React.Fragment>
      <h5 id="section13" className="main-subtitle">
        Overdue Claims
      </h5>
      <Card className="card-example">
        <Card.Body>
          <Grid
            data={claimsData}
            columns={[
              "Batch Number",
    "Claims Type",
    "Provider Name",
    "Provider Billed Amount",
    "Year",
    "Month",
    "Date Added",
    "Level Description",
            ]}
            search={true}
            pagination={true}
            sort={true}
            resizable={true}
            className={{
              table: "table table-bordered mb-0",
            }}
          />
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
    </React.Fragment>
  );
}
