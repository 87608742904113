import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Card, Col, Table, Modal, Row,Nav } from "react-bootstrap";
import HeaderMobile from "../../../layouts/HeaderMobile";
import { formatRevenue, calculateMetricsData} from "../../../logic/Utils";
import Sidebar from "../../../layouts/Sidebar";
import Swal from "sweetalert2";
import { Bar } from "react-chartjs-2";
import { AnalyticsService } from "../../../service/AnalyticsService";

export default function MlrView(props) {
  const { closeModal, mlrRecord,isProvider, isPlan } = props;

 
  const monthOrder = {
    JANUARY: 1,
    FEBRUARY: 2,
    MARCH: 3,
    APRIL: 4,
    MAY: 5,
    JUNE: 6,
    JULY: 7,
    AUGUST: 8,
    SEPTEMBER: 9,
    OCTOBER: 10,
    NOVEMBER: 11,
    DECEMBER: 12,
  };
  
  
  // Safely access metricsDataMap
  const metricsDataMap = mlrRecord.metricsDataMap || {};
  const encounterMap = mlrRecord.encounterMaps || {};

  // Calculate chart data only if metricsDataMap is not empty
  const mlrChartData = Object.keys(metricsDataMap).length > 0 ? calculateMetricsData(metricsDataMap, "mlr") : null;
  const claimsChartData = Object.keys(metricsDataMap).length > 0 ? calculateMetricsData(metricsDataMap, "claims") : null;
  const encountersChartData = Object.keys(metricsDataMap).length > 0 ? calculateMetricsData(metricsDataMap, "encounters") : null;

  // Sorting and preparing data only if metricsDataMap is not empty
  // const months = Object.keys(metricsDataMap).sort((a, b) => monthOrder[a] - monthOrder[b]);
  const months = Object.keys(metricsDataMap).sort((a, b) => {
    const upperA = a.toUpperCase();
    const upperB = b.toUpperCase();
    return (monthOrder[upperA] || 13) - (monthOrder[upperB] || 13);
  });
  const metricKeys = months.length > 0 ? Object.keys(metricsDataMap[months[0]]) : [];


// const months = Object.keys(mlrRecord.metricsDataMap).sort((a, b) => monthOrder[a] - monthOrder[b]);
 
//   const metricKeys = Object.keys(mlrRecord.metricsDataMap[months[0]]);

  useEffect(() => {
    Prism.highlightAll();
    //authorize entry
  }, []);

  const [lgShow, setLgShow] = useState(true);
  const options = {
    scales: {
      x: { stacked: false },
      y: { stacked: false }
    }
  };

  return (
    <React.Fragment>
      <Sidebar />
      <HeaderMobile />
      <div className="main main-docs">
        <Modal show={lgShow} onHide={closeModal} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>{mlrRecord.providerBased===true?mlrRecord.providerName:mlrRecord.isPlan?mlrRecord.planType:mlrRecord.clientName} </Modal.Title>
            {/* <Modal.Title>{mlrRecord.isPlan?mlrRecord.planType:""} </Modal.Title> */}
            
          </Modal.Header>
          <Modal.Body>
          <Row className="g-3">
          <>


<Col xs="12" xl="4"> 
   <Card className="card-one">
     <Card.Body>
       <Card.Title as="label" className="fs-sm fw-medium mb-1">
        MLR
       </Card.Title>
       <h3 className="card-value mb-1">
         <i className="ri-briefcase-4-line"></i> {mlrRecord.mlr}
       </h3>
    
     </Card.Body>
   </Card>
 </Col>
 <Col xs="12" xl="4">
   <Card className="card-one">
     <Card.Body>
       <Card.Title as="label" className="fs-sm fw-medium mb-1">
         Total Claims Generated
       </Card.Title>
       <h3 className="card-value mb-1">
         <i className="ri-briefcase-4-line"></i> {formatRevenue(mlrRecord.totalClaims)}
      
       </h3>
     </Card.Body>
   </Card>
 </Col>
 <Col xs="12" xl="4">
   <Card className="card-one">
   <Card.Body>
       <Card.Title as="label" className="fs-sm fw-medium mb-1">
        Total Revenue for the Period
       </Card.Title>
       <h3 className="card-value mb-1">
         <i className="ri-briefcase-4-line"></i> {formatRevenue(mlrRecord.totalRevenue)}
       </h3>
      
     </Card.Body>
   </Card>
 </Col>
 <Col xs="12" xl="4">
   <Card className="card-one">
    
     <Card.Body>
       <Card.Title as="label" className="fs-sm fw-medium mb-1">
         Total Encounter Count
       </Card.Title>
       <h3 className="card-value mb-1">
         <i className="ri-briefcase-4-line"></i> {mlrRecord.totalEncounterCount}
       </h3>
      
     </Card.Body>
   </Card>
 </Col>
 <Col xs="12" xl="4">
   <Card className="card-one">
     <Card.Body>
       <Card.Title as="label" className="fs-sm fw-medium mb-1">
        Average Claims Per Encounter
       </Card.Title>
       <h3 className="card-value mb-1">
         <i className="ri-briefcase-4-line"></i> {formatRevenue(mlrRecord.averageClaimsPerEncounter)}
       </h3>
      
     </Card.Body>
   </Card>
 </Col>
 <Col xs="12" xl="4">
   <Card className="card-one">
     <Card.Body>
       <Card.Title as="label" className="fs-sm fw-medium mb-1">
        Revenue per Month
       </Card.Title>
       <h3 className="card-value mb-1">
         <i className="ri-briefcase-4-line"></i> {formatRevenue(mlrRecord.revenuePerMonth)}
       </h3>
      
     </Card.Body>
   </Card>
 </Col>
</>


{/* BarChart */}


<Col xl="6">
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">
                  Mlr Chart 
                  </Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body>
                  {mlrChartData != null && (
                    <Bar
                      data={mlrChartData}
                      options={options}
                      className="ht-300"
                    />
                  )}
                </Card.Body>
              
              </Card>
            </Col>
            <Col xl="6">
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">
                    Claims Chart 
                  </Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body>
                  {claimsChartData != null && (
                    <Bar
                      data={claimsChartData}
                      options={options}
                      className="ht-300"
                    />
                  )}
                </Card.Body>
              
              </Card>
            </Col>
            <Col xl="6">
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">
                    Encounter Chart 
                  </Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body>
                  {encountersChartData != null && (
                    <Bar
                      data={encountersChartData}
                      options={options}
                      className="ht-300"
                    />
                  )}
                </Card.Body>
              
              </Card>
            </Col>
           


            {
  (!isProvider && !isPlan) && (
            <Col md="6" xl="6">
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">
                    {" "}
                     Table Breakdown 
                  </Card.Title>
                  <Nav as="nav" className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body>
                {mlrRecord.metricsDataMap != null && (
                <Table striped bordered hover responsive>
                <thead>
        <tr>
          <th>Month</th>
          {metricKeys.map((metric) => (
            <th key={metric}>{metric}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {months.map((month) => (
          <tr key={month}>
            <td>{month}</td>
            <td>{formatRevenue(mlrRecord.metricsDataMap[month].claimsAmount)}</td>
            <td>{formatRevenue(mlrRecord.metricsDataMap[month].revenueAmount)}</td>
            <td>{mlrRecord.metricsDataMap[month].encounterCount}</td>
            <td>{formatRevenue(mlrRecord.metricsDataMap[month].claimsPerEncounter)}</td>
            <td>{formatRevenue(mlrRecord.metricsDataMap[month].revenuePerEncounter)}</td>
            <td>{mlrRecord.metricsDataMap[month].mlr}</td>
            <td>{mlrRecord.metricsDataMap[month].commission}</td>
            <td>{mlrRecord.metricsDataMap[month].vcr}</td>
          </tr>
        ))}
      </tbody>
    </Table>
                )}
                </Card.Body>
              </Card>
            </Col>
  )}

{
  (isProvider || isPlan)  && (
    <Col md="6" xl="6">
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">
                    {" "}
                     Encounter Breakdown 
                  </Card.Title>
                  <Nav as="nav" className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body>
                {mlrRecord.encounterMaps != null && (
                <Table striped bordered hover responsive>
                
                
                <thead>
          <tr>
          <th>Client Name</th>
            <th>Claims Amount</th>
            <th>Revenue Spread</th>
            <th>Revenue Amount</th>
            <th>Encounter Count</th>
          </tr>
        </thead>
        <tbody>
          {mlrRecord.encounterMaps.map((item, index) => (
            <tr key={index}>
            <td>{item.clientName}</td>
              <td>{formatRevenue(item.claimsAmount)}</td>
              <td>{formatRevenue(item.clientRevenueSpread)}</td>
              <td>{formatRevenue(item.revenueAmount)}</td>
              <td>{item.encounterCount}</td>
            </tr>
          ))}
        </tbody>
    </Table>
                )}
                </Card.Body>
              </Card>
            </Col>
  )
}


          </Row>





{/* TableBreakdown */}



          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
}
