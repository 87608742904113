import React, {useContext, useEffect} from "react";
import Prism from "prismjs";
import {Button, Modal, Image} from "react-bootstrap";
import HeaderMobile from "../layouts/HeaderMobile";
import failure from "../assets/img/failure-icon.png"
import TaskContext from "../context/TaskContext";
import {useNavigate} from "react-router-dom";
export default function PasswordFailure(props) {

    const message = props.message;

    const { modalState, updateModalState, } = useContext(TaskContext);

    const navigate = useNavigate();

    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const handleClose2 = () => {

        updateModalState(false);

        navigate("/login", { replace: true });

    };

    useEffect(() => {
        Prism.highlightAll();
    }, []);


    return (
        <React.Fragment>
            <HeaderMobile />
            <div className="main main-docs">
                <Modal show={modalState}  onHide={handleClose2} centered size="sm">
                    <Modal.Header closeButton>
                        {/*<Modal.Title>Modal heading</Modal.Title>*/}
                    </Modal.Header>
                    <Modal.Body>
                        <Image src={failure} fluid={true} alt="..." />
                        <h3 className="text-center">{message}</h3>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose2}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>

        </React.Fragment>
    );
}