import React, { useEffect, useState } from 'react';
import { Dropdown, Nav, Form } from 'react-bootstrap';
import { TeamService } from '../service/TeamService';


const TeamDropdown = ({ handleItemClick }) => {
  const [teamsData, setTeamsData] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredTeams, setFilteredTeams] = useState([]);

  useEffect(() => {
    TeamService.getTeamInfoByManager(localStorage.getItem('token'))
      .then((res) => {
        
        setTeamsData(res.data.result.memberProfiles);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    // Logic for filtering teams based on the search term
    setFilteredTeams(
      teamsData.filter(
        (team) =>
          `${team.firstName} ${team.lastName}`
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, teamsData]);

  const handleTeamClick = (team) => {
    setSelectedTeam(team);
    handleItemClick(team);
  };

  return (
    <Nav className="nav-classic">
      <Dropdown>
        <Dropdown.Toggle variant="primary" id="team-dropdown">
          {selectedTeam
            ? `${selectedTeam.firstName} ${selectedTeam.lastName}`
            : 'Select Team Member'}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Form>
            <Form.Group controlId="searchForm">
              <Form.Control
                type="text"
                placeholder="Search for a Team Member"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Form.Group>
          </Form>
          {filteredTeams.map((team, index) => (
            <Dropdown.Item key={index} onClick={() => handleTeamClick(team)}>
              {team.firstName} {team.lastName}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Nav>
  );
};

export default TeamDropdown;
