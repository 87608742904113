import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Prism from "prismjs";
import HeaderMobile from "../layouts/HeaderMobile";
import {Image, Modal} from "react-bootstrap";
import success from "../assets/img/success_icon.png";
import Signin from "../pages/Signin";

export default function SigninModal(props) {


    const modalStyle = {
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the opacity (0.5) to make it semi-transparent
        boxShadow: "none", // Remove the box shadow
      };

    const destination = props.destination;

    const [showModal, setShowModal] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const handleClose2 = () => {

        setShowModal(false)
        window.location.reload();

        // navigate(destination, { replace: true });

    };



    return (
        <React.Fragment>
            <HeaderMobile />
            <div className="main main-docs">
                <Modal show={showModal}  centered size="m"
                
                
                      style={modalStyle} // Apply the inline CSS here
      >
                    <Modal.Header closeButton>
                        {/*<Modal.Title>Modal heading</Modal.Title>*/}
                    </Modal.Header>
                    <Modal.Body>
                       <Signin successAction = {handleClose2}/>
                    </Modal.Body>
                    <Modal.Footer >
                        {/*<Button variant="secondary" onClick={handleClose2}>*/}
                        {/*    Close*/}
                        {/*</Button>*/}
                    </Modal.Footer>
                </Modal>
            </div>

        </React.Fragment>
    );
}