import React, {useEffect, useState} from "react";
import Prism from "prismjs";
import {Button, Card, Col, Form, Modal, Spinner} from "react-bootstrap";
import HeaderMobile from "../layouts/HeaderMobile";
import {useNavigate} from 'react-router-dom';
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import Sidebar from "../layouts/Sidebar";
import {TeamService} from "../service/TeamService";
import {ProjectService} from "../service/ProjectService";
import SuccessModal from "./SuccessModal";
import FailureModal from "./FailureModal";
import BackgroundProjects from "../pages/BackgroundProjects";

export default function CreateProject() {
    useEffect(() => {
        Prism.highlightAll();
    }, []);


    const [lgShow, setLgShow] = useState(true);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [description, setDescription] = useState("");
    const [name, setName] = useState("");
    const [assignees, setAssignees] = useState([]);
    const [teamsData, setTeamsData] = React.useState([]);
    const navigate = useNavigate();
    const lgHandleClose = () => {
        setLgShow(false);
        // navigate('/projects'); // Navigate to "/apps/tasks" upon modal closure
    };
    const currentDate = new Date();
    useEffect(() => {


        TeamService.getTeamInfoByManager(localStorage.getItem("token"))
            .then((res) => {
                setTeamsData(res.data.result.memberProfiles);
            })
            .catch((err) => {
                console.log(err);

            });


    }, [setTeamsData]);
    const projectDto = {
        name,
        description,
        startDate,
        endDate,
        assignees,
    };
    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     set(prevData => ({
    //         ...prevData,
    //         [name]: value
    //     }));
    // };
    const selectOptions = teamsData.map((member, index) => ({
        value: member.email,
        label: member.firstName + " " + member.lastName,
    }));
    const [logSuccess, setLogSuccess] = React.useState(false)
    const [logError, setLogError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false);
    const changeParentModlal = () => {
        setLogError(false);


    };

    const handleSubmit = (e) => {

        setIsLoading(true);
        e.preventDefault();
        // Perform the task creation logic here
        ProjectService.createProject(projectDto, localStorage.getItem("token"))
            .then((response) => {
                // Handle successful task creation
                if (response.status === 201) {
                    lgHandleClose()

                    setIsLoading(false);
                    setLogSuccess(true);

                }



            })
            .catch((error) => {
                    setLogError(true)
                setIsLoading(false);
                    setErrorMessage(error.response.data.result)
                    console.log(error);
                }
            );
        // Close the modal
        lgHandleClose();
    };


    return (
        <React.Fragment>
            <Sidebar/>
            <HeaderMobile/>
            <BackgroundProjects/>

            <div className="main main-docs">

                <Modal show={lgShow} onHide={lgHandleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Create a Project</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Card>
                            <Card.Body>
                                <div className="mb-3">
                                    <Form.Label htmlFor="formGroupExampleInput">Project Title</Form.Label>
                                    <Form.Control onChange={(event) => setName(event.target.value)} type="text"
                                                  id="formGroupExampleInput"
                                                  placeholder="Project Title "/>
                                </div>
                                <div className="mb-3">
                                    <Form.Label htmlFor="formGroupExampleInput2">Project Description</Form.Label>
                                    <Form.Control onChange={(event) => setDescription(event.target.value)} as="textarea"
                                                  id="exampleFormControlTextarea1" rows="3"
                                                  placeholder="Enter message here..."></Form.Control>
                                </div>
                                <div className="mb-3">
                                    <Col sm="6">
                                        <Form.Label htmlFor="formGroupExampleInput2">Start Date</Form.Label>
                                        <ReactDatePicker
                                            minDate={currentDate}
                                            placeholderText="Select a starting date"
                                            selected={startDate} onChange={(date) => setStartDate(date)}
                                                         className="form-control"/>
                                    </Col>
                                </div>
                                <div className="mb-3">
                                    <Col sm="6">
                                        <Form.Label htmlFor="formGroupExampleInput2">End Date</Form.Label>
                                        <ReactDatePicker selected={endDate}
                                                         minDate={currentDate}
                                                            placeholderText="Select an ending date"
                                                         onChange={(endDate) => setEndDate(endDate)}
                                                         className="form-control"/>
                                    </Col>
                                </div>
                                <div className="mb-3">
                                    <Form.Label htmlFor="formGroupExampleInput2">Assign to Who?</Form.Label>
                                    {/* Conditional rendering for the Select component */}
                                    {selectOptions.length > 0 ? (
                                        <Select
                                            placeholder={"You can assign to multiple people"}
                                            onChange={(selectedOptions) => {
                                                // Extract the array of selected values from the selectedOptions object
                                                const selectedValues = selectedOptions.map((option) => option.value);
                                                setAssignees(selectedValues); // Update the assignees state with an array of selected values
                                            }}
                                            options={selectOptions}
                                            isMulti={true}
                                            isSearchable={true}
                                        />
                                    ) : (
                                        <p>Loading Team Members...</p>
                                    )}
                                </div>
                            </Card.Body>

                            <Card.Footer>
                            </Card.Footer>
                            <Form onSubmit={handleSubmit}/>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={lgHandleClose}>
                            Close
                        </Button>
                        {!isLoading && ( <Button variant="primary" onClick={handleSubmit}>
                            Create Project
                        </Button>)}
                        {isLoading && (
                            <Button  className="btn-sign">
                                Processing...<span/><Spinner  size="sm" animation="border" /></Button>
                        )}
                    </Modal.Footer>
                </Modal>
                {logSuccess && (
                    <SuccessModal message={"Project creation was successful"} destination={"/projects"}/>
                )}
                {logError && (
                    <FailureModal message={errorMessage} action={changeParentModlal}/>
                )}

            </div>

        </React.Fragment>
    );

}