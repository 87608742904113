import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Card, Col, Table, Modal, Row } from "react-bootstrap";
import HeaderMobile from "../../../layouts/HeaderMobile";
import { formatRevenue, } from "../../../logic/Utils";
import Sidebar from "../../../layouts/Sidebar";
import Swal from "sweetalert2";

import { AnalyticsService } from "../../../service/AnalyticsService";

export default function SalesRecordView(props) {
  const closeModal = props.closeModal;
  const salesRecord = props.salesRecord;
  useEffect(() => {
    Prism.highlightAll();
    //authorize entry
  }, []);

  const [lgShow, setLgShow] = useState(true);


  return (
    <React.Fragment>
      <Sidebar />
      <HeaderMobile />
      <div className="main main-docs">
        <Modal show={lgShow} onHide={closeModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Sales Record Details </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card>
              <Card.Body>
              <Table striped bordered hover responsive>
          <tbody>
            <tr style={{ backgroundColor: '#f2f2f2' }}>
              <td>Name of Client/Customer</td>
              <td>{salesRecord.nameOfClient}</td>
            </tr>
            <tr>
              <td>Date of Sale</td>
              <td>{salesRecord.dateOfSale}</td>
            </tr>
            <tr style={{ backgroundColor: '#f2f2f2' }}>
              <td>Total Value (Naira)</td>
              <td>{formatRevenue(salesRecord.revenueAmount)}</td>
            </tr>
            <tr>
              <td>Total Unit Sales</td>
              <td>{salesRecord.totalPopulationCount}</td>
            </tr>
            <tr>
              <td>PPP Value (Naira)</td>
              <td>{formatRevenue(salesRecord.pppValueAmount)}</td>
            </tr>
            <tr>
              <td>Unit Sales for PPP</td>
              <td>{salesRecord.pppPopulationCount}</td>
            </tr>


            <tr>
              <td>Metro Maxi Value (Naira)</td>
              <td>{formatRevenue(salesRecord.metromaxiValueAmount)}</td>
            </tr>
            <tr>
              <td>Unit Sales for Metro Maxi</td>
              <td>{salesRecord.metromaxiPopulationCount}</td>
            </tr>


            <tr>
              <td>Metro Midi Value (Naira)</td>
              <td>{formatRevenue(salesRecord.metromidiValueAmount)}</td>
            </tr>
            <tr>
              <td>Unit Sales for Metro Midi</td>
              <td>{salesRecord.metromidiPopulationCount}</td>
            </tr>

            
            <tr>
              <td>Metro Mini Value (Naira)</td>
              <td>{formatRevenue(salesRecord.metrominiValueAmount)}</td>
            </tr>
            <tr>
              <td>Unit Sales for Metro Mini</td>
              <td>{salesRecord.metrominiPopulationCount}</td>
            </tr>

            <tr>
              <td>Flexi Value (Naira)</td>
              <td>{formatRevenue(salesRecord.flexiValueAmount)}</td>
            </tr>
            <tr>
              <td>Unit Sales for Flexi</td>
              <td>{salesRecord.flexiPopulationCount}</td>
            </tr>


            <tr>
              <td>NHIA Value (Naira):</td>
              <td>{formatRevenue(salesRecord.nhiaValueAmount)}</td>
            </tr>
            <tr>
              <td>Unit Sales for NHIA</td>
              <td>{salesRecord.nhiaPopulationCount}</td>
            </tr>
            <tr>
              <td>Sold By</td>
              <td>{salesRecord.salesOfficerFullName}</td>
            </tr>
          </tbody>
        </Table>
                

              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
}
