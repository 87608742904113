import React, {useContext, useEffect} from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import {Badge, Card, Col, Nav, ProgressBar, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import SingleTask from "../components/SingleTask";
import TaskContext from "../context/TaskContext";
import {TaskService} from "../service/TaskService";
import Sidebar from "../layouts/Sidebar";


export default function ManagerInActiveAssignedTasks() {
    useEffect(() => {
        document.body.classList.add('app-task')
        return () => {
            document.body.classList.remove('app-task')
        }
    }, []);


    const {selectedTask, updateTask} = useContext(TaskContext);
    const {updateModalState} = useContext(TaskContext);
    const [overdueTasks, setOverdueTasks] = React.useState([]);
    const [completedTasks, setCompletedTasks] = React.useState([]);
    const [extensionTasks, setExtensionTasks] = React.useState([]);


    function handleTaskClick(task) {

        // pass the task to the context
        updateTask(task);
        //open modal
        updateModalState(true);
    }

    useEffect(() => {
        TaskService.getAllOverdueTasksOfAssigner(localStorage.getItem("token"))
            .then((res) => {
                setOverdueTasks(res.data.result);
            })
            .catch((err) => {
                console.log(err);
            });

        TaskService.getAllCompletedTasksOfAssigner(localStorage.getItem("token"))
            .then((res) => {
                setCompletedTasks(res.data.result);
            })
            .catch((err) => {
                console.log(err);
            });

        TaskService.getAllExtensionRequestedTasksOfAssigner(localStorage.getItem("token"))
            .then((res) => {
                setExtensionTasks(res.data.result);
            })
            .catch((err) => {
                console.log(err);
            });


    }, [setCompletedTasks, setExtensionTasks, setOverdueTasks]);




    return (
        <React.Fragment>
            <Sidebar />
            <Header />
            <div className="main main-app p-3 p-lg-4 p-xxl-5">
                <Row className="row-cols-auto g-4 g-xxl-5">
                    <Col xl>
                        <h2 className="main-title fs-4 mb-1">Welcome to your Task Manager!</h2>
                        <p className="text-secondary mb-4">Streamline your Workflow with Ease</p>

                        <Nav className="nav-line mb-4">
                            <Nav.Link href="" className="nav-link active" >
                                Your Inactive Tasks
                            </Nav.Link>
                        </Nav>
                        <Row className="g-3 g-lg-4">


                            <Col md>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <label className="task-label">Overdue Tasks</label>
                                    <div className="dropdown dropdown-task">
                                        <Link to="" className="dropdown-link">Date Added <i
                                            className="ri-arrow-down-s-line"></i></Link>
                                    </div>
                                </div>

                                {/*in progress tasks*/}
                                {overdueTasks.map((task, index) => (
                                    <Card className="card-task" key={index} onClick={() => handleTaskClick(task)}>
                                        <Card.Body className="p-3 pb-1">
                                            {/* Render task data */}
                                            <div className="d-flex gap-1">
                                                {/*  {task.taskType}  */}
                                                {/*{task.badge.map((badge, ind) => (*/}
                                                <Badge key="0" bg="success">
                                                    {task.taskType}
                                                </Badge>
                                                {/*))}*/}
                                            </div>
                                            <div
                                                className="d-flex flex-row-reverse align-items-center justify-content-between mt-2 mb-1">
                                                <span className="card-date">{task.dueDate}</span>
                                                <Card.Title as="h6">{task.name}</Card.Title>
                                            </div>
                                            <p className="fs-xs">
                                                Assigned to: <Link to="">{task.assignees}</Link>
                                            </p>
                                            {task.text && <p className="fs-sm">{task.taskDescription}</p>}

                                            <div
                                                className="d-flex align-items-center justify-content-between fs-xs text-secondary mb-1">
              <span>
                <strong className="fw-medium">{task.progress}%</strong> complete
              </span>
                                                <span>{task.hour}</span>
                                            </div>
                                            <ProgressBar now={task.progress} className="mb-2"/>
                                        </Card.Body>
                                        <Card.Footer className="p-3 pt-0">
                                            <p className="fs-xs">
                                                Assigned by: <Link to="">{task.creator}</Link>
                                            </p>
                                            <Nav as="nav">
                                                <Nav.Link href="">
                                                    <i className="ri-eye-2-line"></i> {task.views}
                                                </Nav.Link>
                                                <Nav.Link href="">
                                                    <i className="ri-chat-1-line"></i> {task.comments}
                                                </Nav.Link>
                                            </Nav>
                                        </Card.Footer>

                                    </Card>
                                ))}


                            </Col>
                            <Col md>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <label className="task-label">Tasks Marked For Extension</label>
                                    <div className="dropdown dropdown-task">
                                        <Link to="" className="dropdown-link">Date Added <i
                                            className="ri-arrow-down-s-line"></i></Link>
                                    </div>
                                </div>
                                {/*new tasks*/}
                                {extensionTasks.map((task, index) => (
                                    <Card className="card-task" key={task.taskId} onClick={() => handleTaskClick(task)}>
                                        <Card.Body className="p-3 pb-1">
                                            {/* Render task data */}
                                            <div className="d-flex gap-1">
                                                {/*  {task.taskType}  */}
                                                {/*{task.badge.map((badge, ind) => (*/}
                                                <Badge key="0" bg="success">
                                                    {task.taskType}
                                                </Badge>
                                                {/*))}*/}
                                            </div>
                                            <div
                                                className="d-flex flex-row-reverse align-items-center justify-content-between mt-2 mb-1">
                                                <span className="card-date">{task.dueDate}</span>
                                                <Card.Title as="h6">{task.name}</Card.Title>
                                            </div>
                                            <p className="fs-xs">
                                                Assigned to: <Link to="">{task.assignees}</Link>
                                            </p>
                                            {task.text && <p className="fs-sm">{task.taskDescription}</p>}

                                            <div
                                                className="d-flex align-items-center justify-content-between fs-xs text-secondary mb-1">
              <span>
                <strong className="fw-medium">{task.progress}%</strong> complete
              </span>
                                                <span>{task.hour}</span>
                                            </div>
                                            <ProgressBar now={task.progress} className="mb-2"/>
                                        </Card.Body>
                                        <Card.Footer className="p-3 pt-0">
                                            <p className="fs-xs">
                                                Assigned by: <Link to="">{task.creator}</Link>
                                            </p>
                                            <Nav as="nav">
                                                <Nav.Link href="">
                                                    <i className="ri-eye-2-line"></i> {task.views}
                                                </Nav.Link>
                                                <Nav.Link href="">
                                                    <i className="ri-chat-1-line"></i> {task.comments}
                                                </Nav.Link>
                                            </Nav>
                                        </Card.Footer>
                                    </Card>
                                ))}

                            </Col>
                            <Col md className="mt-5 mt-md-3 mt-lg-4">
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <label className="task-label">Completed Tasks</label>
                                    <div className="dropdown dropdown-task">
                                        <Link to="" className="dropdown-link">Date Added <i
                                            className="ri-arrow-down-s-line"></i></Link>
                                    </div>
                                </div>

                                {/*pending approval*/}
                                {completedTasks.map((task, index) => (
                                    <Card className="card-task" key={index} onClick={() => handleTaskClick(task)}>
                                        <Card.Body className="p-3 pb-1">
                                            {/* Render task data */}
                                            <div className="d-flex gap-1">
                                                {/*  {task.taskType}  */}
                                                {/*{task.badge.map((badge, ind) => (*/}
                                                <Badge key="0" bg="success">
                                                    {task.taskType}
                                                </Badge>
                                                {/*))}*/}
                                            </div>
                                            <div
                                                className="d-flex flex-row-reverse align-items-center justify-content-between mt-2 mb-1">
                                                <span className="card-date">{task.dueDate}</span>
                                                <Card.Title as="h6">{task.name}</Card.Title>
                                            </div>
                                            <p className="fs-xs">
                                                Assigned to: <Link to="">{task.assignees}</Link>
                                            </p>
                                            {task.text && <p className="fs-sm">{task.taskDescription}</p>}

                                            <div
                                                className="d-flex align-items-center justify-content-between fs-xs text-secondary mb-1">
              <span>
                <strong className="fw-medium">{task.progress}%</strong> complete
              </span>
                                                <span>{task.hour}</span>
                                            </div>
                                            <ProgressBar now={task.progress} className="mb-2"/>
                                        </Card.Body>
                                        <Card.Footer className="p-3 pt-0">
                                            <p className="fs-xs">
                                                Assigned by: <Link to="">{task.creator}</Link>
                                            </p>
                                            <Nav as="nav">
                                                <Nav.Link href="">
                                                    <i className="ri-eye-2-line"></i> {task.views}
                                                </Nav.Link>
                                                <Nav.Link href="">
                                                    <i className="ri-chat-1-line"></i> {task.comments}
                                                </Nav.Link>
                                            </Nav>
                                        </Card.Footer>

                                    </Card>
                                ))}

                            </Col>
                        </Row>
                        {selectedTask && <SingleTask />}

                    </Col>
                </Row>

                <Footer />
            </div>
        </React.Fragment>
    );

}