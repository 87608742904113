import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { Link } from "react-router-dom";
import {
  Card,
  Col,
  Button,
  Nav,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Spinner,
  Form,
} from "react-bootstrap";
import ReactApexChart from "react-apexcharts";

import Swal from "sweetalert2";

import Avatar from "../../components/Avatar";

import { ReportService } from "../../service/ReportService";
import Sidebar from "../../layouts/Sidebar";
import TaskDetails from "../../components/TaskDetails";

export default function Reports() {
  var data = [
    [0, 9],
    [1, 7],
    [2, 4],
    [3, 8],
    [4, 4],
    [5, 12],
    [6, 4],
    [7, 6],
    [8, 5],
    [9, 10],
    [10, 4],
    [11, 5],
    [12, 10],
    [13, 2],
    [14, 6],
  ];


  const chart = {
    parentHeightOffset: 0,
    stacked: true,
    sparkline: {
      enabled: true,
    },
  };

  const states = {
    hover: {
      filter: {
        type: "none",
      },
    },
    active: {
      filter: {
        type: "none",
      },
    },
  };

  const plotOptions = {
    bar: {
      columnWidth: "60%",
    },
  };

  const stroke = {
    curve: "straight",
    lineCap: "square",
  };

  const seriesOne = [
    {
      type: "column",
      data: [
        [0, 0],
        [1, 0],
        [2, 5],
        [3, 10],
        [4, 6],
        [5, 10],
        [6, 15],
        [7, 18],
        [8, 7],
        [9, 11],
        [10, 13],
        [11, 15],
        [12, 13],
        [13, 7],
        [14, 5],
      ],
    },
    {
      type: "column",
      data: data,
    },
  ];

  const optionOne = {
    chart: chart,
    states: states,
    colors: ["#506fd9", "#e5e9f2"],
    plotOptions: plotOptions,
    stroke: stroke,
    fill: { opacity: 1 },
    tooltip: { enabled: false },
  };

  const seriesTwo = [
    {
      type: "column",
      data: [
        [0, 3],
        [1, 4],
        [2, 12],
        [3, 6],
        [4, 16],
        [5, 5],
        [6, 8],
        [7, 4],
        [8, 3],
        [9, 6],
        [10, 4],
        [11, 10],
        [12, 3],
        [13, 7],
        [14, 10],
      ],
    },
    {
      type: "column",
      data: data,
    },
  ];

  const optionTwo = {
    chart: chart,
    states: states,
    colors: ["#85b6fe", "#e5e9f2"],
    plotOptions: plotOptions,
    stroke: stroke,
    fill: { opacity: 1 },
    tooltip: { enabled: false },
  };

  const seriesThree = [
    {
      type: "column",
      data: [
        [0, 5],
        [1, 10],
        [2, 20],
        [3, 15],
        [4, 6],
        [5, 10],
        [6, 15],
        [7, 18],
        [8, 7],
        [9, 11],
        [10, 13],
        [11, 15],
        [12, 13],
        [13, 7],
        [14, 5],
      ],
    },
    {
      type: "column",
      data: data,
    },
  ];

  const optionThree = {
    chart: chart,
    states: states,
    colors: ["#0dcaf0", "#e5e9f2"],
    plotOptions: plotOptions,
    stroke: stroke,
    fill: { opacity: 1 },
    tooltip: { enabled: false },
  };

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  const [logData, setLogData] = React.useState([]);
  const [reportData, setReportData] = React.useState([]);
  const [managerReport, setManagerReport] = React.useState([]);
  const [taskDetails, setTaskDetails] = useState([]);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [loaded, setIsLoaded] = useState(false);
  const [employeeName, setIsEmployeeName] = useState("");

  const viewTaskDetails = (item,employeeName) => {
    setTaskDetails(item);
    setIsEmployeeName(employeeName);
    setShowTaskModal(true);
  };

  useEffect(() => {
    ReportService.getReportForManagerDaily(localStorage.getItem("token"))
      .then((res) => {
        setManagerReport(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });

    ReportService.getReportForActivity(localStorage.getItem("token"))
      .then((res) => {
        if (res.data.result.length > 0) {
          setLogData(res.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    switchSkin(skin);
  }, [skin, setLogData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    ReportService.getManagerReportForTeamByRange(
      localStorage.getItem("token"),
      startDate,
      endDate
    )
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setReportData(response.data.result);
          setIsLoaded(true);
        }
      })
      .catch((error) => {
        Swal.fire("Action failed", error.response.data.result, "error");
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link href="#">Reports</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Team Reports
              </li>
            </ol>
            <h4 className="main-title mb-0">Welcome to Reports Section</h4>
          </div>

        
        </div>

        <Row className="g-3 justify-content-center">
              <Col md="6" xl="4">
                <Card className="card-one">
                  <Card.Body>
                    <Row>
                      <Col xs="7">
                        <h3 className="card-value mb-1">
                          {managerReport.totalTasksAssigned}
                        </h3>
                        <label className="card-title fw-medium text-dark mb-1">
                          Total Tasks Assigned Today
                        </label>
                        <span className="d-block text-muted fs-11 ff-secondary lh-4">
                          Total No. of Tasks Assigned
                        </span>
                      </Col>
                      <Col xs="5">
                        <ReactApexChart
                          series={seriesOne}
                          options={optionOne}
                          type="bar"
                          height={70}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6" xl="4">
                <Card className="card-one">
                  <Card.Body>
                    <Row>
                      <Col xs="7">
                        <h3 className="card-value mb-1">
                          {managerReport.tasks_pendingApproval +
                            managerReport.ongoingTasks}
                        </h3>
                        <label className="card-title fw-medium text-dark mb-1">
                          Ongoing Tasks For Today
                        </label>
                        <span className="d-block text-muted fs-11 ff-secondary lh-4">
                          Tasks in Progress and Pending Approval
                        </span>
                      </Col>
                      <Col xs="5">
                        <ReactApexChart
                          series={seriesTwo}
                          options={optionTwo}
                          type="bar"
                          height={70}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6" xl="4">
                <Card className="card-one">
                  <Card.Body>
                    <Row>
                      <Col xs="7">
                        <h3 className="card-value mb-1">
                          {managerReport.completedTasks}
                        </h3>
                        <label className="card-title fw-medium text-dark mb-1">
                          Completed Tasks Today
                        </label>
                        <span className="d-block text-muted fs-11 ff-secondary lh-4">
                          Tasks completed within the team
                        </span>
                      </Col>
                      <Col xs="5">
                        <ReactApexChart
                          series={seriesThree}
                          options={optionThree}
                          type="bar"
                          height={70}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
        <Card className="card-one mt-3">
          <Card.Header>Generate Report</Card.Header>
          <Card.Body>
            <div className="mb-3">
              <Col sm="6">
                <Form.Label htmlFor="formGroupExampleInput2">
                  Start Date
                </Form.Label>
               
                              <Form.Control
                                name="startDate"
                                type="date"
                                id="startDate"
                                class="form-control input-class"
                                onChange={(e) => {
                                  setStartDate(e.target.value);
                                }}
                              />
              </Col>
              <Col sm="6">
                <Form.Label htmlFor="formGroupExampleInput2">
                  End Date
                </Form.Label>
              
                <Form.Control
                                name="endDate"
                                type="date"
                                id="endDate"
                                class="form-control input-class"
                                onChange={(e) => {
                                  setEndDate(e.target.value);
                                }}/>
              </Col>
            </div>
          </Card.Body>

          <Card.Footer>
            {!isLoading && (
              <Button variant="primary" onClick={handleSubmit}>
                Create Report
              </Button>
            )}
            {isLoading && (
              <Button className="btn-sign">
                Processing...
                <span />
                <Spinner size="sm" animation="border" />
              </Button>
            )}
          </Card.Footer>
          <Form onSubmit={handleSubmit} />
        </Card>
        
        {loaded && (
          <div>
         
            <Col xs="12">
              <Card className="card-one mt-3">
                <Card.Header>
                  <Card.Title as="h6">Task Figures from {startDate} till {endDate}</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body>
                  <Table className="table-agent1 mb-0" responsive>
                    <thead>
                      <tr>
                        {/* <th>
                    <Form.Check type="checkbox" />
                  </th> */}
                        {/* <th>Average Rating(%)</th> */}
                        <th>Employee Name</th>
                        <th>Total Tasks Assigned</th>
                        <th>Ongoing Tasks</th>
                        <th>Completed Tasks</th>
                        <th>Overdue Tasks</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reportData.map((item, index) => (
                        <tr key={index}>
                          {/* <td>
                        <Form.Check type="checkbox" />
                      </td> */}
                          {/* <td><span className="ff-numerals">{item.averageRating>0?item.averageRating:"NOT RATED YET"}</span></td> */}
                          <td>
                            <div className="d-flex align-items-center gap-2">
                              <Avatar img={item.avatar} />
                              <div>
                                <h6 className="mb-0">{item.employeeName}</h6>
                                <span className="fs-xs text-secondary">
                                  {item.email}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="ff-numerals">
                              {item.totalTasksAssigned}
                            </span>
                          </td>
                          <td>
                            <span className="ff-numerals">
                              {item.ongoingTasks + item.tasks_pendingApproval}
                            </span>
                          </td>
                          <td>
                            <span className="ff-numerals">
                              {item.completedTasks}
                            </span>
                          </td>
                          <td>
                            <span className="ff-numerals">
                              {item.overdueTasks}
                            </span>
                          </td>
                          {/*<td>*/}
                          {/*  <div className="d-flex gap-1 text-warning">*/}
                          {/*    {item.averageRating.map((star, ind) => (*/}
                          {/*        <i key={ind} className={"ri-star-" + star}></i>*/}
                          {/*    ))}*/}
                          {/*  </div>*/}
                          {/*</td>*/}
                          <td>
                            <span className="fs-xs text-secondary">
                              <Button
                                variant="primary"
                                onClick={() =>
                                  viewTaskDetails(item.assignedTasksDetails,item.employeeName)
                                }
                              >
                                VIEW
                              </Button>
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>

          </div>
        )}

        <Card className="card-one mt-3">
              <Card.Body>
                <Table className="table-four table-bordered">
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th colSpan="3">Login Activity</th>
                      {/* <th colSpan="3">Logout Activity</th> */}
                    </tr>
                    <tr>
                      <th>Employee Name</th>
                      <th>Date</th>
                      <th>Login Time</th>
                      <th>IP Address</th>
                      {/* <th>Date</th>
                  <th>Logout Time</th>
                  <th>Avg. Time Session</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {logData.length > 0 &&
                      logData.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <Link to="">{item.employeeName}</Link>
                          </td>
                          <td>{item.loginActivityDate}</td>
                          <td>{item.lastLoginTime}</td>
                          <td>{item.ipLocation}</td>
                          {/* <td>{item.logoutActivityDate}</td>
                    <td>{item.lastLogoutTime}</td>
                    <td>00:01:05</td> */}
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

        {showTaskModal && (
          <TaskDetails
            onClose={() => setShowTaskModal(false)}
            taskData={taskDetails}
            employeeData={employeeName}
          />
        )}
      </div>
    </React.Fragment>
  );
}
