import React, {useContext, useEffect} from "react";
import { Link } from "react-router-dom";
import {Col, Row, Form, Nav, Card, Button} from "react-bootstrap";


import projects from "../assets/img/projects.png";

import SingleProject from "../components/SingleProject";
import TaskContext from "../context/TaskContext";


import {ProjectService} from "../service/ProjectService";

export default function BackgroundProjects() {

    const { selectedProject, updateProject } = useContext(TaskContext);
    const { updateModalState } = useContext(TaskContext);
    const [data, setData] = React.useState([]);
    const [managerData, setManagerData] = React.useState([]);
    const [userData, setUserData] = React.useState([]);
    let userDetails = {};
    const userDetailsJSON = localStorage.getItem('userDetails');
    userDetails = JSON.parse(userDetailsJSON);
    const userRole = userDetails ? userDetails.role : "";
    const allowedRoles = ["MANAGER","HR"];
    const shouldRenderManagerSection = allowedRoles.includes(userRole);
    const shouldEmployeeSection = (userRole==="EMPLOYEE");


    useEffect(() => {
        ProjectService.getAllProjectsOfUser(localStorage.getItem("token"))
            .then((res) => {
                setUserData(res.data.result);
            })
            .catch((err) => {
                console.log(err);
            });

        ProjectService.getAllProjectsOfManager(localStorage.getItem("token"))
            .then((res) => {
                setManagerData(res.data.result);
            })
            .catch((err) => {
                console.log(err);
            });

    }, [ ]);


    useEffect(() => {
        if (shouldRenderManagerSection && !data.length) {
            setData(managerData);
        } else if (shouldEmployeeSection && !data.length) {
            setData(userData);
        }
    }, [shouldRenderManagerSection, shouldEmployeeSection, data.length, managerData, userData]);

    function handleProjectClick(project) {

        // pass the task to the context
        updateProject(project);
        //open modal
        updateModalState(true);
    }


    return (
        <React.Fragment>
            <div className="main main-app p-3 p-lg-4 p-xxl-5">
                <Row className="g-5">
                    <Col xl>
                        <ol className="breadcrumb fs-sm mb-2">
                            <li className="breadcrumb-item"><Link to="#">Pages</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Projects</li>
                        </ol>
                        <h2 className="main-title">Projects</h2>

                        <Nav className="nav-line mb-4">
                            <Nav.Link href="" className="active">Current Projects</Nav.Link>
                        </Nav>

                        <Row className="g-3">
                            {
                                data.map((event, index) => (
                                    <Col sm="6" key={index}>
                                        <Card className="card-event" onClick={() => handleProjectClick(event)}>
                                            <Link to="#"><img src={projects} className="card-img-top" alt="" /></Link>
                                            <Card.Body>
                                                <h6><Link to="">{event.name}</Link></h6>
                                                <p className="mb-0">{event.startDate + " - " + event.endDate}</p>
                                                <p className="text-secondary fs-sm">{event.name}</p>

                                                <div className="mutual-badge">
                                                    <ul>
                                                        {/*{event.mutual.map((img, ind) => (*/}
                                                        {/*  <li key={ind}><Avatar img={img} /></li>*/}
                                                        {/*))}*/}
                                                    </ul>
                                                    <label> You and {event.assigneeCount} others are part of this.</label>
                                                </div>
                                            </Card.Body>
                                            <Card.Footer>
                                                <Button variant="primary"><i className="ri-star-line"></i>View Project</Button>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                ))}
                            {selectedProject && <SingleProject />}
                        </Row>
                        {data.length === 0 && (
                            <Card>
                                <Card.Body>
                                    <div className="text-center">
                                        <h4 className="mb-3">No Projects Found</h4>
                                    </div>
                                </Card.Body>
                            </Card>
                        )}


                    </Col>
                    <Col xl="4" className="d-none d-xl-block">
                        <div className="form-search">
                            <i className="ri-search-line"></i>
                            <Form.Control type="text" placeholder="Search Projects" />
                        </div>

                        <hr className="my-3 opacity-0" />
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}