import axios from "axios";


const GET_ALL_TEAM = "https://mtroflow-fksngumbvq-lz.a.run.app/team/all";
const SAVE_SCHEDULE = "https://mtroflow-fksngumbvq-lz.a.run.app/schedule/create";

class teamService {
    getTeamInfoById(token, teamId){
        return axios.get(`https://mtroflow-fksngumbvq-lz.a.run.app/team/${teamId}/info`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }

    getTeamInfoByManager(token){
        return axios.get("https://mtroflow-fksngumbvq-lz.a.run.app/team/members", {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }

    getAllTeams(token){
        return axios.get(GET_ALL_TEAM, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }

    saveSchedule(dto, token) {
        return axios.post(SAVE_SCHEDULE, dto, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    }


    getTeamSchedule(token){
        return axios.get("https://mtroflow-fksngumbvq-lz.a.run.app/schedule/team/manager", {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }
    
}

export const TeamService = new teamService();
