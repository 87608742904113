import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { Grid } from "gridjs-react";
import { DataGrid } from "@mui/x-data-grid";

export default function KpiResult(props) {
  const rows = props.data;
  const columns = [
    {
      field: "id",
      headerName: "KPI ID",
      flex: 0.5,
    },
    {
      field: "description",
      headerName: "KPI Description",
      flex: 1,
    },
    { field: "weight", headerName: "KPI Weight", flex: 1 }
  ];


  return (
    <React.Fragment>
      <h5 id="section13" className="main-subtitle">
        KPI Metrics
      </h5>
      <Card className="card-example">
        <Card.Body>
        <DataGrid
        rows={rows}
        columns={columns}
        pageSize={20}
        // onSelectionModelChange={(selection) =>
        //   setSelectedTariff(selection.selectionModel[0])
        // }
        search
      />
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
    </React.Fragment>
  );
}
