import axios from "axios";


const GET_ALL_NEW_TASKS_CREATED = "https://mtroflow-fksngumbvq-lz.a.run.app/task/fetch/?status=NEW";
const GET_ONGOING_TASKS = "https://mtroflow-fksngumbvq-lz.a.run.app/task/fetch/?status=ONGOING";
const GET_COMPLETED_TASKS = "https://mtroflow-fksngumbvq-lz.a.run.app/task/fetch/?status=COMPLETED";
const GET_EXTENSION_REQUESTED = "https://mtroflow-fksngumbvq-lz.a.run.app/task/fetch/?status=EXTENSION_REQUESTED";
const GET_OVERDUE_TASKS = "https://mtroflow-fksngumbvq-lz.a.run.app/task/fetch/?status=OVERDUE";
const GET_PENDING_APPROVAL = "https://mtroflow-fksngumbvq-lz.a.run.app/task/fetch/?status=PENDING_APPROVAL";
const CREATE_TASK = "https://mtroflow-fksngumbvq-lz.a.run.app/task/create";
const CREATE_MULTIPLE_TASK = "https://mtroflow-fksngumbvq-lz.a.run.app/task/create/multiple";
const GET_REPORT_FOR_A_USER = "https://mtroflow-fksngumbvq-lz.a.run.app/report/employee/";
const GET_ALL_NEW_TASKS_OF_ASSIGNER = "https://mtroflow-fksngumbvq-lz.a.run.app/task/assigner/fetch/?status=NEW";
const GET_ONGOING_TASKS_OF_ASSIGNER = "https://mtroflow-fksngumbvq-lz.a.run.app/task/assigner/fetch/?status=ONGOING";
const GET_COMPLETED_TASKS_OF_ASSIGNER = "https://mtroflow-fksngumbvq-lz.a.run.app/task/assigner/fetch/?status=COMPLETED";
const GET_EXTENSION_REQUESTED_TASKS_OF_ASSIGNER = "https://mtroflow-fksngumbvq-lz.a.run.app/task/assigner/fetch/?status=EXTENSION_REQUESTED";
const GET_OVERDUE_TASKS_OF_ASSIGNER = "https://mtroflow-fksngumbvq-lz.a.run.app/task/assigner/fetch/?status=OVERDUE";
const GET_PENDING_APPROVAL_OF_ASSIGNER = "https://mtroflow-fksngumbvq-lz.a.run.app/task/assigner/fetch/?status=PENDING_APPROVAL"
const CREATE_WORK_PLAN = "https://mtroflow-fksngumbvq-lz.a.run.app/task/workplan";

class taskService {


    createWorkPlan(planData, token) {
        return axios.post(CREATE_WORK_PLAN, planData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    }

    createWorkPlanForUser(userId, token,workPlan) {
        return axios.post(`https://mtroflow-fksngumbvq-lz.a.run.app/task/${userId}/workplan`,workPlan, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }
    createTask(taskData, token) {
        return axios.post(CREATE_TASK, taskData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    }

    createTaskForMultiple(taskData, token) {
        return axios.post(CREATE_MULTIPLE_TASK, taskData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    }
    getAllNewTasksCreated(token) {


        return axios.get(GET_ALL_NEW_TASKS_CREATED, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }
    getAllInProgressTasksCreated(token) {


        return axios.get(GET_ONGOING_TASKS, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }
    getAllPendingApprovalTasksCreated(token) {


        return axios.get(GET_PENDING_APPROVAL, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }
    getAllCompletedTasksCreated(token) {


        return axios.get(GET_COMPLETED_TASKS, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }

    getReportForAnEmployee(token) {


        return axios.get(GET_REPORT_FOR_A_USER, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    }
        getAllOverdueTasks(token) {


            return axios.get(GET_OVERDUE_TASKS, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            });
        }
        getAllExtensionRequestedTasks(token) {


            return axios.get(GET_EXTENSION_REQUESTED, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            });
        }
        getAllOverdueTasksOfAssigner(token) {


            return axios.get(GET_OVERDUE_TASKS_OF_ASSIGNER, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            });
        }

        getAllCompletedTasksOfAssigner(token) {


            return axios.get(GET_COMPLETED_TASKS_OF_ASSIGNER, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            });
        }


        getAllExtensionRequestedTasksOfAssigner(token) {


            return axios.get(GET_EXTENSION_REQUESTED_TASKS_OF_ASSIGNER, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            });
        }

        getAllNewTasksCreatedOfAssigner(token) {


            return axios.get(GET_ALL_NEW_TASKS_OF_ASSIGNER, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            });

        }
        getAllInProgressTasksCreatedOfAssigner(token) {


            return axios.get(GET_ONGOING_TASKS_OF_ASSIGNER, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            });

        }
    getTaskInfo(token, taskId) {


        return axios.get(`https://mtroflow-fksngumbvq-lz.a.run.app/task/${taskId}/info`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }

    getTaskLog(token, taskId) {


        return axios.get(`https://mtroflow-fksngumbvq-lz.a.run.app/task/${taskId}/log/`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }

        getAllPendingApprovalTasksCreatedxOfAssigner(token) {


            return axios.get(GET_PENDING_APPROVAL_OF_ASSIGNER, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            });


        }


        getAllTasksByUserAndStatus(token,status,userId) {


            return axios.get(`https://mtroflow-fksngumbvq-lz.a.run.app/task/${userId}/fetch/?status=${status}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            });
    
        }

    // Implement other methods with similar changes...
}

export const TaskService = new taskService();
