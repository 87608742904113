import React, {useContext, useEffect} from "react";

import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import {Badge, Card, Col, Nav, ProgressBar, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import SingleTask from "../components/SingleTask";
import TaskContext from "../context/TaskContext";
import {TaskService} from "../service/TaskService";
import Sidebar from "../layouts/Sidebar";
import TaskListing from "./TaskListing";


export default function ManagerAssignedTasks() {
    useEffect(() => {
        document.body.classList.add('app-task')
        return () => {
            document.body.classList.remove('app-task')
        }
    }, []);


    const {selectedTask, updateTask} = useContext(TaskContext);
    const {updateModalState} = useContext(TaskContext);
    const [data, setData] = React.useState([]);
    const [progressTasks, setProgressTasks] = React.useState([]);
    const [pendingApprovalTasks, setPendingApprovalTasks] = React.useState([]);
    const [setCompletedTasks] = React.useState([]);


    function handleTaskClick(task) {

        // pass the task to the context
        updateTask(task);
        //open modal
        updateModalState(true);
    }

    useEffect(() => {
        TaskService.getAllNewTasksCreatedOfAssigner(localStorage.getItem("token"))
            .then((res) => {
                setData(res.data.result);
            })
            .catch((err) => {
                console.log(err);
            });

        TaskService.getAllInProgressTasksCreatedOfAssigner(localStorage.getItem("token"))
            .then((res) => {
                setProgressTasks(res.data.result);
            })
            .catch((err) => {
                console.log(err);
            });

        TaskService.getAllPendingApprovalTasksCreatedxOfAssigner(localStorage.getItem("token"))
            .then((res) => {
                setPendingApprovalTasks(res.data.result);
            })
            .catch((err) => {
                console.log(err);
            });

        TaskService.getAllCompletedTasksCreated(localStorage.getItem("token"))
            .then((res) => {
                setCompletedTasks(res.data.result);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [setCompletedTasks, setData, setPendingApprovalTasks, setProgressTasks]);




    return (
        <React.Fragment>
            <Sidebar />
            <Header />
            <div className="main main-app p-3 p-lg-4 p-xxl-5">
                <Row className="row-cols-auto g-4 g-xxl-5">
                    <Col xl>
                        <h2 className="main-title fs-4 mb-1">Welcome to your Task Manager!</h2>
                        <p className="text-secondary mb-4">Streamline your Workflow with Ease</p>

                        <Nav className="nav-line mb-4">
                            <Nav.Link href="" className="nav-link active" >
                                Your Active Tasks
                            </Nav.Link>
                        </Nav>
                        <Row className="g-3 g-lg-4">

                            <Col md>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <label className="task-label">Newly Assigned</label>
                                    <div className="dropdown dropdown-task">
                                        <Link to="" className="dropdown-link">Date Added <i
                                            className="ri-arrow-down-s-line"></i></Link>
                                    </div>
                                </div>
                                {/*new tasks*/}
                                <TaskListing taskList={data} taskClickFunction={handleTaskClick} />

                            </Col>
                            <Col md>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <label className="task-label">In Progress</label>
                                    <div className="dropdown dropdown-task">
                                        <Link to="" className="dropdown-link">Date Added <i
                                            className="ri-arrow-down-s-line"></i></Link>
                                    </div>
                                </div>

                                {/*in progress tasks*/}
                                <TaskListing taskList={progressTasks} taskClickFunction={handleTaskClick} />


                            </Col>
                            <Col md className="mt-5 mt-md-3 mt-lg-4">
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <label className="task-label">Pending Approval</label>
                                    <div className="dropdown dropdown-task">
                                        <Link to="" className="dropdown-link">Date Added <i
                                            className="ri-arrow-down-s-line"></i></Link>
                                    </div>
                                </div>

                                {/*pending approval*/}
                                <TaskListing taskList={pendingApprovalTasks} taskClickFunction={handleTaskClick} />

                            </Col>
                        </Row>
                        {selectedTask && <SingleTask/>}

                    </Col>
                </Row>

                <Footer />
            </div>
        </React.Fragment>
    );

}