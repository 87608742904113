import React from "react";
import { useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import { Card, Col, Nav, Row, Tab } from "react-bootstrap";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import Avatar from "../components/Avatar";

import img1 from "../assets/img/img1.jpg";
import img5 from "../assets/img/img5.jpg";
import img6 from "../assets/img/img6.jpg";
import { hideLoadingModal,showLoadingModal} from "../logic/Utils";
import Sidebar from "../layouts/Sidebar";
import KpiResult from "../components/KpiResult";
import { EmployeeService } from "../service/EmployeeService";

export default function EmployeeProfile() {
    const [isProcessed, setIsProcessed] = React.useState(false);
  
 
    const [employeeProfile, setEmployeeProfile] = React.useState(null);
    let employeeId = null;
    const handleMemberClick = (employeeId) => {
      showLoadingModal();
      EmployeeService.getProfileFOrAUserById(localStorage.getItem("token"), employeeId)
      .then((response) => {
        setEmployeeProfile(response.data.result);
        setIsProcessed(true);
        hideLoadingModal();
      })
      .catch((err) => {
          hideLoadingModal();
          console.log(err);
      });
    };
  
    useEffect(() => {

        showLoadingModal();

        const regex = /id=([^&]+)/;
        // Get the current URL from the browser
        const currentUrl = window.location.href;

        // Use the regular expression to extract the token from the URL
        const match = currentUrl.match(regex);
        employeeId = match && match[1]; 
        

        if (employeeId) {
        EmployeeService.getProfileFOrAUserById(localStorage.getItem("token"), employeeId)
                .then((response) => {
                  setEmployeeProfile(response.data.result);
                  setIsProcessed(true);
                  hideLoadingModal();
                })
                .catch((err) => {
                    hideLoadingModal();
                    console.log(err);
                });
    
        
            }

        else{
            EmployeeService.getProfileFOrAUser(localStorage.getItem("token"))
            .then((response) => {
              setEmployeeProfile(response.data.result);
              setIsProcessed(true);
              hideLoadingModal();
            })
            .catch((err) => {
                console.log(err);
                hideLoadingModal();
            });
        }

    }, [employeeId]);


  return (
    <React.Fragment>
      <Sidebar />
      <Header />
      <div className="main main-app p-3 p-lg-4">
      {isProcessed&&(  <Row className="g-5">
          <Col xl>
            <div className="media-profile mb-5">
              <div className="media-img mb-3 mb-sm-0">
                <img src={img1} className="img-fluid" alt="..." />
              </div>
              <div className="media-body">
                <h5 className="media-name">{employeeProfile.employeeName}</h5>
                <p className="d-flex gap-2 mb-4">
                  <i className="ri-map-pin-line"></i> MetroHealth HMO Ltd.
                </p>
                <p className="mb-0">
                  Redhead, Innovator, Saviour of Mankind, Hopeless Romantic,
                  Attractive 20-something Yogurt Enthusiast. You can replace
                  this with any content and adjust it as needed...{" "}
                  <Link to="">Read more</Link>
                </p>
              </div>
            </div>

   
            <Tab.Container
              defaultActiveKey={"employeeInformation"}
              className="mt-5"
            >
              <Nav className="nav-line mt-5">
                <Nav.Item>
                  <Nav.Link
                    style={{ marginRight: "20px" }}
                    eventKey="employeeInformation"
                  >
                    Employee Information
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    style={{ marginRight: "20px" }}
                    eventKey="kpiInformation"
                  >
                    KPI Information{" "}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    style={{ marginRight: "20px" }}
                    eventKey="editProfile"
                  >
                    Edit Profile
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="employeeInformation">
                 

                  <Card className="card-post mt-4">
                  
                    <Card.Body>
                     
                    </Card.Body>
                    <Card.Footer>
                      <Nav>
                        <Nav.Link href="">
                          <i className="ri-thumb-up-line"></i> Like
                        </Nav.Link>
                       
                      </Nav>
                    </Card.Footer>
                  </Card>
                </Tab.Pane>
                <Tab.Pane eventKey="kpiInformation">
                  <Card className="card-post mt-4">
                    <Card.Header>
                      <Card.Title>KPI Information</Card.Title>
                      <Link to="" className="link-more">
                        <i className="ri-more-2-fill"></i>
                      </Link>
                    </Card.Header>
                    <Card.Body>
                    {isProcessed && <KpiResult data={employeeProfile.kpiEntityList} />}
                    </Card.Body>
                    <Card.Footer>
                     
                    </Card.Footer>
                  </Card>
                </Tab.Pane>
                <Tab.Pane eventKey="editProfile">
                  <Card className="card-post mt-4">
                    <Card.Header>
                      <Card.Title>Account Settings</Card.Title>
                      <Link to="" className="link-more">
                        <i className="ri-more-2-fill"></i>
                      </Link>
                    </Card.Header>
                    <Card.Body>
                     
                    </Card.Body>
                    <Card.Footer>
                     
                    </Card.Footer>
                  </Card>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
          <Col xl="4" xxl="3" className="d-none d-xl-block">
            <hr className="my-4 opacity-0" />

            <h5 className="section-title mb-4">Contact Information</h5>
            <ul className="list-contact-info">
              <li>
                <i className="ri-phone-fill"></i>
                <span>{employeeProfile.employeePhoneNumber}</span>
              </li>
              <li>
                <i className="ri-mail-fill"></i>
                <span>{employeeProfile.employeeEmail}</span>
              </li>
              <li>
                <i className="ri-building-fill"></i>
                <span>{employeeProfile.employeeDepartment}</span>
              </li>
            </ul>

            <hr className="my-4 opacity-0" />

            <h5 className="section-title mb-4">People You May Know</h5>
            <ul className="people-group">
              {employeeProfile.employeeFriendsList.map((people, index) => (
                <li className="people-item" key={index}>
                  <Avatar img={img6} />
                  <div className="people-body">
                    <h6>
                    <Link onClick={() => handleMemberClick(people.employeeId)}>
      {people.employeeName}
    </Link>
                    </h6>
                    <span>{people.employeeRole}</span>
                  </div>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
       )}
      </div>
    </React.Fragment>
  );
}
