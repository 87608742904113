import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
  Container,
} from "react-bootstrap";

import Swal from "sweetalert2";
import Select from "react-select";
import { TaskService } from "../service/TaskService";

import { useNavigate } from "react-router-dom";
import { EmployeeService } from "../service/EmployeeService";

const DuplicateWorkPlan = ({ workPlanData, handleClose }) => {
  const [workPlanName, setWorkPlanName] = useState("");
  const [lgShow, setLgShow] = useState(true);
  const [isLoading, setIsLoading] = React.useState(false);

  const [workPlanDescription, setWorkPlanDescription] = useState("");
  const [kpiOptions, setKpiOptions] = useState([]);
  const [showKpi, setShowKpi] = useState(false);
  const [workPlanStartDate, setWorkPlanStartDate] = useState(
    getCurrentDateTime()
  );
  const [workPlanEndDate, setWorkPlanEndDate] = useState(null);
  const [activities, setActivities] = useState([]);
  const [kpiId, setKpiData] = useState("");
  const [step, setStep] = useState(1);
  const workPlanDto = {
    workPlanName,
    workPlanDescription,
    workPlanStartDate,
    workPlanEndDate,
    activities,
    kpiId,
  };

  useEffect(() => {
    // Extract data from the provided workPlanData

    EmployeeService.getKpiFOrAUser(localStorage.getItem("token"))
      .then((res) => {
        const selectOptions = res.data.result.map((kpi, index) => ({
          value: kpi.id,
          label: kpi.description,
        }));

        setKpiOptions(selectOptions);
        setShowKpi(true);
      })
      .catch((err) => {
        console.log(err);
      });

    setWorkPlanName(workPlanData.name);
    setWorkPlanDescription(workPlanData.description);
    setActivities([...workPlanData.assignedTasksDetails]);
    setKpiData(workPlanData.kpiId);
  }, [workPlanData]);

  const handleTaskKpiChange = (index, kpiOption) => {
    const updatedFormData = [...activities];
    updatedFormData[index]["kpiId"] = kpiOption.value; // Assuming 'kpiId' is the correct property in your activities array
    setActivities(updatedFormData);
  };
  const validateWorkPlanDto = (workPlanDto) => {
    const errorMessages = [];

    // Check each field in the workPlanDto object

    if (!workPlanDto.workPlanName) {
      errorMessages.push("Work Plan Name is required.");
    }

    if (!workPlanDto.workPlanDescription) {
      errorMessages.push("Work Plan Description is required.");
    }

    if (!workPlanDto.workPlanStartDate) {
      errorMessages.push("Work Plan Start Date is required.");
    }

    if (!workPlanDto.workPlanEndDate) {
      errorMessages.push("Work Plan End Date is required.");
    }

    // Display error messages if any
    if (errorMessages.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: errorMessages.join("\n"),
      });

      return false; // Validation failed
    }

    return true; // Validation passed
  };

  const validateWorkPlanDtoActivities = (workPlanDto) => {
    const errorMessages = [];

    if (workPlanDto.activities.length === 0) {
      errorMessages.push("At least one activity is required.");
    }

    if (workPlanDto.activities.some((activity) => !activity.taskDescription)) {
      errorMessages.push("All activities must have a description");
    }

    if (workPlanDto.activities.some((activity) => !activity.endDate)) {
      errorMessages.push("All activities must have a due date.");
    }

    if (workPlanDto.activities.some((activity) => !activity.name)) {
      errorMessages.push("All activities must have a title.");
    }

    if (workPlanDto.activities.some((activity) => !activity.kpiId)) {
      errorMessages.push("All activities must have a kpi.");
    }

    // Display error messages if any
    if (errorMessages.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: errorMessages.join("\n"),
      });

      return false; // Validation failed
    }

    return true; // Validation passed
  };

  const handleNext = () => {
    if (step === 1 && !validateWorkPlanDto(workPlanDto)) {
      return;
    }

    setStep(step + 1);
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  const changeGlobalEndDate = (endDate) => {
    setWorkPlanEndDate(endDate);
    const updatedActivities = workPlanData.assignedTasksDetails.map(
      (activity) => ({
        ...activity,
        endDate: endDate,
      })
    );

    setActivities(updatedActivities);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!validateWorkPlanDtoActivities(workPlanDto)) {
      setIsLoading(false);
      return;
    }

    const modifiedArray = workPlanDto.activities.map((obj) => {
      return {
        title: obj.name,
        description: obj.taskDescription,
        endDate: obj.endDate,
      };
    });

    workPlanDto.activities = modifiedArray;

    console.log(workPlanDto.activities);

    TaskService.createWorkPlan(workPlanDto, localStorage.getItem("token"))
      .then((res) => {
        console.log(res);

        if (res.status === 201) {
          setIsLoading(false);
          Swal.fire("Workplan has been created", "Success", "success").then(
            (result) => {
              if (result.isConfirmed) {
                lgHandleClose();
              }
            }
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
        Swal.fire("Action failed", err.response.data.result, "error");
      });
  };

  const lgHandleClose = () => {
    setLgShow(false);
    handleClose();
  };
  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedFormData = [...activities];
    updatedFormData[index][name] = value;
    setActivities(updatedFormData);
  };

  const handleAddActivity = () => {
    setActivities([
      ...activities,
      {
        description: "",
        startDate: getCurrentDateTime(),
        endDate: new Date(),
        status: "",
        priority: "",
        title: "",
        kpiId: "",
      },
    ]);
  };

  const handleRemoveActivity = (index) => {
    const updatedFormData = [...activities];
    updatedFormData.splice(index, 1);
    setActivities(updatedFormData);
  };

  function getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = `${now.getMonth() + 1}`.padStart(2, "0");
    const day = `${now.getDate()}`.padStart(2, "0");
    const hours = `${now.getHours()}`.padStart(2, "0");
    const minutes = `${now.getMinutes()}`.padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  return (
    <React.Fragment>
      <Modal show={lgShow} onHide={lgHandleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Create a Work Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {step === 1 && (
            <Card>
              <Card.Body>
                <br />
                <div className="mb-3">
                  <Form.Label htmlFor="formGroupExampleInput">
                    Work Plan
                  </Form.Label>
                  <Form.Control
                    onChange={(event) => setWorkPlanName(event.target.value)}
                    type="text"
                    value={workPlanName}
                    id="formGroupExampleInput"
                    placeholder="Work Plan Title "
                    required
                  />
                </div>
                <div className="mb-3">
                  <Form.Label htmlFor="formGroupExampleInput2">
                    Work Plan Description
                  </Form.Label>
                  <Form.Control
                    onChange={(event) =>
                      setWorkPlanDescription(event.target.value)
                    }
                    as="textarea"
                    value={workPlanDescription}
                    id="exampleFormControlTextarea1"
                    rows="3"
                    placeholder="Enter a brief description here..."
                    required
                  ></Form.Control>
                </div>
                <div className="mb-3">
                  <Row>
                    {" "}
                    <Col sm="6">
                      <Form.Label htmlFor="formGroupExampleInput2">
                        Start Date
                      </Form.Label>
                      <Form.Control
                        type="datetime-local"
                        name="workPlanStartDate"
                        value={workPlanStartDate}
                        min={getCurrentDateTime()}
                        onChange={(e) => setWorkPlanStartDate(e.target.value)}
                        required
                      />
                    </Col>
                    <Col sm="6">
                      <Form.Label htmlFor="formGroupExampleInput2">
                        End Date
                      </Form.Label>
                      <Form.Control
                        type="datetime-local"
                        name="workPlanEndDate"
                        value={workPlanEndDate}
                        min={getCurrentDateTime()}
                        onChange={(e) => changeGlobalEndDate(e.target.value)}
                        required
                      />
                    </Col>
                  </Row>
                </div>
              </Card.Body>

              <Form onSubmit={handleSubmit} />
            </Card>
          )}

          {step === 2 && (
            <Card
              className="card-post mt-4"
              style={{ paddingLeft: "5%", paddingRight: "5%" }}
            >
              <Card.Header>
                <Card.Title>Tasks in this Workplan</Card.Title>
                <Card.Text>You can add as much as you want.</Card.Text>
              </Card.Header>
              <Card.Body className="p-0">
                {activities.length > 0 && (
                  <Container>
                    {activities.map((data, index) => (
                      <Card key={index} style={{ marginBottom: "20px" }}>
                        <Card.Body>
                          <Row>
                            <Col md={6} className="mb-3">
                              <Form.Label>Task Title</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Task Title"
                                name="name"
                                value={data.name}
                                onChange={(e) => handleInputChange(index, e)}
                              />
                            </Col>
                            <Col md={6}>
                              <Form.Label>Activity Description</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3} // Adjust the number of rows as needed
                                placeholder="Activity Description"
                                name="taskDescription"
                                value={data.taskDescription}
                                onChange={(e) => handleInputChange(index, e)}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6} className="mb-15">
                              <Form.Label>Due Date</Form.Label>
                              <Form.Control
                                type="datetime-local"
                                name="endDate"
                                value={data.endDate}
                                max={workPlanEndDate}
                                min={getCurrentDateTime()}
                                onChange={(e) => handleInputChange(index, e)}
                              />
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Form.Label htmlFor="formGroupExampleInput2">
                                  Associated KPI
                                </Form.Label>
                                {showKpi ? (
                                  <Select
                                    onChange={(kpiOption) => {
                                      if (kpiOption && kpiOption.value) {
                                        handleTaskKpiChange(index, {
                                          name: "kpiId",
                                          value: kpiOption.value,
                                        });
                                      }
                                    }}
                                    options={kpiOptions}
                                    isSearchable={true}
                                    value={
                                      showKpi
                                        ? kpiOptions.find(
                                            (option) =>
                                              option.value === data.kpiId
                                          )
                                        : null
                                    }
                                    required
                                  />
                                ) : (
                                  <p>Loading KPIs...</p>
                                )}
                              </div>
                              <Button
                                variant="danger"
                                onClick={() => handleRemoveActivity(index)}
                                style={{ marginTop: "10px" }}
                              >
                                Remove
                              </Button>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    ))}
                  </Container>
                )}
                <Row className="mt-3">
                  <Col>
                    <p>Total Activities Added: {activities.length}</p>
                  </Col>
                </Row>
              </Card.Body>

              <Card.Footer>
                <Row className="justify-content-center">
                  <Col md="auto">
                    <Button variant="primary" onClick={handleAddActivity}>
                      Add New Activity
                    </Button>
                  </Col>
                  <Col md="auto">
                    {!isLoading && (
                      <Button onClick={handleSubmit} className="btn-sign">
                        Submit Work Plan
                      </Button>
                    )}
                    {isLoading && (
                      <Button className="btn-sign">
                        Processing...
                        <span />
                        <Spinner size="sm" animation="border" />
                      </Button>
                    )}
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          )}
        </Modal.Body>
        <Modal.Footer>
          {step > 0 && (
            <Button variant="primary" onClick={handlePrev}>
              Previous Step
            </Button>
          )}
          {step > 0 && step < 2 && (
            <Button variant="success" onClick={handleNext}>
              Next Step
            </Button>
          )}
          <Button variant="secondary" onClick={lgHandleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default DuplicateWorkPlan;
