import axios from "axios";


const ForgotPassword_API_BASE_URL = "https://mtroflow-fksngumbvq-lz.a.run.app/auth/forget_password";
const ResetPassword_API_BASE_URL = "https://mtroflow-fksngumbvq-lz.a.run.app/auth/forget_password";
const LOGIN_API_BASE_URL = "https://mtroflow-fksngumbvq-lz.a.run.app/auth/login";
const LOGOUT_URL = "https://mtroflow-fksngumbvq-lz.a.run.app/logout";
class authService{

    login (data) {
        return axios.post(LOGIN_API_BASE_URL, data, {
            headers: {
                "Content-Type": "application/json"
            }
        });

    }

    forgotPassword(email) {
        return axios.post(ForgotPassword_API_BASE_URL, email,{
            headers: {
                "Content-Type": "application/json"
            }
        });
    }

    resetPassword(token, data) {
        return axios.post(ResetPassword_API_BASE_URL + token, data);
    }

    logout(token) {
        return axios({
            method: "post",
            url: LOGOUT_URL,
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    }

}

export const AuthService = new authService();