import React, { useState } from "react";
import {
  Card,
  Col,
  Nav,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Bar, Line  } from "react-chartjs-2";


import Sidebar from "../../layouts/Sidebar";

export default function Leads() {

   


  const chartOption = {
    indexAxis: "y",
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 100,
        grid: {
          borderColor: "#000",
          color: "#f3f5f9",
        },
        ticks: {
          color: "#212830",
          font: {
            size: 10,
            weight: "500",
          },
        },
      },
      y: {
        grid: {
          borderWidth: 0,
          color: "#f3f5f9",
        },
        ticks: {
          color: "#212830",
          font: {
            size: 12,
          },
        },
      },
    },
  };


  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  if (skin === "dark") {
    chartOption.scales["x"].grid.color = "#222b41";
    chartOption.scales["x"].ticks.color = "rgba(255,255,255,.65)";
    chartOption.scales["x"].grid.borderColor = "#222b41";
    chartOption.scales["y"].grid.color = "#222b41";
    chartOption.scales["y"].ticks.color = "rgba(255,255,255,.65)";
  } else {
    chartOption.scales["x"].grid.color = "#edeff6";
    chartOption.scales["x"].ticks.color = "#42484e";
    chartOption.scales["x"].grid.borderColor = "#edeff6";
    chartOption.scales["y"].grid.color = "#edeff6";
    chartOption.scales["y"].ticks.color = "#42484e";
  }

  const ctxLabel = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const ctxData1 = [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30];
  const ctxData2 = [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20];

  const sources = [
    "Direct Traffic",
    "Email Marketing",
    "Organic Search",
    "Paid Search",
    "Referrals",
    "Social Media",
    "Other Campaigns",
    "Offline Sources",
  ];
  const ctxData = [
    [10, 20, 30, 40, 50, 60, 70, 80], // Direct Traffic
    [15, 25, 35, 45, 55, 65, 75, 85], // Email Marketing
    [12, 22, 32, 42, 52, 62, 72, 82], // Organic Search
    [18, 28, 38, 48, 58, 68, 78, 88], // Paid Search
    [25, 35, 45, 55, 65, 75, 85, 95], // Referrals
    [30, 40, 50, 60, 70, 80, 90, 100], // Social Media
    [22, 32, 42, 52, 62, 72, 82, 92], // Other Campaigns
    [17, 27, 37, 47, 57, 67, 77, 87], // Offline Sources
  ];

  const ctxColors = [
    "#506fd9", // Blue
    "#DBDFFD", // Light Blue
    "#808080", // Grey
    "#3498db", // Dodger Blue
    "#2ecc71", // Emerald Green
    "#27ae60", // Dark Green
    "#c0392b", // Firebrick Red
    "#16a085", // Dark Cyan Green
  ];
  const ctxColor1 = "#33FFFF";
  const ctxColor2 = "#33FF57";
  const datasets = sources.map((label, index) => ({
    data: ctxData[index],
    backgroundColor: ctxColors[index % ctxColors.length], // Cycle through colors if more labels than colors
    barPercentage: 0.5,
    label,
  }));

  //   const dataBar = {
  //     labels: ctxLabel,
  //     datasets
  //   };

  const dataBar = {
    labels: ctxLabel,
    datasets: datasets,
  };
  const optionBar3 = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        stacked: true,
      },
      x: {
        stacked: true,
      },
    },
  };

  const dataLine = {
    labels: ctxLabel,
    datasets: [
      {
        data: ctxData1,
        borderColor: ctxColor1,
        backgroundColor: ctxColor1,
        borderWidth: 1,
      },
      {
        data: ctxData2,
        borderColor: ctxColor2,
        backgroundColor: ctxColor2,
        borderWidth: 1,
      },
    ],
  };

  const optionLine = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 80,
      },
    },
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Sidebar/>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Leads Analytics 
              </li>
            </ol>
            <h4 className="main-title mb-0">Leads Analytics</h4>
          </div>

          <Nav as="nav" className="nav-icon nav-icon-lg">
            <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
              <Nav.Link href="">
                <i className="ri-share-line"></i>
              </Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Print</Tooltip>}>
              <Nav.Link href="">
                <i className="ri-printer-line"></i>
              </Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Report</Tooltip>}>
              <Nav.Link href="">
                <i className="ri-bar-chart-2-line"></i>
              </Nav.Link>
            </OverlayTrigger>
          </Nav>
        </div>

        <Row className="g-3">
          {[
            {
              label: "Unique Purchases",
              icon: "ri-shopping-bag-3-line",
              value: "8,327",
              percent: "0.7",
              status: "down",
            },
            {
              label: "Order Value",
              icon: "ri-briefcase-4-line",
              value: "₦12,105",
              percent: "2.1",
              status: "up",
            },
            {
              label: "Order Quantity",
              icon: "ri-inbox-line",
              value: "4,598",
              percent: "0.3",
              status: "down",
            },
            {
              label: "Conversion Rate",
              icon: "ri-bar-chart-box-line",
              value: "6.28%",
              percent: "1.2",
              status: "up",
            },
          ].map((card, index) => (
            <Col xs="6" xl="3" key={index}>
              <Card className="card-one">
                <Card.Body>
                  <Card.Title as="label" className="fs-sm fw-medium mb-1">
                    {card.label}
                  </Card.Title>
                  <h3 className="card-value mb-1">
                    <i className={card.icon}></i> {card.value}
                  </h3>
                  <small>
                    <span
                      className={
                        "d-inline-flex text-" +
                        (card.status === "up" ? "success" : "danger")
                      }
                    >
                      {card.percent}%{" "}
                      <i
                        className={
                          "ri-arrow-" +
                          (card.status === "up" ? "up" : "down") +
                          "-line"
                        }
                      ></i>
                    </span>{" "}
                    than last week
                  </small>
                </Card.Body>
              </Card>
            </Col>
          ))}
          <Col xl="7">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Leads Generated by Source - Chart</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <Bar data={dataBar} options={optionBar3} className="ht-300" />
              </Card.Body>
              <Card.Footer>
                <ul className="legend mb-3">
                  <li>Growth Actual</li>
                  <li>Actual</li>
                  <li>Plan</li>
                </ul>
                <div className="p-2">
                  <Row className="g-3">
                    <Col sm="6">
                      <h3 className="card-value mb-2">
                        <span>$</span>834,631.18
                      </h3>
                      <label className="card-title fw-semibold text-dark mb-2">
                        Monthly Revenue Growth
                      </label>
                      <p className="mb-0 fs-xs text-secondary">
                        Measure how fast you're growing monthly recurring
                        revenue.
                      </p>
                    </Col>
                    <Col sm="6">
                      <h3 className="card-value mb-2">
                        <span>$</span>19,204.15
                      </h3>
                      <label className="card-title fw-semibold text-dark mb-2">
                        Monthly Revenue/Customer
                      </label>
                      <p className="mb-0 fs-xs text-secondary">
                        The revenue generated per account on a monthly or yearly
                        basis.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="6" xl="5">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Leads Generated by Source</Card.Title>
                <Nav as="nav" className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <Table className="table-two mb-4" responsive>
                  <thead>
                    <tr>
                      <th>Month</th>
                      <th>Direct Traffic</th>
                      <th>Email Marketing</th>
                      <th>Organic Search</th>
                      <th>Paid Search</th>
                      <th>Referrals</th>
                      <th>Social Media</th>
                      <th>Other Campaigns</th>
                      <th>Offline Sources</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      {
                        month: "Jan-13",
                        "Direct Traffic": 50,
                        "Email Marketing": 50,
                        "Organic Search": 50,
                        "Paid Search": 50,
                        Referrals: 50,
                        "Social Media": 50,
                        "Other Campaigns": 50,
                        "Offline Sources": "n/a",
                      },
                      {
                        month: "Jan-13",
                        "Direct Traffic": 50,
                        "Email Marketing": 50,
                        "Organic Search": 50,
                        "Paid Search": 50,
                        Referrals: 50,
                        "Social Media": 50,
                        "Other Campaigns": 50,
                        "Offline Sources": "n/a",
                      },
                      {
                        month: "Jan-13",
                        "Direct Traffic": 50,
                        "Email Marketing": 50,
                        "Organic Search": 50,
                        "Paid Search": 50,
                        Referrals: 50,
                        "Social Media": 50,
                        "Other Campaigns": 50,
                        "Offline Sources": "n/a",
                      },
                      {
                        month: "Jan-13",
                        "Direct Traffic": 50,
                        "Email Marketing": 50,
                        "Organic Search": 50,
                        "Paid Search": 50,
                        Referrals: 50,
                        "Social Media": 50,
                        "Other Campaigns": 50,
                        "Offline Sources": "n/a",
                      },
                      {
                        month: "Jan-13",
                        "Direct Traffic": 50,
                        "Email Marketing": 50,
                        "Organic Search": 50,
                        "Paid Search": 50,
                        Referrals: 50,
                        "Social Media": 50,
                        "Other Campaigns": 50,
                        "Offline Sources": "n/a",
                      },

                      {
                        month: "Jan-13",
                        "Direct Traffic": 50,
                        "Email Marketing": 50,
                        "Organic Search": 50,
                        "Paid Search": 50,
                        Referrals: 50,
                        "Social Media": 50,
                        "Other Campaigns": 50,
                        "Offline Sources": "n/a",
                      },
                      {
                        month: "Jan-13",
                        "Direct Traffic": 50,
                        "Email Marketing": 50,
                        "Organic Search": 50,
                        "Paid Search": 50,
                        Referrals: 50,
                        "Social Media": 50,
                        "Other Campaigns": 50,
                        "Offline Sources": "n/a",
                      },
                      {
                        month: "Jan-13",
                        "Direct Traffic": 50,
                        "Email Marketing": 50,
                        "Organic Search": 50,
                        "Paid Search": 50,
                        Referrals: 50,
                        "Social Media": 50,
                        "Other Campaigns": 50,
                        "Offline Sources": "n/a",
                      },
                      {
                        month: "Jan-13",
                        "Direct Traffic": 50,
                        "Email Marketing": 50,
                        "Organic Search": 50,
                        "Paid Search": 50,
                        Referrals: 50,
                        "Social Media": 50,
                        "Other Campaigns": 50,
                        "Offline Sources": "n/a",
                      },
                      {
                        month: "Jan-13",
                        "Direct Traffic": 50,
                        "Email Marketing": 50,
                        "Organic Search": 50,
                        "Paid Search": 50,
                        Referrals: 50,
                        "Social Media": 50,
                        "Other Campaigns": 50,
                        "Offline Sources": "n/a",
                      },
                      {
                        month: "Jan-13",
                        "Direct Traffic": 50,
                        "Email Marketing": 50,
                        "Organic Search": 50,
                        "Paid Search": 50,
                        Referrals: 50,
                        "Social Media": 50,
                        "Other Campaigns": 50,
                        "Offline Sources": "n/a",
                      },
                      {
                        month: "Jan-13",
                        "Direct Traffic": 50,
                        "Email Marketing": 50,
                        "Organic Search": 50,
                        "Paid Search": 50,
                        Referrals: 50,
                        "Social Media": 50,
                        "Other Campaigns": 50,
                        "Offline Sources": "n/a",
                      },

                      // Include data for other months similarly
                    ].map((item, index) => (
                      <tr key={index}>
                        <td>{item.month}</td>
                        <td>{item["Direct Traffic"]}</td>
                        <td>{item["Email Marketing"]}</td>
                        <td>{item["Organic Search"]}</td>
                        <td>{item["Paid Search"]}</td>
                        <td>{item["Referrals"]}</td>
                        <td>{item["Social Media"]}</td>
                        <td>{item["Other Campaigns"]}</td>
                        <td>{item["Offline Sources"]}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <Card className="p-3 d-flex flex-row mb-2">
                  <div className="card-icon bg-primary">
                    <i className="ri-bar-chart-grouped-line"></i>
                  </div>
                  <div className="ms-3">
                    <h4 className="card-value mb-1">58394</h4>
                    <label className="card-title fw-medium text-dark mb-1">
                      Direct Traffic
                    </label>
                    <p className="fs-xs text-secondary mb-0 lh-4">
                      Highest Channel this Month - December
                    </p>
                  </div>
                </Card>
                <Card className="p-3 d-flex flex-row">
                  <div className="card-icon bg-ui-02">
                    <i className="ri-bar-chart-grouped-line"></i>
                  </div>
                  <div className="ms-3">
                    <h4 className="card-value mb-1">8</h4>
                    <label className="card-title fw-medium text-dark mb-1">
                      Channels
                    </label>
                    <p className="fs-xs text-secondary mb-0 lh-4">
                      Wheree we get 
                    </p>
                  </div>
                </Card>
              </Card.Body>
            </Card>
          </Col>

          <Col xl="5">
            <Card className="card-analytics mb-3">
              <Card.Header>
                <Card.Title as="h6">Total Leads Generated</Card.Title>
                <Nav as="nav" className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-3 p-xl-4">
                <Table className="table-two mb-4" responsive>
                  <thead>
                    <tr>
                      <th></th>
                      {[
                        "Jan-13",
                        "Feb-13",
                        "Mar-13",
                        "Apr-13",
                        "May-13",
                        "Jun-13",
                        "Jul-13",
                        "Aug-13",
                        "Sep-13",
                        "Oct-13",
                        "Nov-13",
                        "Dec-13",
                      ].map((month, index) => (
                        <th key={index}>{month}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Total Visits</td>
                      {[
                        50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160,
                      ].map((value, index) => (
                        <td key={index}>{value}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Month-On-Month(%)</td>
                      {[
                        50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 155, 170,
                      ].map((value, index) => (
                        <td key={index}>{value}</td>
                      ))}
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            <Card className="card-analytics ">
              <Card.Header>
                <Card.Title as="h6">Audit History</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-0">
                <ul className="people-group">
                  {[
                    {
                      bg: "teal",
                      icon: "ri-secure-payment-line",
                      label: "Data update",
                      date: "Oct 21, 2023, 3:30pm",
                      value: "Google",
                      status: "External Source",
                      color: "success",
                    },
                   
                    {
                      bg: "primary",
                      icon: "ri-secure-payment-line",
                      label: "Data Deletion",
                      date: "Oct 18, 2023, 6:18pm",
                      value: "Blessing Chukwubuogum",
                      status: "Internal Source",
                      color: "info",
                    },
                   
                  ].map((item, index) => (
                    <li className="people-item" key={index}>
                      <div className="avatar">
                        <span className={"avatar-initial fs-20 bg-" + item.bg}>
                          <i className={item.icon}></i>
                        </span>
                      </div>
                      <div className="people-body">
                        <h6>
                          <Link to="">{item.label}</Link>
                        </h6>
                        <span>{item.date}</span>
                      </div>
                      <div className="text-end">
                        <div className="fs-sm">{item.value}</div>
                        <span className={"d-block fs-xs text-" + item.color}>
                          {item.status}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col xl="7">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Total Leads Generated Chart</Card.Title>
                <Nav as="nav" className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-3 p-xl-4">
                <Line data={dataLine} options={optionLine} className="ht-300" />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}
