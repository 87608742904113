import axios from "axios";

const GET_REPORT_FOR_LAST_7_DAYS ="https://mtroflow-fksngumbvq-lz.a.run.app/report/team/?type=WEEKLY";
const GET_REPORT_FOR_MANAGER_DAILY ="https://mtroflow-fksngumbvq-lz.a.run.app/report/team/manager?type=DAILY";
const GET_REPORT_FOR_ACTIVITY ="https://mtroflow-fksngumbvq-lz.a.run.app/report/activity/"
const GET_REPORT_FOR_HR_EACH_TEAM_ACTIVITY ="https://mtroflow-fksngumbvq-lz.a.run.app/report/hr/activity/?teamId="
const GET_REPORT_FOR_LAST_7_DAYS_HR ="https://mtroflow-fksngumbvq-lz.a.run.app/report/hr/teams/?type=WEEKLY&teamId=";
const GET_REPORT_FOR_MANGER_DAILY_HR ="https://mtroflow-fksngumbvq-lz.a.run.app/report/hr/manager?type=DAILY&teamId=";
const GET_REPORT_FOR_HR_RANGE ="https://mtroflow-fksngumbvq-lz.a.run.app/report/teams/";
const GET_REPORT_FOR_MANAGER_RANGE ="https://mtroflow-fksngumbvq-lz.a.run.app/report/teams/manager";


class reportService {

    getReportForAnEmployeeByRange(token, reportDto) {

        return axios.post("https://mtroflow-fksngumbvq-lz.a.run.app/report/employee/create", reportDto, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }
    getHRReportForActivityPerTeam(token, teamId) {

        return axios.get(GET_REPORT_FOR_HR_EACH_TEAM_ACTIVITY + teamId, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }
    getHRReportForLast7DaysPerTeam(token, teamId) {

        return axios.get(GET_REPORT_FOR_LAST_7_DAYS_HR + teamId, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    }

    getHRReportForEACHMANGERDAILY(token, teamId) {

        return axios.get(GET_REPORT_FOR_MANGER_DAILY_HR + teamId, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }

       getHRReportForTeamByRange(token, teamId, startDate, endDate) {

        return axios.get(`${GET_REPORT_FOR_HR_RANGE}?teamId=${teamId}&startDate=${startDate}&endDate=${endDate}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }

    getManagerReportForTeamByRange(token, startDate, endDate) {

        return axios.get(`${GET_REPORT_FOR_MANAGER_RANGE}?startDate=${startDate}&endDate=${endDate}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }
    getReportForLast7Days(token) {



        return axios.get(GET_REPORT_FOR_LAST_7_DAYS, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }
    getReportForManagerDaily(token) {


        return axios.get(GET_REPORT_FOR_MANAGER_DAILY, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });



    }
    getReportForActivity(token) {
        return axios.get(GET_REPORT_FOR_ACTIVITY, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    }



    // Implement other methods with similar changes...
}

export const ReportService = new reportService();
