import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Row, Table, Spinner } from "react-bootstrap";
import Avatar from "../components/Avatar";
import img6 from "../assets/img/img6.jpg";
import projects from "../assets/img/projects.png";
import Swal from "sweetalert2";
import ReactDOMServer from "react-dom/server";
import { TaskPostService } from "../service/TaskPostService";
import DuplicateWorkPlan from "./DuplicateWorkPlan";
import DuplicateWorkPlanTeam from "./DuplicateWorkPlanTeam";
import { ProjectService } from "../service/ProjectService";

export default function SingleWorkPlanDetails(props) {
  let userDetails = {};
  const userDetailsJSON = localStorage.getItem("userDetails");
  userDetails = JSON.parse(userDetailsJSON);
  const userRole = userDetails ? userDetails.role : "";
  const allowedRoles = ["MANAGER", "HR"];
  const isManager = allowedRoles.includes(userRole);
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const closeModal = props.closeModal;
  const [isEditMode, setIsEditMode] = useState(false);

  // const [isLoading,setIsLoading]= useState(false);

  const showLoadingModal = () => {
    // Convert the React component to an HTML string
    const spinnerHtml = ReactDOMServer.renderToString(
      <Spinner animation="border" />
    );

    // Open Swal modal with the Bootstrap Spinner component
    Swal.fire({
      title: "Processing",
      html: `
            <div class="text-center">
              ${spinnerHtml}
            </div>
          `,
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
  };

  // Function to hide the loading modal
  const hideLoadingModal = () => {
    // Close the Swal modal
    Swal.close();
  };

  const navigate = useNavigate();

  const handleDelete = (projectId) => {
    if (!isManager){
        Swal.fire("Stop!", "You are not authorized for this action", "error");
        return;
      }
    showLoadingModal();
    ProjectService.deleteProjectAndTasks(
      localStorage.getItem("token"),
      projectId
    )
      .then((response) => {
        // Handle successful deletion, e.g., show a success message
        console.log("Project deleted successfully:", response.data);
        hideLoadingModal();
      })
      .catch((error) => {
        Swal.fire("Error", error.response.result, "success");
        hideLoadingModal();
      });
  };

  const lgHandleClose = () => {
    // setLgShow(false);
    closeModal();
  };
  const selectedProject = props.selectedProject;

  const handleApproval = (projectId) => {
    if (!isManager){
        Swal.fire("Stop!", "You are not authorized for this action", "error");
        return;
      }
    showLoadingModal();
    TaskPostService.approveWorkPlan(
      projectId,
      localStorage.getItem("token"),
      null
    )
      .then((response) => {
        if (response.status === 200) {
          Swal.fire("Workplan has been approved", "Wonderful", "success").then(
            (result) => {
              if (result.isConfirmed) {
                closeModal();
              }
            }
          );

          // hideLoadingModal();
        }
      })
      .catch((error) => {
        // hideLoadingModal();
        Swal.fire("Error", error.response.data.result, "error").then(
          (result) => {
            if (result.isConfirmed) {
              closeModal();
            }
          }
        );
      });
  };

  return (
    <React.Fragment>
      <Card className="card-event">
        <Link to="#">
          <img src={projects} className="card-img-top" alt="" />
        </Link>
        <Card.Body>
          <h6>
            <Link to="">{selectedProject.name}</Link>
          </h6>
          <p className="mb-0">
            {selectedProject.startDate + " - " + selectedProject.endDate}
          </p>
          <p className="text-secondary fs-sm">{selectedProject.name}</p>

          <div className="mutual-badge">
            <ul>
              {/*        {event.mutual.map((img, ind) => (*/}
              <li>
                <Avatar img={img6} />
              </li>
              {/*        ))}*/}
            </ul>
            <label>
              {" "}
              You and {selectedProject.assigneeCount} others are part of this.
            </label>{" "}
          </div>

          <Card className="card-one">
            <Card.Header>
              <Card.Title as="h6">Tasks Details</Card.Title>
            </Card.Header>
            <Card.Body>
              {selectedProject.assignedTasksDetails.length > 0 && (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Task Description</th>
                      <th>Due Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedProject.assignedTasksDetails.map((item) => (
                      <tr key={item.taskId}>
                        <td>{item.name}</td>
                        <td>{item.taskDescription}</td>
                        <td>{item.dueDate}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Card.Body>
        <Card.Footer>
          <Button
            variant="primary"
            onClick={() => handleApproval(selectedProject.id)}
          >
            Approve WorkPlan
          </Button>

          {/* <Button variant="primary">Edit WorkPlan</Button> */}

          <Button variant="primary" onClick={() => setIsEditMode(true)}>
            Duplicate WorkPlan
          </Button>

          <Button
            variant="danger"
            onClick={() => handleDelete(selectedProject.id)}
          >
            Delete WorkPlan
          </Button>
        </Card.Footer>
      </Card>

      {isEditMode && isManager && (
        <DuplicateWorkPlanTeam
          workPlanData={selectedProject}
          handleClose={() => setIsEditMode(false)}
        />
      )}

      {isEditMode && !isManager && (
        <DuplicateWorkPlan
          workPlanData={selectedProject}
          handleClose={() => setIsEditMode(false)}
        />
      )}
    </React.Fragment>
  );
}
