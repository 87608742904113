import React, { useContext, useEffect, useState } from "react";
import Header from "../../../layouts/Header";
import { Col, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import SingleTask from "../../../components/SingleTask";
import TaskContext from "../../../context/TaskContext";
import { TaskService } from "../../../service/TaskService";
import Sidebar from "../../../layouts/Sidebar";
import TaskListing from "../../../apps/TaskListing";
import Lottie from "lottie-react";
import animationData from "../../../assets/animation/animation_lmawejkm.json";
import TeamDropdown from "../../../components/TeamDropdown";

export default function TeamNewTasks() {
  useEffect(() => {
    document.body.classList.add("app-task");
    return () => {
      document.body.classList.remove("app-task");
    };
  }, []);

  const { selectedTask, updateTask } = useContext(TaskContext);
  const { updateModalState } = useContext(TaskContext);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = useState(false);

  function handleTaskClick(task) {
    // pass the task to the context
    updateTask(task);
    //open modal
    updateModalState(true);
  }

  const handleItemClick = (team) => {
    setLoading(true);

    // Array of promises representing the asynchronous tasks
    const tasks = [
      TaskService.getAllTasksByUserAndStatus(
        localStorage.getItem("token"),
        "NEW",
        team.users_id
      ).then((res) => {
        setData(res.data.result);
      }),
    ];

    // Execute all promises and set loading to false when all tasks are done
    Promise.all(tasks)
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };




  const highPriorityTasks = data.filter((task) => task.priority === "HIGH");
  const mediumPriorityTasks = data.filter((task) => task.priority === "MEDIUM");
  const lowPriorityTasks = data.filter(
    (task) => task.priority === "LOW" || task.priority === ""
  );

  return (
    <React.Fragment>
      <Sidebar />
      <Header />
      <div className="main main-app p-3 p-lg-4 p-xxl-5">
        <Row className="row-cols-auto g-4 g-xxl-5">
          <Col xl>
            <h2 className="main-title fs-4 mb-1">
              Welcome to your Task Manager!
            </h2>
            <p className="text-secondary mb-4">
              Streamline your Workflow with Ease
            </p>

            <Nav className="nav-line mb-4">
              <Nav.Link href="" className="nav-link active">
                New Tasks from your team
              </Nav.Link>
            </Nav>

            <TeamDropdown handleItemClick={handleItemClick} />
            <br />

            {loading && (
              <div className={`fullscreen-loader ${loading ? "show" : "hide"}`}>
                <Lottie
                  animationData={animationData}
                  loop={true}
                  autoPlay={true}
                />
              </div>
            )}
            {!loading && (
              <Row className="g-3 g-lg-4">
                <Col md>
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <label className="task-label">High Priority</label>
                    <div className="dropdown dropdown-task">
                      <Link to="" className="dropdown-link">
                        Date Added <i className="ri-arrow-down-s-line"></i>
                      </Link>
                    </div>
                  </div>
                  {/* first 5 tasks*/}
                  <TaskListing
                    taskList={highPriorityTasks}
                    taskClickFunction={handleTaskClick}
                  />
                </Col>
                <Col md>
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <label className="task-label">Medium Priority</label>
                    <div className="dropdown dropdown-task">
                      <Link to="" className="dropdown-link">
                        Date Added <i className="ri-arrow-down-s-line"></i>
                      </Link>
                    </div>
                  </div>

                  {/*next 5*/}
                  <TaskListing
                    taskList={mediumPriorityTasks}
                    taskClickFunction={handleTaskClick}
                  />
                </Col>
                <Col md className="mt-5 mt-md-3 mt-lg-4">
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <label className="task-label"> Low Priority</label>
                    <div className="dropdown dropdown-task">
                      <Link to="" className="dropdown-link">
                        Date Added <i className="ri-arrow-down-s-line"></i>
                      </Link>
                    </div>
                  </div>

                  {/*next 5*/}
                  <TaskListing
                    taskList={lowPriorityTasks}
                    taskClickFunction={handleTaskClick}
                  />
                </Col>
              </Row>
            )}
            {selectedTask && <SingleTask />}
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}
