import React, {useEffect, useState} from "react";

import { Button, Card, Col, Row, Nav, Table, ProgressBar } from "react-bootstrap";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import {Link, useNavigate} from "react-router-dom";
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import {TaskService} from "../service/TaskService";
import Sidebar from "../layouts/Sidebar";

export default function Overview() {

  const token = localStorage.getItem("token");
  const isLoggedIn = !!token;
  let userDetails = {};
  const userDetailsJSON = localStorage.getItem('userDetails');
  userDetails = JSON.parse(userDetailsJSON);


  //Current Ticket Status

  const ctxLabel = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const ctxData1 = [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30];
  const ctxData2 = [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20];
  const ctxColor1 = '#506fd9';
  const ctxColor2 = '#DBDFFD';

  const dataBar = {
    labels: ctxLabel,
    datasets: [{
      data: ctxData1,
      backgroundColor: ctxColor1,
      barPercentage: 0.5
    }, {
      data: ctxData2,
      backgroundColor: ctxColor2,
      barPercentage: 0.5
    }]
  };

  const optionBar = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        type: 'linear', 
        beginAtZero: true,
        max: 80
      }
    }
  };





  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  switchSkin(skin);
  const [reportData, setReportData] = React.useState([]);

  useEffect(() => {
    TaskService.getReportForAnEmployee(localStorage.getItem("token"))
        .then((res) => {
          setReportData(res.data.result);
        })
        .catch((err) => {
          console.log(err);
        });

    switchSkin(skin);
  }, [skin, setReportData]);


  const navigate = useNavigate();
  useEffect(() => {
  if (!isLoggedIn) {
    navigate("/login", { replace: true });
  }
  }, [isLoggedIn,]);


  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link href="#">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Overview</li>
            </ol>
            <br/>
            <h4 className="main-title mb-0">Hello, {userDetails ? userDetails.firstName :  ''}</h4>
            <br/>
            <p >Welcome to your Personal Dashboard</p>
          </div>
          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
            <Button variant="white" className="btn-icon"><i className="ri-share-line fs-18 lh-1"></i></Button>
            <Button variant="white" className="btn-icon"><i className="ri-printer-line fs-18 lh-1"></i></Button>
            <Button variant="primary" className="btn-icon"><i className="ri-bar-chart-2-line fs-18 lh-1"></i></Button>
          </div>
        </div>

        <Row className="g-3">
          {/*first row of reportss*/}
          {[
            {
              "bg": "primary",
              "icon": "ri-bell-line",
              "value": reportData.newly_assignedTasks,
              "percent": "+0.00%",
              "success": true,
              "label": "New Tasks",

            }, {
              "bg": "ui-02",
              "icon": "ri-blaze-fill",
              "value": reportData.ongoingTasks,
              "percent": "+0.00%",
              "success": false,
              "label": "Ongoing Tasks",
            }, {
              "bg": "ui-03",
              "icon": "ri-star-smile-line",
              "value": reportData.tasks_pendingApproval,
              "percent": "+0.00%",
              "success": false,
              "label": "Tasks Pending Approval",

            },

          ].map((item, index) => (
            <Col sm="4" key={index}>
              <Card className="card-one">
                <Card.Body className="p-3">
                  <div className="d-flex d-sm-block d-xl-flex align-items-center">
                    <div className={"helpdesk-icon text-white bg-" + item.bg}><i className={item.icon}></i></div>
                    <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                      <h2 className="card-value d-flex align-items-baseline mb-0">{item.value} <small className={"text-" + (item.success ? "success" : "")}>{item.percent}</small></h2>
                      <label className="card-label fs-sm fw-medium mb-1">{item.label}</label>

                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>

          ))}
          {[
            {
              "bg": "primary",
              "icon": "ri-bell-line",
              "value": reportData.extensionRequests,
              "percent": "+0.00%",
              "success": true,
              "label": "Extension Requests",
              // "agents": 8
            }, {
              "bg": "ui-02",
              "icon": "ri-blaze-fill",
              "value": reportData.overdueTasks,
              "percent": "+0.00%",
              "success": false,
              "label": "Overdue Tasks",
              // "agents": 5
            }, {
              "bg": "ui-03",
              "icon": "ri-star-smile-line",
              "value": reportData.completedTasks,
              "percent": "+0.00%",
              "success": false,
              "label": "Completed Tasks",
              // "agents": 9
            },

          ].map((item, index) => (
              <Col sm="4" key={index}>
                <Card className="card-one">
                  <Card.Body className="p-3">
                    <div className="d-flex d-sm-block d-xl-flex align-items-center">
                      <div className={"helpdesk-icon text-white bg-" + item.bg}><i className={item.icon}></i></div>
                      <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                        <h2 className="card-value d-flex align-items-baseline mb-0">{item.value} <small className={"text-" + (item.success ? "success" : "")}>{item.percent}</small></h2>
                        <label className="card-label fs-sm fw-medium mb-1">{item.label}</label>

                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

          ))}
           {/*2nd report section*/}
          <Col md="7" xl="8">
            <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6"> Monthly Task Chart</Card.Title>
                </Card.Header>
              <Card.Body>
                <Bar data={dataBar} options={optionBar} className="ht-300" />
              </Card.Body>
            </Card>
          </Col>
          <Col md="5" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Performance Score</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <div className="d-flex align-items-baseline gap-2 mb-0">
                  <h1 className="card-value mb-0">N/A</h1>
                  <ProgressBar className="flex-fill ht-5">
                    <ProgressBar now={20} />
                    <ProgressBar now={15} variant="success" />
                    <ProgressBar now={15} variant="warning" />
                    <ProgressBar now={20} variant="info" />
                    <ProgressBar now={10} variant="danger" />
                  </ProgressBar>
                </div>
                <p className="fs-sm">The rating value assigned to the your completed tasks.</p>
                <Table className="table-ratings mb-0">
                  <tbody>
                  {[
                    {
                      "dot": "primary",
                      "label": "Excellent",
                      "count": "0",
                      "percent": "0%"
                    }, {
                      "dot": "success",
                      "label": "Very Good",
                      "count": "0",
                      "percent": "0%"
                    }, {
                      "dot": "warning",
                      "label": "Good",
                      "count": "0",
                      "percent": "0%"
                    }, {
                      "dot": "info",
                      "label": "Fair",
                      "count": "0",
                      "percent": "0%"
                    }, {
                      "dot": "danger",
                      "label": "Poor",
                      "count": "0",
                      "percent": "0%"
                    }
                  ].map((item, index) => (
                      <tr key={index}>
                        <td><span className={"badge-dot bg-" + item.dot}></span></td>
                        <td><strong>{item.label}</strong></td>
                        <td>{item.count}</td>
                        <td>{item.percent}</td>
                      </tr>
                  ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>


        </Row>

        <Footer />
      </div>
    </React.Fragment>
  )
}