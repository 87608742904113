import React, { useEffect, useState } from "react";

import Footer from "../../layouts/Footer";
import {  Row } from "react-bootstrap";
import {
  getCurrentDate,
  showLoadingModal,
  hideLoadingModal
} from "../../logic/Utils"; 

import Swal from "sweetalert2"
import MetricsOverview from "./components/MetricsOverview";
import MetricsByPeriod from "./components/MetricsByPeriod";
import MetricsBySource from "./components/MetricsBySource";
import { AnalyticsService } from "../../service/AnalyticsService";
export default function SalesOverview() {
 
  const [salesOverview, setSalesOverview] = useState(null);
  const userDetails = useState(
    JSON.parse(localStorage.getItem("userDetails")) || {}
  );
  function checkPermission(userDetails) {
   
    const result = (
      (userDetails.role == "MANAGER" && userDetails.department == "SALES") ||
      userDetails.department == "EXCO" ||
      userDetails.role == "ADMIN"
  );

    return result;
}


 
  useEffect(() => {


    if (checkPermission(userDetails[0]) ){
      showLoadingModal();
      AnalyticsService.getSalesOverview(
     localStorage.getItem("token"),
   )
     .then((res) => {
       const salesMap = res.data.result;
      
       setSalesOverview(salesMap);
       hideLoadingModal();
     
     })
     .catch((err) => {
       hideLoadingModal();
       console.log(err);
     });
    }

  
   
  }, []);

  return (
    <React.Fragment>
      
       
        <Row  style={{ marginTop: "15px" }}className="g-3">
         {
          salesOverview!=null && (
        <div>
        <MetricsOverview metrics={salesOverview}/>
         <MetricsByPeriod metrics={salesOverview}/>
         <MetricsBySource metrics={salesOverview}/>
         <Footer/>
        </div>
          )
         }

         {
          !checkPermission(userDetails[0]) && (
       <div>
        <h1>Not Authorized</h1>
        <p>You are not authorized to view this page.</p>

       </div>
          )
         }

       
       
        </Row>

       
     
    </React.Fragment>
  );
}