import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import {AuthService} from "../service/AuthService";
import SigninModal from "../components/SigninModal";



const LogoutAndRedirect = () => {
    const navigate = useNavigate();
    const [isAuthenticated,setIsAuthenticated] = useState(true);



    useEffect(() => {

        const token = localStorage.getItem('token');




            if (!token) {

                console.log("no token found")

// Get the pathname
                const pathname = window.location.pathname;

// Get the query string (including the "?")
                const queryString = window.location.search;

// Get specific query parameters
                const urlParams = new URLSearchParams(queryString);
                const taskParam = urlParams.get('task'); // Get the value of the "task" parameter

// Combine them all if needed, including query parameters
                let intendedUrl = pathname;
                if (queryString) {
                    intendedUrl += queryString;
                }

                localStorage.setItem('intendedUrl', intendedUrl);

                console.log(intendedUrl)

                setIsAuthenticated(false);

                // navigate('/login', { replace: true });
        }

            else {
            const isTokenExpired = () => {
                const decodedToken = jwtDecode(token);
                if (decodedToken.exp) {
                    const expirationTime = decodedToken.exp * 1000; // Multiply by 1000 to convert seconds to milliseconds
                    const currentTime = new Date().getTime();
                    return currentTime > expirationTime;
                }
                return true; // If the token doesn't have an expiration time, consider it as expired
            };

            if (isTokenExpired()) {
                handleLogout();
            }
        }
        }, []);

    const handleLogout = () => {

        // Call logout service
        AuthService.logout(localStorage.getItem('token'))
            .then((res) => {
                if (res.status === 200) {
                    console.log('logout success');
                    // navigate('/login', { replace: true });
                }
            })
            .catch((err) => {
                console.log(err);
            });

        // Clear the stored JWT token
        localStorage.removeItem('token');
        localStorage.removeItem('userDetails');
        // Get the pathname
        const pathname = window.location.pathname;

// Get the query string (including the "?")
        const queryString = window.location.search;

// Get specific query parameters
        const urlParams = new URLSearchParams(queryString);
        const taskParam = urlParams.get('task'); // Get the value of the "task" parameter

// Combine them all if needed, including query parameters
        let intendedUrl = pathname;
        if (queryString) {
            intendedUrl += queryString;
        }

        localStorage.setItem('intendedUrl', intendedUrl);
        // navigate('/login', { replace: true });
        setIsAuthenticated(false);
    };

    return(
        <React.Fragment>
            {!isAuthenticated && (
                <SigninModal/>
            )}
        </React.Fragment>
    )// You can return null or an empty fragment since this component doesn't render anything
};

export default LogoutAndRedirect;
