import React, {useContext, useEffect} from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import {Badge, Card, Col, Nav, ProgressBar, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import SingleTask from "../components/SingleTask";
import TaskContext from "../context/TaskContext";
import {TaskService} from "../service/TaskService";
import Sidebar from "../layouts/Sidebar";


export default function Tasks() {
    useEffect(() => {
        document.body.classList.add('app-task')
        return () => {
            document.body.classList.remove('app-task')
        }
    }, []);


    const {selectedTask, updateTask} = useContext(TaskContext);
    const {updateModalState} = useContext(TaskContext);
    const [data, setData] = React.useState([]);
    const [progressTasks, setProgressTasks] = React.useState([]);
    const [pendingApprovalTasks, setPendingApprovalTasks] = React.useState([]);


    function handleTaskClick(task) {

        // pass the task to the context
        updateTask(task);
        //open modal
        updateModalState(true);
    }

    useEffect(() => {
        TaskService.getAllNewTasksCreated(localStorage.getItem("token"))
            .then((res) => {
                setData(res.data.result);
            })
            .catch((err) => {
                console.log(err);
            });

        TaskService.getAllInProgressTasksCreated(localStorage.getItem("token"))
            .then((res) => {
                setProgressTasks(res.data.result);
            })
            .catch((err) => {
                console.log(err);

            });

        TaskService.getAllPendingApprovalTasksCreated(localStorage.getItem("token"))
            .then((res) => {
                setPendingApprovalTasks(res.data.result);
            })
            .catch((err) => {
                console.log(err);
            });

    }, [ setData, setPendingApprovalTasks, setProgressTasks]);




  return (
      <React.Fragment>
          <Sidebar />
        <Header />
        <div className="main main-app p-3 p-lg-4 p-xxl-5">
          <Row className="row-cols-auto g-4 g-xxl-5">
            <Col xl>
              <h2 className="main-title fs-4 mb-1">Welcome to your Task Manager!</h2>
              <p className="text-secondary mb-4">Streamline your Workflow with Ease</p>

              <Nav className="nav-line mb-4">
                <Nav.Link href="" className="nav-link active" >
                  Your Active Tasks
                </Nav.Link>
                {/*<Nav.Link  href="" className="nav-link" >*/}
                {/*  Completed Tasks*/}
                {/*</Nav.Link>*/}
                {/*<Nav.Link href="" className="nav-link" >*/}
                {/*  Overdue Tasks*/}
                {/*</Nav.Link>*/}
              </Nav>
                <Row className="g-3 g-lg-4">

                    <Col md>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <label className="task-label">Newly Assigned</label>
                            <div className="dropdown dropdown-task">
                                <Link to="" className="dropdown-link">Date Added <i
                                    className="ri-arrow-down-s-line"></i></Link>
                            </div>
                        </div>
                        {/*new tasks*/}
                        {data.map((task, index) => (
                            <Card className="card-task" key={task.taskId} onClick={() => handleTaskClick(task)}>
                                <Card.Body className="p-3 pb-1">
                                    {/* Render task data */}
                                    <div className="d-flex gap-1">
                                        {/*  {task.taskType}  */}
                                        {/*{task.badge.map((badge, ind) => (*/}
                                        <Badge key="0" bg="success">
                                            {task.taskType}
                                        </Badge>
                                        {/*))}*/}
                                    </div>
                                    <div
                                        className="d-flex flex-row-reverse align-items-center justify-content-between mt-2 mb-1">
                                        <span className="card-date">{"Deadline:" + task.dueDate}</span>
                                        <Card.Title as="h6">{task.name}</Card.Title>
                                    </div>
                                    <p className="fs-xs">
                                        Assigned to: <Link to="">{task.assignees}</Link>
                                    </p>
                                    {task.text && <p className="fs-sm">{task.taskDescription}</p>}

                                    <div
                                        className="d-flex align-items-center justify-content-between fs-xs text-secondary mb-1">
              <span>
                <strong className="fw-medium">{task.progress}%</strong> complete
              </span>
                                        <span>{task.hour}</span>
                                    </div>
                                    <ProgressBar now={task.progress} className="mb-2"/>
                                </Card.Body>
                                <Card.Footer className="p-3 pt-0">
                                    <p className="fs-xs">
                                        Assigned by: <Link to="">{task.creator}</Link>
                                    </p>
                                    <Nav as="nav">
                                        <Nav.Link href="">
                                            <i className="ri-eye-2-line"></i> {task.views}
                                        </Nav.Link>
                                        <Nav.Link href="">
                                            <i className="ri-chat-1-line"></i> {task.comments}
                                        </Nav.Link>
                                    </Nav>
                                </Card.Footer>
                            </Card>
                        ))}
                        {data.length === 0 && (
                            <Card>
                                <Card.Body>
                                    <div className="text-center">
                                        <h4 className="mb-3">No Tasks Found</h4>
                                    </div>
                                </Card.Body>
                            </Card>
                        )}

                    </Col>
                    <Col md>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <label className="task-label">In Progress</label>
                            <div className="dropdown dropdown-task">
                                <Link to="" className="dropdown-link">Date Added <i
                                    className="ri-arrow-down-s-line"></i></Link>
                            </div>
                        </div>

                        {/*in progress tasks*/}
                        {progressTasks.map((task, index) => (
                            <Card className="card-task" key={index} onClick={() => handleTaskClick(task)}>
                                <Card.Body className="p-3 pb-1">
                                    {/* Render task data */}
                                    <div className="d-flex gap-1">
                                        {/*  {task.taskType}  */}
                                        {/*{task.badge.map((badge, ind) => (*/}
                                        <Badge key="0" bg="success">
                                            {task.taskType}
                                        </Badge>
                                        {/*))}*/}
                                    </div>
                                    <div
                                        className="d-flex flex-row-reverse align-items-center justify-content-between mt-2 mb-1">
                                        <span className="card-date">{"Deadline:" + task.dueDate}</span>
                                        <span className="card-date">{task.dueDate}</span>
                                        <Card.Title as="h6">{task.name}</Card.Title>
                                    </div>
                                    <p className="fs-xs">
                                        Assigned to: <Link to="">{task.assignees}</Link>
                                    </p>
                                    {task.text && <p className="fs-sm">{task.taskDescription}</p>}

                                    <div
                                        className="d-flex align-items-center justify-content-between fs-xs text-secondary mb-1">
              <span>
                <strong className="fw-medium">{task.progress}%</strong> complete
              </span>
                                        <span>{task.hour}</span>
                                    </div>
                                    <ProgressBar now={task.progress} className="mb-2"/>
                                </Card.Body>
                                <Card.Footer className="p-3 pt-0">
                                    <p className="fs-xs">
                                        Assigned by: <Link to="">{task.creator}</Link>
                                    </p>
                                    <Nav as="nav">
                                        <Nav.Link href="">
                                            <i className="ri-eye-2-line"></i> {task.views}
                                        </Nav.Link>
                                        <Nav.Link href="">
                                            <i className="ri-chat-1-line"></i> {task.comments}
                                        </Nav.Link>
                                    </Nav>
                                </Card.Footer>

                            </Card>
                        ))}
                        {progressTasks.length === 0 && (
                            <Card>
                                <Card.Body>
                                    <div className="text-center">
                                        <h4 className="mb-3">No Tasks Found</h4>
                                    </div>
                                </Card.Body>
                            </Card>
                        )}


                    </Col>
                    <Col md className="mt-5 mt-md-3 mt-lg-4">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <label className="task-label">Pending Approval</label>
                            <div className="dropdown dropdown-task">
                                <Link to="" className="dropdown-link">Date Added <i
                                    className="ri-arrow-down-s-line"></i></Link>
                            </div>
                        </div>

                        {/*pending approval*/}
                        {pendingApprovalTasks.map((task, index) => (
                            <Card className="card-task" key={index} onClick={() => handleTaskClick(task)}>
                                <Card.Body className="p-3 pb-1">
                                    {/* Render task data */}
                                    <div className="d-flex gap-1">
                                        {/*  {task.taskType}  */}
                                        {/*{task.badge.map((badge, ind) => (*/}
                                        <Badge key="0" bg="success">
                                            {task.taskType}
                                        </Badge>
                                        {/*))}*/}
                                    </div>
                                    <div
                                        className="d-flex flex-row-reverse align-items-center justify-content-between mt-2 mb-1">
                                        <span className="card-date">{"Deadline:" + task.dueDate}</span>
                                        <span className="card-date">{task.dueDate}</span>
                                        <Card.Title as="h6">{task.name}</Card.Title>
                                    </div>
                                    <p className="fs-xs">
                                        Assigned to: <Link to="">{task.assignees}</Link>
                                    </p>
                                    {task.text && <p className="fs-sm">{task.taskDescription}</p>}

                                    <div
                                        className="d-flex align-items-center justify-content-between fs-xs text-secondary mb-1">
              <span>
                <strong className="fw-medium">{task.progress}%</strong> complete
              </span>
                                        <span>{task.hour}</span>
                                    </div>
                                    <ProgressBar now={task.progress} className="mb-2"/>
                                </Card.Body>
                                <Card.Footer className="p-3 pt-0">
                                    <p className="fs-xs">
                                        Assigned by: <Link to="">{task.creator}</Link>
                                    </p>
                                    <Nav as="nav">
                                        <Nav.Link href="">
                                            <i className="ri-eye-2-line"></i> {task.views}
                                        </Nav.Link>
                                        <Nav.Link href="">
                                            <i className="ri-chat-1-line"></i> {task.comments}
                                        </Nav.Link>
                                    </Nav>
                                </Card.Footer>

                            </Card>
                        ))}
                        {pendingApprovalTasks.length === 0 && (
                            <Card>
                                <Card.Body>
                                    <div className="text-center">
                                        <h4 className="mb-3">No Tasks Found</h4>
                                    </div>
                                </Card.Body>
                            </Card>
                        )}

                    </Col>
                </Row>
                {selectedTask && <SingleTask/>}

            </Col>
          </Row>
        </div>
      </React.Fragment>
  );

}