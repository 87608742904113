import React from "react";
import HrReports from "../../components/HrReports";

export default function HrTeamReports() {

    const teamId = 4;
    const teamName = "HR/Admin Team";
    return (
        <React.Fragment>
            <HrReports teamId={teamId} teamName={teamName} />
        </React.Fragment>
    );
}