import axios from "axios";


class taskPostService {


    startTask(taskId, token) {
        return axios.post(`https://mtroflow-fksngumbvq-lz.a.run.app/task/${taskId}/start`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    }

    submitTask(taskId, token) {
        return axios.post(`https://mtroflow-fksngumbvq-lz.a.run.app/task/${taskId}/submit`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    }

    cancelTask(taskId, token) {
        return axios.post(`https://mtroflow-fksngumbvq-lz.a.run.app/task/${taskId}/delete`,{}, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
        });
    }

    approveTask(taskId, token) {
        return axios.post(`https://mtroflow-fksngumbvq-lz.a.run.app/task/${taskId}/approve`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    }

    addComment(taskId, dto, token) {
        return axios.post(`https://mtroflow-fksngumbvq-lz.a.run.app/task/${taskId}/comment`, dto, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    }
    requestExtension(taskId, token) {
        return axios.post(`https://mtroflow-fksngumbvq-lz.a.run.app/task/${taskId}/request_extension`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    }
    approveExtension(taskId,newDueDate, token) {
        return axios.post(`https://mtroflow-fksngumbvq-lz.a.run.app/task/${taskId}/approve_extension?newDueDate=${newDueDate}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    }

    rateTask(taskId, token,ratingDto) {
        return axios.post(`https://mtroflow-fksngumbvq-lz.a.run.app/task/${taskId}/rate`,ratingDto, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    }

    approveWorkPlan(projectId, token,workPlan) {
        return axios.post(`https://mtroflow-fksngumbvq-lz.a.run.app/task/workplan/${projectId}/approve`,workPlan, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    }

    // Implement other methods with similar changes...
}

export const TaskPostService = new taskPostService();
