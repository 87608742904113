import React, {useState} from "react";
import {Alert, Button, Card, Form, Image, Spinner} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {AuthService} from "../service/AuthService";
import jwt_decode from "jwt-decode";
import favicon from "../assets/img/favicon.png";

export default function Signin(props) {
   const handleSuccess = props.successAction;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const navigate = useNavigate();
  const requestData = {
    email: email,
    password: password
  };

   const clickSubmit = () => {
     setIsLoading(true);
     setPasswordError(false);
   }

    const intendedUrl = localStorage.getItem('intendedUrl');

  const handleSubmit = async (e) => {
    e.preventDefault();

    clickSubmit();
    AuthService.login(requestData)
        .then((response) => {

          //Navigate to the Dashboard page & set context to true
          if (response.status === 200) {
            const decodedToken = jwt_decode(response.data.result.token)

           
            const newDetails = {
              firstName: response.data.result.firstName,
              lastName: response.data.result.lastName,
              email: decodedToken.sub,
              jobTitle: response.data.result.jobTitle,
              role: decodedToken.roles[0],
              department: response.data.result.department,
              userId: response.data.result.userId,
              
            };

            console.log(newDetails);

            localStorage.setItem('token', response.data.result.token)
            localStorage.setItem('userDetails', JSON.stringify(newDetails));
            const expirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds
            localStorage.setItem("expirationTime", expirationTime.toString());

          

            let destination = "/dashboard/overview";

              if (intendedUrl) {
                  destination = intendedUrl;
                  console.log(intendedUrl);
                  localStorage.removeItem('intendedUrl');
              }



            setTimeout(() => {

                navigate(destination, { replace: true });
                setIsLoading(false);
                handleSuccess();

            }, 2000); // Delay of 1 second (1000 milliseconds)
          }

       

    

        })
        .catch((error) => {
          if (error.response.data.result === "Bad credentials") {
            setPasswordError("Please reset your password to continue. Type in your official email address and check your mail");
            setIsLoading(false);
          

            setTimeout(() => {
              navigate("/forgot-password", { replace: true })
          }, 3000); 
          return;
          }

          if (error.response.data.result === "user does not exist") {
            setPasswordError("Please check your email address. No user was found");
            setIsLoading(false);

            return;
      
          }
          setPasswordError("Connection Error, please try again or check your internet connection");
          // navigate("/forgot-password", { replace: true });
          setIsLoading(false);
        });


  };
  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
          <div className="d-flex align-items-center">
            <Image src={favicon} fluid={true} width="20px" alt="..." className="mr-2" />
            <Link to="/" className="sidebar-logo" style={{ marginLeft: '10px' }}>
              MetroHealth
            </Link>

          </div>
          <Card.Title style={{ marginTop: '20px' }}>Sign In</Card.Title>
          <Card.Text>Welcome back! Please login to continue.</Card.Text>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <div className="mb-4">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="text" placeholder="Enter your email address"
                            value={email} onChange={(e) => setEmail(e.target.value)}/>
            </div>
            <div className="mb-4">
              <Form.Label className="d-flex justify-content-between">
                Password <Link to="/forgot-password">Forgot password?</Link>
              </Form.Label>
              <div className="d-flex align-items-center" style={{ position: 'relative' }}>
                  <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter your password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                  />
                  <Form.Check
                      type="switch"
                      checked={showPassword}
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 1,
                      }}
                      onChange={togglePasswordVisibility}
                  />
              </div>
            </div>

            {!isLoading && (
                <Button type="submit" className="btn-sign">Sign In</Button>
            )}
            {isLoading && (
                <Button  className="btn-sign">
                  Signing you in...<span/><Spinner  size="sm" animation="border" /></Button>
            )}

          </Form>
        </Card.Body>
        <Card.Footer>
        <p>Don't have login credentials? </p><a href="mailto:ict@metrohealthhmo.com">Request a Login Link</a>
        </Card.Footer>
        {passwordError && (
            <Alert key={"warning"} variant={"warning"} className="alert-solid">
              {passwordError}
            </Alert>
        )}
      </Card>
    </div>
  )
}