import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Modal, Form } from "react-bootstrap";

import { formatRevenue, showLoadingModal,hideLoadingModal } from "../../../logic/Utils";

import EditSalesRecord from "./EditSalesRecord";
import Swal from "sweetalert2";
import { AnalyticsService } from "../../../service/AnalyticsService";
import SalesRecordView from "./SalesRecordView";
const RecordsGrid = (props) => {
  const [selectedSalesRecord, setSelectedSalesRecord] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const rows = props.data;
  const userDetails = useState(
    JSON.parse(localStorage.getItem("userDetails")) || {}
  );


  const columns = [
    { 
      field: "id",
      headerName: "S/N",
      width: 25,
    },
{ field: "salesOfficerFullName", headerName: "Sales Officer Full Name", width: 250 },
  { field: "nameOfClient", headerName: "Name of Client", width: 100},
  { field: "revenueAmount", headerName: "Revenue Value",valueFormatter: (params) => formatRevenue(params.value), },
 
  { field: "totalPopulationCount", headerName: "Total Unit Sales" },
  { field: "dateOfSale", headerName: "Date of Sale"},
  { field: "createdBy", headerName: "Created / Updated By", width: 100 },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <>
        <Button
            variant="primary"
            style={{ marginRight: "10px" }}
            // startIcon={<EditIcon />}
            onClick={() => handleView(params.row)}
          >
            View
          </Button>
          <Button
            variant="success"
            style={{ marginRight: "10px" }}
            // startIcon={<EditIcon />}
            onClick={() => handleEdit(params.row)}
          >
            Edit
          </Button>
          <Button
            variant="warning"
            // startIcon={<DeleteIcon />}
            onClick={() => handleDelete(params.row)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  const handleDelete = (row) => {
    if (validateOwnership(row)) {

      return;
   }
    showLoadingModal();


    AnalyticsService.deleteSalesData(
      localStorage.getItem("token"),row.id
     )
       .then((res) => {
         if (res.status === 200) {
           Swal.fire("Sales Data has been deleted", "", "success");
         }
 
       })
       .catch((err) => {
         hideLoadingModal();
         console.log(err);
       });
 
  };


  const handleEdit = (row) => {

   if (validateOwnership(row)) {

      return;
   }

   setViewMode(false);
    setShowModal(true);
    setSelectedSalesRecord(row);
  };

  const handleView = (row) => {

    // if (validateOwnership(row)) {
 
    //    return;
    // }
 
     
     setShowModal(true);
      setViewMode(true);
     setSelectedSalesRecord(row);
    
   };


  const validateOwnership = (row) => {
    if (row.salesOfficerId !== userDetails[0].userId) {
      Swal.fire("You are not the owner of this record", "", "error");
      return true;
    }
    return false;
  }
  const handleClose = () => {
    // Implement your edit logic here
    setShowModal(false);
    setSelectedSalesRecord(null);
  };



  return (
    <div style={{ height: 600, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={20}
        onSelectionModelChange={(selection) =>
          setSelectedSalesRecord(selection.selectionModel[0])
        }
        search
      />
    
      {(selectedSalesRecord && !viewMode) && (
        <div>
          {showModal && (
            <EditSalesRecord salesRecord={selectedSalesRecord} closeModal={handleClose} />
          )}
        </div>
      )}
      {(selectedSalesRecord && viewMode)&& (
        <div>
          {showModal && (
            <SalesRecordView salesRecord={selectedSalesRecord} closeModal={handleClose} />
          )}
        </div>
      )}
    </div>
  );
};

export default RecordsGrid;
