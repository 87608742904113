import React, {useContext, useEffect} from "react";
import Prism from "prismjs";
import {Link, useNavigate} from "react-router-dom";
import HeaderMobile from "../layouts/HeaderMobile";
import {Button, Card, Modal} from "react-bootstrap";
import TaskContext from "../context/TaskContext";
import Sidebar from "../layouts/Sidebar";
import Avatar from "../components/Avatar";
import img6 from "../assets/img/img6.jpg";
import projects from "../assets/img/projects.png";

export default function SingleProject() {
    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const { selectedProject, modalState, updateModalState } = useContext(TaskContext);

    const navigate = useNavigate();
    const lgHandleClose = () => {
        // setLgShow(false);
        updateModalState(false);


        navigate('/projects'); // Navigate to "/apps/tasks" upon modal closure
    };
   const event = selectedProject;

    return (
        <React.Fragment>
            <Sidebar />
            <HeaderMobile/>
            <div className="main main-docs">
                <Modal show={modalState} onHide={lgHandleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Project Overview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card className="card-event">
                            <Link to="#"><img src={projects} className="card-img-top" alt="" /></Link>
                            <Card.Body>
                                <h6><Link to="">{event.name}</Link></h6>
                                <p className="mb-0">{event.startDate + " - " + event.endDate}</p>
                                <p className="text-secondary fs-sm">{event.name}</p>

                                <div className="mutual-badge">
                                    <ul>
                                {/*        {event.mutual.map((img, ind) => (*/}
                                            <li><Avatar img={img6} /></li>
                                {/*        ))}*/}
                                    </ul>
                                    <label> You and {event.assigneeCount} others are part of this.</label>                                </div>
                            </Card.Body>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={lgHandleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </React.Fragment>
    );
}