import React from "react";
import { Table } from "react-bootstrap";

const SalesTableBySource = ({ salesMap, tableCategory }) => {
  const monthOrder = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];

  const formatAndSumValues = (salesMap, month, key) => {
    const total = Object.values(salesMap).reduce((sum, officer) => {
      const value = officer[month]?.reduce(
        (total, record) => total + (parseFloat(record[key]) || 0),
        0
      );
      return sum + (isNaN(value) ? 0 : value);
    }, 0);
  
    // Format the number with commas
    const formattedValue = total.toLocaleString('en-US');
  
    // Add the Naira symbol
    const valueWithCurrency = `₦${formattedValue}`;
  
    return valueWithCurrency;
  };
  

  const allMonths = Object.values(salesMap)
    .flatMap((officer) => Object.keys(officer))
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort((a, b) => monthOrder.indexOf(a) - monthOrder.indexOf(b));

  return (
    <div>
      {tableCategory === "REVENUE" && (
        <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th style={{ backgroundColor: 'lightgray' }}>Month/Week</th>
          <th>PPP</th>
          <th>Metromaxi</th>
          <th>Metromidi</th>
          <th>Metromini</th>
          <th>Flexi</th>
          <th>NHIA</th>
          <th style={{ backgroundColor: 'lightgray' }}>Total Revenue</th>
        </tr>
      </thead>
      <tbody>
        {allMonths.map((month) => (
          <tr key={month}>
            <td>{month}</td>
            <td>{formatAndSumValues(salesMap, month, 'pppValueAmount')}</td>
            <td>{formatAndSumValues(salesMap, month, 'metromaxiValueAmount')}</td>
            <td>{formatAndSumValues(salesMap, month, 'metromidiValueAmount')}</td>
            <td>{formatAndSumValues(salesMap, month, 'metrominiValueAmount')}</td>
            <td>{formatAndSumValues(salesMap, month, 'flexiValueAmount')}</td>
            <td>{formatAndSumValues(salesMap, month, 'nhiaValueAmount')}</td>
            <td style={{ backgroundColor: 'lightgray' }}>
              {formatAndSumValues(salesMap, month, 'revenueAmount')}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
      )}

      {tableCategory === "UNITSALES" && (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th style={{ backgroundColor: 'lightgray' }}>Month/Week</th>
              <th>PPP</th>
              <th>Metromaxi</th>
              <th>Metromidi</th>
              <th>Metromini</th>
             
              <th>Flexi</th>
              <th>NHIA</th>
              <th style={ { backgroundColor: "lightgray" }}>Total Unit Sales</th>
            </tr>
          </thead>
          <tbody>
            {allMonths.map((month,monthIndex) => (
              <tr key={month}>
              <td>{month}</td>
              <td>
                  {salesMap &&
                    Object.values(salesMap).reduce(
                      (sum, officer) =>
                        sum +
                        (officer[month]
                          ? officer[month].reduce(
                              (total, record) =>
                                total + (record.pppPopulationCount || 0),
                              0
                            )
                          : 0),
                      0
                    )}
                </td>
                <td>
                  {salesMap &&
                    Object.values(salesMap).reduce(
                      (sum, officer) =>
                        sum +
                        (officer[month]
                          ? officer[month].reduce(
                              (total, record) =>
                                total + (record.metromaxiPopulationCount || 0),
                              0
                            )
                          : 0),
                      0
                    )}
                </td>
                <td>
                  {salesMap &&
                    Object.values(salesMap).reduce(
                      (sum, officer) =>
                        sum +
                        (officer[month]
                          ? officer[month].reduce(
                              (total, record) =>
                                total + (record.metromidiPopulationCount || 0),
                              0
                            )
                          : 0),
                      0
                    )}
                </td>
                <td>
                  {salesMap &&
                    Object.values(salesMap).reduce(
                      (sum, officer) =>
                        sum +
                        (officer[month]
                          ? officer[month].reduce(
                              (total, record) =>
                                total + (record.metrominiPopulationCount || 0),
                              0
                            )
                          : 0),
                      0
                    )}
                </td>
               
                <td>
                  {salesMap &&
                    Object.values(salesMap).reduce(
                      (sum, officer) =>
                        sum +
                        (officer[month]
                          ? officer[month].reduce(
                              (total, record) =>
                                total + (record.flexiPopulationCount || 0),
                              0
                            )
                          : 0),
                      0
                    )}
                </td>
                <td>
                  {salesMap &&
                    Object.values(salesMap).reduce(
                      (sum, officer) =>
                        sum +
                        (officer[month]
                          ? officer[month].reduce(
                              (total, record) =>
                                total + (record.nhiaPopulationCount || 0),
                              0
                            )
                          : 0),
                      0
                    )}
                </td>
                <td style={ { backgroundColor: "lightgray" }}>
                  {Object.values(salesMap).reduce(
                    (sum, officer) =>
                      sum +
                      (officer[month]
                        ? officer[month].reduce(
                            (total, record) =>
                              total + (record.totalPopulationCount || 0),
                            0
                          )
                        : 0),
                    0
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default SalesTableBySource;
