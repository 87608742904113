import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Container,
  Nav,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Button,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Bar, Line } from "react-chartjs-2";
import { AnalyticsService } from "../../service/AnalyticsService";
import {
  getCurrentDate,
  showLoadingModal,
  hideLoadingModal,
} from "../../logic/Utils";
import Swal from "sweetalert2";

import Sidebar from "../../layouts/Sidebar";
import SalesInput from "../../components/SalesInput";
import SalesTableForTeam from "../../components/SalesTableForTeam";
import SalesHighlights from "../../components/SalesHighlights";

export default function SalesTeam() {
  const [showInputModal, setShowInputModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [chartData, setChartData] = useState(null);
  const [salesMap, setSalesMap] = useState(null);
  const [salesMetrics, setSalesMetrics] = useState(null);
  const userDetails = useState(
    JSON.parse(localStorage.getItem("userDetails")) || {}
  );

  function checkPermission(userDetails) {
   
    const result = (
      (userDetails.role == "MANAGER" && userDetails.department == "SALES") ||
      userDetails.department == "EXCO" ||
      userDetails.role == "ADMIN"
  );

    return result;
}
  const validateDates = () => {
    const errorMessages = [];

    if (!endDate) {
      errorMessages.push("End Date is required.");
    }

    if (!startDate) {
      errorMessages.push("Start Date is required.");
    }

    // Display error messages if any
    if (errorMessages.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: errorMessages.join("\n"),
      });

      return false;
    }

    return true;
  };
  const handleSubmit = (e) => {

    if (!checkPermission(userDetails[0])){
      Swal.fire({
        icon: "error",
        title: "UnAuthorized Action",
        text: "YOu are not authorized to do this",
      });
      return;
    }
    showLoadingModal();
    if (!validateDates()) {
      return;
    }

    AnalyticsService.getAnalyticsForSalesTeamByRange(
      localStorage.getItem("token"),
      startDate,
      endDate
    )
      .then((res) => {
        const salesMap = res.data.result.salesMap;
        setSalesMap(salesMap);
        setSalesMetrics(res.data.result.salesMetrics);
        if (res.data.result.salesMetrics.numberOfSales == 0) {
          Swal.fire("No sales were recorded during this period", "", "info");
          return;
        }
        let cumulativeData = {};

        // Calculate cumulative revenue for each period across all officers
        Object.keys(salesMap).forEach((officer) => {
          Object.keys(salesMap[officer]).forEach((month) => {
            const totalRevenue = salesMap[officer][month]
              ? salesMap[officer][month].reduce(
                  (sum, record) => sum + record.revenueAmount,
                  0
                )
              : 0;

            if (!cumulativeData[month]) {
              cumulativeData[month] = 0;
            }

            cumulativeData[month] += totalRevenue;
          });
        });

        // Find the highest and lowest performing periods based on cumulative values
        let highestPeriod = "";
        let highestValue = 0;
        let lowestPeriod = "";
        let lowestValue = Number.MAX_SAFE_INTEGER;

        Object.keys(cumulativeData).forEach((month) => {
          const totalRevenue = cumulativeData[month];

          if (totalRevenue > highestValue) {
            highestValue = totalRevenue;
            highestPeriod = month;
          }

          if (totalRevenue < lowestValue) {
            lowestValue = totalRevenue;
            lowestPeriod = month;
          }
        });

        // Custom order of months
        const monthOrder = [
          "JANUARY",
          "FEBRUARY",
          "MARCH",
          "APRIL",
          "MAY",
          "JUNE",
          "JULY",
          "AUGUST",
          "SEPTEMBER",
          "OCTOBER",
          "NOVEMBER",
          "DECEMBER",
        ];

        // Extract sources (Officer names) and ctxLabel (months) from the response
        const sources = Object.keys(salesMap);
        const ctxLabel = Array.from(
          new Set(
            Object.values(salesMap)
              .flatMap((officer) => Object.keys(officer))
              .flat()
          )
        );
        ctxLabel.sort((a, b) => monthOrder.indexOf(a) - monthOrder.indexOf(b));

        // Initialize ctxData as an array of empty arrays
        let ctxData = Array(sources.length)
          .fill()
          .map(() => Array(ctxLabel.length).fill(0));

        // Fill ctxData with revenue values
        sources.forEach((source, i) => {
          const officerData = salesMap[source];
          ctxLabel.forEach((month, j) => {
            const monthData = officerData[month];
            if (monthData && monthData.length > 0) {
              ctxData[i][j] = monthData.reduce(
                (total, record) => total + record.revenueAmount,
                0
              );
            }
          });
        });

        const datasets = sources.map((label, index) => ({
          data: ctxData[index],
          backgroundColor: ctxColors[index % ctxColors.length], // Cycle through colors if more labels than colors
          barPercentage: 0.5,
          label,
        }));

        const dataBar = {
          labels: ctxLabel,
          datasets: datasets,
        };

        setChartData(dataBar);
        setIsLoaded(true);
        hideLoadingModal();
      })
      .catch((err) => {
        hideLoadingModal();
        console.log(err);
      });
  };

  const closeModal = () => {
    setShowInputModal(false);
  };

  const chartOption = {
    indexAxis: "y",
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 100,
        grid: {
          borderColor: "#000",
          color: "#f3f5f9",
        },
        ticks: {
          color: "#212830",
          font: {
            size: 10,
            weight: "500",
          },
        },
      },
      y: {
        grid: {
          borderWidth: 0,
          color: "#f3f5f9",
        },
        ticks: {
          color: "#212830",
          font: {
            size: 12,
          },
        },
      },
    },
  };

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  if (skin === "dark") {
    chartOption.scales["x"].grid.color = "#222b41";
    chartOption.scales["x"].ticks.color = "rgba(255,255,255,.65)";
    chartOption.scales["x"].grid.borderColor = "#222b41";
    chartOption.scales["y"].grid.color = "#222b41";
    chartOption.scales["y"].ticks.color = "rgba(255,255,255,.65)";
  } else {
    chartOption.scales["x"].grid.color = "#edeff6";
    chartOption.scales["x"].ticks.color = "#42484e";
    chartOption.scales["x"].grid.borderColor = "#edeff6";
    chartOption.scales["y"].grid.color = "#edeff6";
    chartOption.scales["y"].ticks.color = "#42484e";
  }

  const ctxData1 = [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30];
  const ctxData2 = [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20];

  const ctxColors = [
    "#506fd9", // Blue
    "#DBDFFD", // Light Blue
    "#808080", // Grey
    "#3498db", // Dodger Blue
    "#2ecc71", // Emerald Green
    "#27ae60", // Dark Green
    "#c0392b", // Firebrick Red
    "#16a085", // Dark Cyan Green
  ];

  const ctxColor1 = "#33FFFF";
  const ctxColor2 = "#33FF57";

  const optionBar3 = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        stacked: true,
      },
      x: {
        stacked: true,
      },
    },
  };

  const dataLine = {
    labels: [],
    datasets: [
      {
        data: ctxData1,
        borderColor: ctxColor1,
        backgroundColor: ctxColor1,
        borderWidth: 1,
      },
      {
        data: ctxData2,
        borderColor: ctxColor2,
        backgroundColor: ctxColor2,
        borderWidth: 1,
      },
    ],
  };

  const optionLine = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 80,
      },
    },
  };

  return (
    <React.Fragment>
      <Card className="card-one mt-3 mb-3">
        <Card.Body>
          <div className="mb-3">
            <Row>
              {" "}
              <Col sm="6">
                <Form.Label htmlFor="formGroupExampleInput2">
                  Start Date
                </Form.Label>

                <Form.Control
                  name="startDate"
                  type="date"
                  id="startDate"
                  max={getCurrentDate()}
                  className="form-control input-class"
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                />
              </Col>
              <Col sm="6">
                <Form.Label htmlFor="formGroupExampleInput2">
                  End Date
                </Form.Label>

                <Form.Control
                  name="endDate"
                  type="date"
                  className="form-control input-class"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                />
              </Col>
            </Row>
          </div>
        </Card.Body>

        <Card.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Card.Footer>
      </Card>
      {isLoaded && (
        <Row className="g-3">
          {salesMetrics != null && (
            <SalesHighlights
                salesTarget={salesMetrics.salesTarget}
                revenueAmount={salesMetrics.revenueAmount}
                revenueTarget={salesMetrics.revenueTarget}
                totalPopulationCountTarget={
                  salesMetrics.totalPopulationCountTarget
                }
                totalPopulationCount={salesMetrics.totalPopulationCount}
                numberOfSales={salesMetrics.numberOfSales}
                totalPopulationCountAchievedPercentage={
                  salesMetrics.totalPopulationCountAchievedPercentage
                }
                revenueTargetAchievedPercentage={
                  salesMetrics.revenueTargetAchievedPercentage
                }
                salesTargetAchievedPercentage={
                  salesMetrics.salesTargetAchievedPercentage
                }
                annualRevenueTarget={salesMetrics.annualRevenueTarget}
                annualTotalPopulationCountTarget={salesMetrics.annualTotalPopulationCountTarget}
              />
          )}
          <Col xl="7">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">
                 Sales Chart for the Team - Revenue
                </Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                {chartData != null && (
                  <Bar
                    data={chartData}
                    options={optionBar3}
                    className="ht-300"
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" xl="5">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">
                  {" "}
                   Table Breakdown for the Team - Revenue
                </Card.Title>
                <Nav as="nav" className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                {salesMap != null && (
                  <SalesTableForTeam
                    salesMap={salesMap}
                    tableCategory={"REVENUE"}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col xl="5">
            <Card className="card-analytics mb-3">
              <Card.Header>
                <Card.Title as="h6">Unit Sales Per Source </Card.Title>
                <Nav as="nav" className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-3 p-xl-4">
                {salesMap != null && (
                  <SalesTableForTeam
                    salesMap={salesMap}
                    tableCategory={"UNITSALES"}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col xl="7">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">
                  Unit Sales Breakdown (Chart)
                </Card.Title>
                <Nav as="nav" className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-3 p-xl-4">
                {chartData != null && (
                  <Bar
                    data={chartData}
                    options={optionBar3}
                    className="ht-300"
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
          )
        </Row>
      )
       
      }

      {showInputModal && <SalesInput closeModal={closeModal} />}
    </React.Fragment>
  );
}
