import TaskContext from "./TaskContext";
import React, {useState} from "react";


const TaskProvider = ({ children }) => {
    const [modalState, setModalState] = useState(true); // used for modal control

    const updateModalState = (newValue) => {
        setModalState(newValue);

    }
    const [selectedProject, setSelectedProject] = useState(null);// used by project

        const updateProject = (newValue) => {
            setSelectedProject(newValue);
        };
    const [selectedTask, setSelectedTask] = useState(null); // used by tasks

    const updateTask = (newValue) => {
        setSelectedTask(newValue);
    };

    const [taskPage, setTaskPage] = useState(null); // used by tasks

    const updateTaskPage = (newValue) => {
        setTaskPage(newValue);
    };

    //AUTH CONTEXT VARIABLES
    const [userDetails, setUserDetails] = useState({
        firstName: null,
        lastName: null,
        email: null,
        jobTitle: null,
        token: null,
        role: null,
        // Add more fields as needed
    });

    const updateUserDetails = (newDetails) => {
        setUserDetails({ ...userDetails, ...newDetails });
    };
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const updateIsLoggedIn = (newValue) => {
        setIsLoggedIn(newValue);
    };
    const [teamId, setTeamId] = useState(false);
    const updateTeamId = (newValue) => {
        setTeamId(newValue);
    };
    return (
        <TaskContext.Provider value={{isLoggedIn, updateIsLoggedIn,
            modalState, selectedTask,updateModalState,
            updateTask,selectedProject,updateProject,
            userDetails, updateUserDetails, setTaskPage,
            updateTaskPage,taskPage, updateTeamId,teamId}}>
            {children}
        </TaskContext.Provider>
    );

}

export default TaskProvider;