import React, { useEffect } from "react";
import {BrowserRouter, Routes, Route, useNavigate,} from "react-router-dom";
import { FloatingWhatsApp } from 'react-floating-whatsapp'


import "../assets/css/remixicon.css";
import "../scss/style.scss";
import Signin from "../pages/Signin";
import Chat from "../apps/Chat"
import CreateProject from "../components/CreateProject";
import CreateTeam from "../components/CreateTeam";
import InviteEmployee from "../components/InviteEmployee";
import Projects from "../pages/WorkPlansForSelf";
import CreateTask from "../components/CreateTask";
import Settings from "../pages/Settings";
import ManagerTeams from "../pages/ManagerTeams";
import Teams from "../pages/Teams";
import ManagerInActiveAssignedTasks from "../apps/ManagerInActiveAssignedTasks";
import Profile from "../pages/Profile";
import ManagerAssignedTasks from "../apps/ManagerAssignedTasks";
import InActiveTasks from "../apps/InActiveTasks";
import Tasks from "../apps/Tasks";
import UnderwritingReports from "../dashboard/reports/UnderwritingReport";
import Overview from "../dashboard/Overview";
import Reports from "../dashboard/reports/Reports";
import ICTReports from "../dashboard/reports/ICTReports";
import LegalReports from "../dashboard/reports/LegalReports";
import HrTeamReports from "../dashboard/reports/HrTeamReports";
import ClientReports from "../dashboard/reports/ClientReports";
import MedicalServicesReports from "../dashboard/reports/MedicalServicesReports";
import SalesReports from "../dashboard/reports/SalesReports";
import OperationsReportTeam from "../dashboard/reports/OperationsReportTeam";


import CreatePassword from "../pages/CreatePassword";
import OngoingTasks from "../dashboard/tasks/OngoingTasks";
import NewTasks from "../dashboard/tasks/NewTasks";
import CompletedTasks from "../dashboard/tasks/CompletedTasks";
import ExtensionTasks from "../dashboard/tasks/ExtensionTasks";
import TeamNewTasks from "../dashboard/tasks/manager/TeamNewTasks";
import TeamOngoingTasks from "../dashboard/tasks/manager/TeamOngoingTasks";
import TeamCompletedTasks from "../dashboard/tasks/manager/TeamCompletedTasks";
import TeamExtensionTasks from "../dashboard/tasks/manager/TeamExtensionTasks";
import GenerateReportForAnEmployee from "../components/GenerateReportForAnEmployee";
import ForgotPassword from "../pages/ForgotPassword";
import TariffManager from "../pages/TariffManager";
import TaskDetails from "../pages/TaskDetails";

import ReportManager from "../pages/ReportManager";
import AppCalendar from "../apps/AppCalendar";
import ClaimsTracker from "../pages/ClaimsTracker";

import Reach from "../pages/Analytics/Reach";
import Leads from "../pages/Analytics/Leads";
import Visits from "../pages/Analytics/Visits";
import CreateWorkPlan from "../components/CreateWorkPlan";
import CreateWorkPlanForTeam from "../components/CreateWorkPlanForTeam";
import WorkPlans from "../pages/WorkPlans";
import WorkPlansForSelf from "../pages/WorkPlansForSelf";
import WorkPlanDetails from "../pages/WorkPlanDetails";
import EmployeeKpi from "../pages/EmployeeKpi";
import SalesTeam from "../pages/Analytics/SalesTeam";
import SalesBySource from "../pages/Analytics/SalesBySource";
import SalesRecords from "../pages/Analytics/SalesRecords";
import EmployeeProfile from "../pages/EmployeeProfile";
import AnalyticsTabs from "../pages/Analytics/AnalyticsTabs";
import SalesOverview from "../pages/Analytics/SalesOverview";
import InvoiceTracker from "../pages/InvoiceTracker/InvoiceTracker";
import MlrAnalytics from "../pages/Analytics/MlrAnalytics";
import ProviderManager from "../pages/ProviderManagement/ProviderManager";


window.addEventListener("load", function () {
    let skinMode = localStorage.getItem("skin-mode");
    let HTMLTag = document.querySelector("html");

    if (skinMode) {
        HTMLTag.setAttribute("data-skin", skinMode);
    }
});

export default function RouteResolver() {


    useEffect(() => {

    }, []);

    return (
        <React.Fragment>
            <BrowserRouter>
                <Routes>
                        <Route path="dashboard/reports" element={<Reports />} />
                        <Route path="dashboard/overview" element={<Overview />} />
                        <Route path="dashboard/Underwriting" element={<UnderwritingReports />} />
                        <Route path="apps/active-tasks" element={<Tasks />} />
                        <Route path="apps/inactive-tasks" element={<InActiveTasks />} />
                        <Route path="apps/assigned-tasks" element={<ManagerAssignedTasks />} />
                        <Route path="apps/assigned_inactive-tasks" element={<ManagerInActiveAssignedTasks />} />
                        <Route path="profile" element={<Profile />} />
                        <Route path="teams" element={<Teams />} />
                        <Route path="my-team" element={<ManagerTeams />} />
                        <Route path="pages/settings" element={<Settings />} />
                        <Route path="/create/task" element={<CreateTask />} />
                        <Route path="/create/team" element={<CreateTeam />} />
                        <Route path="/create/project" element={<CreateProject />} />
                        <Route path="/projects" element={<Projects />} />
                        <Route path="/invite/employee" element={<InviteEmployee />} />
                        <Route path="/dashboard/ict" element={<ICTReports />} />
                        <Route path="/dashboard/legal" element={<LegalReports />} />
                        <Route path="/dashboard/hr" element={<HrTeamReports />} />
                        <Route path="/dashboard/client" element={<ClientReports />} />
                        <Route path="/dashboard/medical" element={<MedicalServicesReports />} />
                        <Route path="/dashboard/sales" element={<SalesReports />} />
                        <Route path="/dashboard/operations" element={<OperationsReportTeam />} />
                        <Route path="/test" element={<SalesOverview />} />
                        <Route path="/tasks/ongoing" element={<OngoingTasks />} />
                        <Route path="/tasks/new" element={<NewTasks />} />
                        <Route path="/tasks" element={<TaskDetails />} />
                        <Route path="/tasks/completed" element={<CompletedTasks />} />
                        <Route path="/tasks/extensions" element={<ExtensionTasks />} />
                        <Route path="/tasks/team/ongoing" element={<TeamOngoingTasks />} />
                        <Route path="/tasks/team/new" element={<TeamNewTasks />} />
                        <Route path="/tasks/team/completed" element={<TeamCompletedTasks />} />
                        <Route path="/tasks/team/extensions" element={<TeamExtensionTasks />} />
                    <Route path="/tariff-manager" element={<TariffManager />} />
                        <Route path="/reports/create" element={<GenerateReportForAnEmployee />} />
                        <Route path="/report-manager" element={<ReportManager/>}/>
                    <Route path="/" element={<Signin />} />
                    <Route path="login" element={<Signin />} />
                    {/*<Route path="enter" element={<Signin/>} />*/}
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/create-password" element={<CreatePassword />} />
                    <Route path="/schedule" element={<AppCalendar />} />
                    <Route path="/claims-tracker" element={<ClaimsTracker />} />
                    <Route path="/invoice-tracker" element={<InvoiceTracker />} />
                    <Route path="/analytics/reach" element={<Reach />} />
                    <Route path="/analytics/visits" element={<Visits />} />
                    <Route path="/analytics/leads" element={<Leads />} />
                    <Route path="/analytics/sales" element={<AnalyticsTabs />} />
                    <Route path="/analytics/mlr" element={<MlrAnalytics />} />
                    <Route path="/analytics/sales-records" element={<SalesRecords />} />
                    <Route path="/analytics/sales/source" element={<SalesBySource />} />
                    <Route path="/create/workplan" element={<CreateWorkPlan />} />
                    <Route path="/team/create/workplan" element={<CreateWorkPlanForTeam />} />
                    <Route path="/team/workplans" element={<WorkPlans />} />
                    <Route path="/workplans" element={<WorkPlansForSelf />} />
                    <Route path="/workplans/info" element={<WorkPlanDetails />} />
                    <Route path="/employee/kpi" element={<EmployeeKpi />} />
                    <Route path="/employee/profile" element={<EmployeeProfile/>} />
                    <Route path="/medical-services/providers" element={<ProviderManager/>} />
                    

                </Routes>
            </BrowserRouter>
            <FloatingWhatsApp 
            phoneNumber="+2348134144985"
             message="Hello!"
             accountName = "ICT Department" 
             avatar = "https://res.cloudinary.com/dpjni3o5l/image/upload/v1701253450/favicon_yl50ba.png"   
             />
            
        </React.Fragment>
    );
}