import React from "react";
import { Card, Col,Row } from "react-bootstrap";
import { formatRevenue, } from "../logic/Utils";

export default function SalesHighlights(props) {

 
  const numberOfSales = props.numberOfSales;
  const salesTarget = props.salesTarget;
  const revenueAmount = props.revenueAmount;
  const revenueAmountTarget = props.revenueTarget;
  const salesTargetAchievedPercentage = props.salesTargetAchievedPercentage;
  const revenueTargetAchievedPercentage = props.revenueTargetAchievedPercentage;
  const totalPopulationCountAchievedPercentage =
    props.totalPopulationCountAchievedPercentage;
  const totalPopulationCountTarget = props.totalPopulationCountTarget;
  const totalPopulationCount = props.totalPopulationCount;
  const annualRevenueTarget = props.annualRevenueTarget;
  const annualTotalPopulationCountTarget = props.annualTotalPopulationCountTarget;

  return (
    <>


     <Col xs="12" xl="4"> 
        <Card className="card-one">
          <Card.Body>
            <Card.Title as="label" className="fs-sm fw-medium mb-1">
              Total Number of Sales
            </Card.Title>
            <h3 className="card-value mb-1">
              <i className="ri-briefcase-4-line"></i> {numberOfSales}
            </h3>
            <small>
              <span
                className={
                  "d-inline-flex text-" +
                  (salesTarget < numberOfSales ? "success" : "danger")
                }
              >
                {salesTargetAchievedPercentage}%{" "}
                <i
                  className={
                    "ri-arrow-" +
                    (salesTarget < numberOfSales ? "up" : "down") +
                    "-line"
                  }
                ></i>
              </span>{" "}
              of target
            </small>
          </Card.Body>
        </Card>
      </Col>
      <Col xs="12" xl="4">
        <Card className="card-one">
          <Card.Body>
            <Card.Title as="label" className="fs-sm fw-medium mb-1">
              Total Revenue Generated
            </Card.Title>
            <h3 className="card-value mb-1">
              <i className="ri-briefcase-4-line"></i> {formatRevenue(revenueAmount)}
           
            </h3>
            <small>
              <span
                className={
                  "d-inline-flex text-" +
                  (revenueAmountTarget < revenueAmount ? "success" : "danger")
                }
              >
                {revenueTargetAchievedPercentage}%{" "}
                <i
                  className={
                    "ri-arrow-" +
                    (revenueAmountTarget < revenueAmount ? "up" : "down") +
                    "-line"
                  }
                ></i>
              </span>{" "}
              of target
            </small>
          </Card.Body>
        </Card>
      </Col>
      <Col xs="12" xl="4">
        <Card className="card-one">
          <Card.Body>
            <Card.Title as="label" className="fs-sm fw-medium mb-1">
              Total Population Count
            </Card.Title>
            <h3 className="card-value mb-1">
              <i className="ri-briefcase-4-line"></i> {totalPopulationCount}
            </h3>
            <small>
              <span
                className={
                  "d-inline-flex text-" +
                  (totalPopulationCountTarget < totalPopulationCount
                    ? "success"
                    : "danger")
                }
              >
                {totalPopulationCountAchievedPercentage}%{" "}
                <i
                  className={
                    "ri-arrow-" +
                    (totalPopulationCountTarget < totalPopulationCount
                      ? "up"
                      : "down") +
                    "-line"
                  }
                ></i>
              </span>{" "}
              of target
            </small>
          </Card.Body>
        </Card>
      </Col>
      <Col xs="12" xl="4">
        <Card className="card-one">
          <Card.Body>
            <Card.Title as="label" className="fs-sm fw-medium mb-1">
              Revenue Target For the Period
            </Card.Title>
            <h3 className="card-value mb-1">
              <i className="ri-briefcase-4-line"></i> {formatRevenue(revenueAmountTarget)}
            </h3>
           
          </Card.Body>
        </Card>
      </Col>
      <Col xs="12" xl="4">
        <Card className="card-one">
          <Card.Body>
            <Card.Title as="label" className="fs-sm fw-medium mb-1">
            Unit Sales Target For the Period
            </Card.Title>
            <h3 className="card-value mb-1">
              <i className="ri-briefcase-4-line"></i> {totalPopulationCountTarget}
            </h3>
           
          </Card.Body>
        </Card>
      </Col>
      <Col xs="12" xl="4">
        <Card className="card-one">
          <Card.Body>
            <Card.Title as="label" className="fs-sm fw-medium mb-1">
              Annual Target
            </Card.Title>
            <h3 className="card-value mb-1">
              <i className="ri-briefcase-4-line"></i> {formatRevenue(annualRevenueTarget)}
            </h3>
            <h4 className="card-value mb-1">
            <i className="ri-briefcase-4-line"></i> {annualTotalPopulationCountTarget}
            </h4>
           
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}
