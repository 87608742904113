import Swal from "sweetalert2";
import ReactDOMServer from "react-dom/server";
import {
    Spinner,
  } from "react-bootstrap";

const showLoadingModal = () => {
    // Convert the React component to an HTML string
    const spinnerHtml = ReactDOMServer.renderToString(
      <Spinner animation="border" />
    );

    // Open Swal modal with the Bootstrap Spinner component
    Swal.fire({
      title: "Processing",
      html: `
            <div class="text-center">
              ${spinnerHtml}
            </div>
          `,
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
  };

  // Function to hide the loading modal
  const hideLoadingModal = () => {
    // Close the Swal modal
    Swal.close();
  };

  function getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = `${now.getMonth() + 1}`.padStart(2, "0");
    const day = `${now.getDate()}`.padStart(2, "0");
    const hours = `${now.getHours()}`.padStart(2, "0");
    const minutes = `${now.getMinutes()}`.padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  function getCurrentDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = `${now.getMonth() + 1}`.padStart(2, "0");
    const day = `${now.getDate()}`.padStart(2, "0");

    return `${year}-${month}-${day}`;
  }


  const calculateCumulativeValueAmounts = (salesMap) => {
    let cumulativeData = {};
  
    // Define custom labels for value amounts
    const valueAmountLabels = {
      metromaxiValueAmount: "Metro Maxi Value Amount",
      metromidiValueAmount: "Metro Midi Value Amount",
      metrominiValueAmount: "Metro Mini Value Amount",
      pppValueAmount: "PPP Value Amount",
      nhiaValueAmount: "NHIA Value Amount",
      flexiValueAmount: "FLEXI Value Amount"
      // Add other value amounts as needed
    };
  
    // Calculate cumulative value amounts for each period across all subsets
    Object.keys(salesMap).forEach((officer) => {
      Object.keys(salesMap[officer]).forEach((month) => {
        if (!cumulativeData[month]) {
          cumulativeData[month] = {};
        }
  
        // Initialize value amounts to 0 for the current month
        Object.keys(valueAmountLabels).forEach((valueAmount) => {
          cumulativeData[month][valueAmount] = 0;
        });
  
        salesMap[officer][month].forEach((record) => {
          // Accumulate value amounts for each record
          Object.keys(valueAmountLabels).forEach((valueAmount) => {
            cumulativeData[month][valueAmount] += record[valueAmount] || 0;
          });
        });
      });
    });
  
    // Custom order of months
    const monthOrder = [
      "JANUARY",
      "FEBRUARY",
      "MARCH",
      "APRIL",
      "MAY",
      "JUNE",
      "JULY",
      "AUGUST",
      "SEPTEMBER",
      "OCTOBER",
      "NOVEMBER",
      "DECEMBER",
    ];
  
    // Extract ctxLabel (months) from the response
    const ctxLabel = Array.from(
      new Set(
        Object.values(salesMap)
          .flatMap((officer) => Object.keys(officer))
          .flat()
      )
    );
    ctxLabel.sort((a, b) => monthOrder.indexOf(a) - monthOrder.indexOf(b));
  
    // Initialize ctxData as an array of empty arrays
    let ctxData = Array(Object.keys(valueAmountLabels).length)
      .fill()
      .map(() => Array(ctxLabel.length).fill(0));
  
    // Fill ctxData with value amount values
    Object.keys(valueAmountLabels).forEach((valueAmount, i) => {
      Object.keys(cumulativeData).forEach((month, j) => {
        ctxData[i][j] = cumulativeData[month][valueAmount];
      });
    });
  
    const datasets = Object.keys(valueAmountLabels).map((valueAmount, index) => ({
      data: ctxData[index],
      // Use different colors or customization if needed
      backgroundColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
        Math.random() * 256
      )}, ${Math.floor(Math.random() * 256)}, 0.5)`,
      barPercentage: 0.5,
      label: valueAmountLabels[valueAmount],
    }));
  
    const dataBar = {
      labels: ctxLabel,
      datasets: datasets,
    };
  
    return dataBar;
  };
  

  function calculateCumulativeUnitSales(salesMap) {
    let cumulativeData = {};
  
    // Define custom labels for unit sales
    const unitSalesLabels = {
      metromaxiPopulationCount: "Metro Maxi Unit Sales",
      metromidiPopulationCount: "Metro Midi Unit Sales",
      metrominiPopulationCount: "Metro Mini Unit Sales",
      pppPopulationCount: "PPP Unit Sales",
      nhiaPopulationCount: "NHIA Unit Sales",
      flexiPopulationCount: "FLEXI Unit Sales"
      // Add other unit sales as needed
    };
  
    // Calculate cumulative population counts for each period across all subsets
    Object.keys(salesMap).forEach((officer) => {
      Object.keys(salesMap[officer]).forEach((month) => {
        if (!cumulativeData[month]) {
          cumulativeData[month] = {};
        }
  
        // Initialize unit sales to 0 for the current month
        Object.keys(unitSalesLabels).forEach((unitSales) => {
          cumulativeData[month][unitSales] = 0;
        });
  
        salesMap[officer][month].forEach((record) => {
          // Accumulate unit sales for each record
          Object.keys(unitSalesLabels).forEach((unitSales) => {
            cumulativeData[month][unitSales] += record[unitSales] || 0;
          });
        });
      });
    });
  
    // Custom order of months
    const monthOrder = [
      "JANUARY",
      "FEBRUARY",
      "MARCH",
      "APRIL",
      "MAY",
      "JUNE",
      "JULY",
      "AUGUST",
      "SEPTEMBER",
      "OCTOBER",
      "NOVEMBER",
      "DECEMBER",
    ];
  
    // Extract ctxLabel (months) from the response
    const ctxLabel = Array.from(
      new Set(
        Object.values(salesMap)
          .flatMap((officer) => Object.keys(officer))
          .flat()
      )
    );
    ctxLabel.sort((a, b) => monthOrder.indexOf(a) - monthOrder.indexOf(b));
  
    // Initialize ctxData as an array of empty arrays
    let ctxData = Array(Object.keys(unitSalesLabels).length)
      .fill()
      .map(() => Array(ctxLabel.length).fill(0));
  
    // Fill ctxData with unit sales values
    Object.keys(unitSalesLabels).forEach((unitSales, i) => {
      Object.keys(cumulativeData).forEach((month, j) => {
        ctxData[i][j] = cumulativeData[month][unitSales];
      });
    });
  
    const datasets = Object.keys(unitSalesLabels).map((unitSales, index) => ({
      data: ctxData[index],
      // Use different colors or customization if needed
      backgroundColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
        Math.random() * 256
      )}, ${Math.floor(Math.random() * 256)}, 0.5)`,
      barPercentage: 0.5,
      label: unitSalesLabels[unitSales],
    }));
  
    const dataBar = {
      labels: ctxLabel,
      datasets: datasets,
    };
  
    return dataBar;
  }
  
const  getLoggedInUser = () => {
  const userDetails =  JSON.parse(localStorage.getItem("userDetails")) || {};
  return userDetails[0];
}

const formatRevenue = (amount) => {

  if (amount){
    // Format the number with commas
    const formattedValue = amount.toLocaleString('en-US');
    // Add the Naira symbol
    const valueWithCurrency = `₦${formattedValue}`;
  
    return valueWithCurrency;
  }
  else {return;}
 
}

const calculateMetricsData = (metricsDataMap, option) => {
  // Early return for null or empty metricsDataMap
  if (!metricsDataMap || Object.keys(metricsDataMap).length === 0) {
    return {
      labels: [],
      datasets: [],
    };
  }

  let cumulativeMetricsData = {};

  // Define custom labels for metrics based on the provided option
  let metricsLabels = {};
  switch (option) {
    case 'mlr':
      metricsLabels = {
        mlr: "Medical Loss Ratio",
      };
      break;
    case 'claims':
      metricsLabels = {
        claimsAmount: "Claims Amount",
      };
      break;
    case 'encounters':
      metricsLabels = {
        encounterCount: "Number of Encounters",
      };
      break;
    case 'revenue':
      metricsLabels = {
        revenueAmount: "Revenue Amount",
      };
      break;
    // Add other cases as needed
    default:
      break;
  }

  // Calculate cumulative metrics for each period
  Object.keys(metricsDataMap).forEach((month) => {
    const uppercaseMonth = month.toUpperCase(); // Convert to uppercase
    if (!cumulativeMetricsData[uppercaseMonth]) {
      cumulativeMetricsData[uppercaseMonth] = {};
    }

    // Initialize metrics to 0 for the current month
    Object.keys(metricsLabels).forEach((metric) => {
      cumulativeMetricsData[uppercaseMonth][metric] = 0;
    });

    // Accumulate metrics for each record
    Object.keys(metricsLabels).forEach((metric) => {
      cumulativeMetricsData[uppercaseMonth][metric] += parseFloat(metricsDataMap[month][metric]) || 0;
    });
  });

  // Custom order of months as uppercase
  const monthOrder = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];

  // Extract and sort ctxLabel (months) from the response, ensuring uppercase for comparison
  const ctxLabel = Array.from(new Set(Object.keys(cumulativeMetricsData).map(month => month.toUpperCase())));
  ctxLabel.sort((a, b) => monthOrder.indexOf(a) - monthOrder.indexOf(b));

  // Initialize ctxData as an array of empty arrays
  let ctxData = Array(Object.keys(metricsLabels).length)
    .fill()
    .map(() => Array(ctxLabel.length).fill(0));

  // Fill ctxData with metric values, ensuring to match uppercase month names
  Object.keys(metricsLabels).forEach((metric, i) => {
    ctxLabel.forEach((month, j) => {
      ctxData[i][j] = cumulativeMetricsData[month][metric];
    });
  });

  const datasets = Object.keys(metricsLabels).map((metric, index) => ({
    label: metricsLabels[metric],
    data: ctxData[index],
    backgroundColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 0.5)`,
  }));

  const dataBar = {
    labels: ctxLabel, // Use the sorted and uppercase month labels
    datasets: datasets,
  };

  return dataBar;
};

  
  
  export {calculateMetricsData,formatRevenue,getLoggedInUser,showLoadingModal,hideLoadingModal,getCurrentDateTime,getCurrentDate,calculateCumulativeValueAmounts,calculateCumulativeUnitSales};